import { OpenAPI } from "../packages/proxy/src";

// src/config.ts
export interface Config {
    apiUrl: string;
    authUrl?: string;
    clientId:string;
    clientSecret:string;
    scope:string;
    redirect_uri: string;
    post_logout_redirect_uri: string;
  }
  
  class ConfigService {
    private config: Config | null = null;
  
    public async load(): Promise<Config | null> {
      if (!this.config) {
        const response = await fetch('/config.json?v=12345');
        if (!response.ok) {
          throw new Error(`Failed to fetch config: ${response.statusText}`);
        }
        this.config = await response.json();
        if(this.config && this.config.apiUrl){
          // Set API base URL
          OpenAPI.BASE = this.config.apiUrl;
          // console.log('OPEN API Base URL:', OpenAPI.BASE);
        }
        
      }
      
      return this.config;
    }
  
    public get(): Config {
      if (!this.config) {
        throw new Error('Config not loaded. Please call load first.');
      }
      return this.config;
    }
  }
  
  export const configService = new ConfigService();