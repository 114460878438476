import React, {useEffect, useState} from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import {TimelineOppositeContent} from "@material-ui/lab";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import Paper from "@material-ui/core/Paper";
import {AppButton} from "../../components/AppButton";
import {useOrderStyles} from "../OrderStyle";
import {useTranslate} from "react-admin";
import RawField from '../../components/RawField';
import { ShopDeliveryService, 
    wora_CorbosService_DeliveryHistories_DeliveryHistoryDto, 
    wora_CorbosService_DeliveryHistories_GetDeliveryHistoriesInput } from '../../packages/proxy/src';
import { af } from 'date-fns/locale';

const ActivityHistory = (props :any) => {
    const orderClasses = useOrderStyles();
    const relatedTask = props.relatedTask;
    const [orderLogs, setOrderLogs] = useState<wora_CorbosService_DeliveryHistories_DeliveryHistoryDto[]>([]);
    const [logsOffsetNumber, setLogsOffsetNumber] = useState<number>(5);
    const [logsExpanded, setLogsExpanded] = useState<boolean>(false);
    const [displayLogs, setDisplayLogs] = useState<any[]>([]);
    const translate = useTranslate();
    const numberOfLogsToBeDisplayedInitially :number = 5;



    useEffect(() => {
        if (typeof relatedTask !== 'undefined'){
            //Get History data for delivery
            let input : wora_CorbosService_DeliveryHistories_GetDeliveryHistoriesInput = {};
            input.deliveryId = props.currentTaskId;
            ShopDeliveryService.getApiShopDeliveriesGetHistory(props.currentTaskId)
            .then((result : wora_CorbosService_DeliveryHistories_DeliveryHistoryDto[])=>{
                setOrderLogs(result || []);
            })
         
        }
    }, [relatedTask]); // eslint-disable-line

    //will toggle the logs
    useEffect(() => {
        if(displayLogs.length == 0){
            setDisplayLogs(orderLogs);
            setLogsExpanded(true);
        }
        else if (displayLogs.length > 5){
            const slicedLogs = orderLogs.slice(0, logsOffsetNumber);
            setDisplayLogs(slicedLogs);
            setLogsExpanded(false);
        }else {
            setDisplayLogs(orderLogs);
            setLogsExpanded(true);
        }

    }, [logsOffsetNumber]); // eslint-disable-line

    useEffect(()=>{
        if (orderLogs.length > 5){
            setDisplayLogs(orderLogs.slice(0, numberOfLogsToBeDisplayedInitially));
            setLogsExpanded(false);
        }else {
            setDisplayLogs(orderLogs);
        }
    }, [orderLogs]);

    function getCleanLog(note: string | null | undefined): React.ReactNode {
        console.log("note", note);
        if (note == null || note == undefined) {
            return note;
        }
        const parts = note.split("->");
        if(parts.length > 1){
        const afterArrow = parts[1].trim(); // "Completed"
        return afterArrow;
        }
        return note;
    }

    return (
        <Grid item xs={12} md={6} className={orderClasses.timeLineBox} >
            <Box style = {{marginTop: "15px"}} alignItems="center" alignContent="center" justifyContent="center">
                <Typography className={orderClasses.sidebarTitle} align="center" variant="h6">
                    {translate('CorbosService::DeliveryHistories')}
                </Typography>
            </Box>
            <Box p={3} style={{height: '500px'}}>
                <Box px={7} mt="-1rem" justifyContent="center" alignContent="center" alignItems="center">
                    {React.Children.toArray(
                        displayLogs.map((orderLog: wora_CorbosService_DeliveryHistories_DeliveryHistoryDto) =>  {
                            

                            return (
                                <Timeline align="left" className={'activityLogs'}>
                                    <TimelineItem className={'missingOppositeContent'}>
                                        <TimelineOppositeContent >
                                            { orderLog.stepType == "D" ? "Delivery" : ( orderLog.stepType == "P" ? "PickUp" : "Order")}
                                            <Typography component="div">
                                                    <RawField source='creationTime' record={orderLog} />
                                                </Typography>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot style = {{marginBottom : "0"}} color="primary" />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Paper elevation={3} className={orderClasses.timeLineCard}>
                                                
                                                <Typography>{getCleanLog(orderLog.note)}</Typography>
                                            </Paper>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            );
                        })
                    )}
                </Box>
                {
                    orderLogs.length > 5 &&
                    <Box my={2} className={orderClasses.loadMoreBtnHolder}>
                        <AppButton onClick={() => !logsExpanded ? setLogsOffsetNumber(orderLogs.length) : setLogsOffsetNumber(numberOfLogsToBeDisplayedInitially)} fullWidth variant="contained" color="primary" size="large" className={orderClasses.loadMoreBtnlg}>
                            {!logsExpanded ? translate('AbpUi::LoadMore') : translate('AbpUi::Close')}
                        </AppButton>
                    </Box>
                }
            </Box>
        </Grid>
    );
};

export default ActivityHistory;