import * as React from "react";
import { Route } from 'react-router-dom';
import { RouteWithoutLayout } from 'react-admin';
import Register from './auth/Register';
import ResetPassword from './auth/ResetPassword';
import { ReturnOrder } from './order/ReturnOrder';
import { NewOrder } from './order/NewOrder';
import { Dashboard } from "./dashboard/Dashboard";
import ChangePassword from "./auth/ChangePassword";
import ShopToShopOrder from "./order/ShopToShopOrder";
import ResetPasswordForm from "./auth/ResetPasswordForm";

const routes = [
    <RouteWithoutLayout noLayout exact path="/set-password" component={ResetPassword} />,
    <RouteWithoutLayout noLayout exact path="/register" 
        render={(props: any) => {
            return <Register
                resource="Contacts"
                basePath={props.match.url}
                permissions={['']}
                {...props} 
            />
        }}
    />,
    <RouteWithoutLayout noLayout exact path="/reset-password" component={ResetPasswordForm} />,

    <Route
            exact path={'/change-password'}
            component={ChangePassword}
        />,
    //     <Route exact path="/my-profile"
    //     render={props => {
    //         return <UserProfileEdit/>
    //     }}
    // />,
    <Route exact path="/"
        render={props => {
            return <NewOrder
            resource="delivery"
            basePath={props.match.url}
            permissions={['list']}
            {...props}
        />
        }}
    />,
        <Route exact path="/shop-to-shop"
        render={props => {
            return <ShopToShopOrder
            resource="delivery"
            basePath={props.match.url}
            permissions={['list']}
            {...props}
        />
        }}
    />,
        <Route exact path="/dashboard"
        render={props => {
            return <Dashboard/>
        }}
    />,
    <Route exact path="/order-return" 
        render={props => {
            return <ReturnOrder 
            resource="delivery"
            basePath={props.match.url}
            permissions={['list']}
            {...props} 
        />
        }}
    />
];

export default routes;