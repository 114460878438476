import React, {useEffect, useState} from 'react';
import {
    CardElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import {doInvoke} from "../utils/lib";
import {getDataFromLocalDb} from "../utils/Helpers";
import {TABLE_AUTH} from "../utils/local-db";
import Button from "@material-ui/core/Button";
import {useNotify, useTranslate} from "react-admin";
import {usePaymentStyles} from "./PaymentStyle";
import {Box} from "@material-ui/core";
import {PaymentDetails} from "./PaymentDetails";
import {CardDetails} from "./CardDetails";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CircularProgress from "@material-ui/core/CircularProgress";

const CheckoutForm = (props:any) => {
    const stripe = useStripe();
    const elements = useElements();
    const [contactDetails, setContactDetails] = useState<any>({});
    const translate = useTranslate();
    const paymentClasses = usePaymentStyles();
    const userAccount = props.account;
    const [savedCards, setSavedCards] = useState<any[]>([]);
    const [selectedCardId, setSelectedCardId] = useState<string>('');
    const [addNewPaymentMethod, setAddNewPaymentMethod] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const notify = useNotify();
    const cardElementOptions = {
        style: {
            base: {
                color: "#424770",
                letterSpacing: "0.025em",
                fontSize: '16px',
                fontFamily: "Popins",
                "::placeholder": {
                    color: "#aab7c4"
                },
            },
            invalid: {
                color: "#9e2146"
            }
        }
    };


    useEffect(() => {
        getDataFromLocalDb(TABLE_AUTH.tableName).then((result) => {
            setContactDetails(result?.contactDetail);
        })
    }, []);
    useEffect(() => {
        const savedCardParams = {
            method: "retrieve_cards",
            data: JSON.stringify({
                customerid: contactDetails?.stripe_cus_id
            })
        };
        if (contactDetails?.stripe_cus_id){
            //retrieve saved cards
            // doInvoke('StripeHandler', savedCardParams, 'POST').then((result) => {
            //     const resultSources  = result?.data;
            //     //function to filter the cards so they can display only one time
            //     const filteredSavedCards = resultSources.filter((item:any, index:number, self:any[]) =>
            //             index === self.findIndex((obj:any)=>(
            //                 obj?.card?.last4 === item?.card?.last4
            //                 && obj?.card?.exp_month === item?.card?.exp_month
            //                 && obj?.card?.exp_year === item?.card?.exp_year
            //                 && obj?.card?.brand === item?.card?.brand
            //             ))
            //     )
            //     setSavedCards(filteredSavedCards);
            // });
        }

    }, [contactDetails]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        let error: boolean = false
        let token: string;
        if (selectedCardId) {
            const selectedCard = savedCards.filter((card: any) => card.id === selectedCardId);
            token = selectedCard[0]?.id;
        } else {
            const card: any = elements?.getElement(CardElement);
           await stripe!.createSource(card).then((result: any) => {
                // console.log('stripe create', result);
                if (typeof result.error !== 'undefined') {
                    // will display result.error.message
                    notify(result.error.message, 'error');
                    error = true
                }

                token = result?.source?.id
            });
        }
        const submitCardParams = {
            method: "create_payment_intent",
            data: JSON.stringify({
                email: contactDetails?.email,
                fullname: contactDetails?.firstname + ' ' + contactDetails?.lastname,
                amount: Math.round(((props?.paymentinfo?.total) + Number.EPSILON) * 100),
                description: props?.salesOrderRecord?.subject,
                payment_method: token!,
                currency: 'EUR',
                capture_method: 'automatic',
            })
        };
        if (!error){
            setLoading(true);
            // console.log('submitCardParams', submitCardParams);
            // Call create delviery task with payment Intent
            props.setShowPaymentMessage(true);

            // doInvoke('StripeHandler', submitCardParams, 'POST').then((result) => {
            //     if (typeof result?.error !== 'undefined'){
            //         notify(result.error?.message, 'error');
            //         error = true;
            //         setLoading(false);
            //     }else if (result?.type?.includes('error')) {
            //         notify(result.message, 'error');
            //         error = true;
            //         setLoading(false);
            //     }else{
            //         props.setShowPaymentMessage(true);
            //     }
            // })
        }

    }

    return (
        <form id={'payment-form'} onSubmit={handleSubmit}>

            <Box py="1rem" mt="2.4rem" px="1rem">
                {
                    savedCards.length > 0 ?
                        <CardDetails
                            setAddNewPaymentMethod={setAddNewPaymentMethod}
                            selectedCardId={selectedCardId} setSelectedCardId={setSelectedCardId}
                            savedCards={savedCards} {...props}/> :
                        <Box mt="2" p={0.5} style={{backgroundColor: '#F8F8F8'}}>
                            <CardElement options={cardElementOptions}/>
                        </Box>

                }
                {(addNewPaymentMethod && !selectedCardId) &&
                <Box mt={2} p={0.5} >
                    <CardElement options={cardElementOptions} />
                </Box>
                }
                {
                    (!addNewPaymentMethod && savedCards.length > 0) ?
                        <Box my="1rem">
                            <Typography style={{cursor: 'pointer', fontSize: '16px'}} onClick={() => {
                                setAddNewPaymentMethod(true);
                                setSelectedCardId('');
                            }} align={"center"}>
                                <AddCircleOutlineIcon style={{verticalAlign: 'middle'}}/>
                                {" "}
                                {translate('translations.add_payment_method')}{" "}
                            </Typography>
                        </Box>
                        : <></>
                }

            </Box>

            <Box p="1rem" mt="-0.5rem">
                <PaymentDetails packages={props?.packages} paymentinfo={props?.paymentinfo}
                                paymentMethod={userAccount?.accountsretailer_id}/>
            </Box>
            <Button
                type={'submit'}
                color="primary"
                disabled={!stripe || loading}
                startIcon = { loading ? <CircularProgress color={loading ? 'primary' : 'secondary' } size={24} /> : <></>}
                fullWidth
                size="large"
                className={paymentClasses.toolbarBtn}
                variant="contained"
            >
                {translate('CorbosService::Save')}
            </Button>
        </form>
    );
}

export default CheckoutForm;