import React, { forwardRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, AppBar , Box,  ListItem, ListItemText } from '@material-ui/core';
import { UserMenu, Logout, usePermissions, useGetIdentity } from 'react-admin';
import woraLogoWhite from './assets/logoWhiteYellowTick.png';
import SideBarMenu from './SideBarMenu';
import { MenuItemLink, useTranslate } from 'react-admin';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
// import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { abpPermissionCheck } from './utils/Helpers';
import LanguageSwitcher  from './components/LanguageSwitcher';
import useAbp from './useAbp';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '10px',
        '& .MuiToolbar-dense': {
            minHeight: '70px',
        },
        '& .MuiSvgIcon-root': {
            width: '1em',
            height: '1em',
        }
    },
    appBar: {
        zIndex: 999999,
    },
    logo: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            width: '14%',
        },
        width: '32%',
        margin: 'auto'
    }, 
    menuBox: {
        width: 'auto',
    },
}));

const UserMenuItems = forwardRef(({ onClick, user } : { onClick: any, user: any }, ref: any) => {
    const translate = useTranslate();
    // const abp = useAbp();

    return (
        <>
        {/* <MenuItemLink
            ref={ref}
            to="/dashboard"
            primaryText={translate('ra.page.dashboard')}
            leftIcon={<DashboardOutlinedIcon />}
            onClick={onClick}
            sidebarIsOpen={true}
        /> */}
        <MenuItemLink
            placeholder={'aa'}
            ref={ref}
            to={`/my-profile/${user.id}`}
            primaryText={translate('AbpUi::PersonalInfo')}
            leftIcon={<PersonOutlineOutlinedIcon />}
            onClick={onClick}
            sidebarIsOpen={true}
        />
         <MenuItemLink
            placeholder={'aaa'}
            ref={ref}
            to="/change-password"
            primaryText={translate('AbpUi::ChangePassword')}
            leftIcon={<LockOutlinedIcon />}
            onClick={onClick}
            sidebarIsOpen={true}
        />
        </>
    )
});

const Header = (props: any) => {
    const classes = useStyles();
    // const abp = useAbp();
    const translate = useTranslate();
    const [menuList, setMenuList] = useState<any[]>([]);
    const [user, setUser] = useState<any>({});
    const { identity } = useGetIdentity();
    const { permissions } = usePermissions();
    
    // Update menu with permissions
    useEffect( () => {
        // console.log('Header - Permissions', permissions);
        // Get Permissions
        
        let items = [];
        // console.log('Header - Permissions', permissions);
        if(abpPermissionCheck(permissions,'CorbosService.Deliveries.Create')){
            // Create
            items.push({
                menuId: '0',
                type: 'menu',
                name: '/',
                label: translate('CorbosService::NewDelivery'),
                parentMenu:'',
                menuItemSequence: '0',
                menuItemVisibility: true,
                permittedIds: '0',
                show: true
            });
        }
        
        // if(abpPermissionCheck(permissions,'CorbosService.ShopPortal.ReturnOrder')){
        //     // Create
        //     items.push({
        //         menuId: '1',
        //         type: 'menu',
        //         name: '/order-return',
        //         label: translate('CorbosService::ReturnOrder'),
        //         parentMenu:'',
        //         menuItemSequence: '0',
        //         menuItemVisibility: true,
        //         permittedIds: '0',
        //         show: true
        //     });
        // }
        
        // if(abpPermissionCheck(permissions,'CorbosService.ShopPortal.ShopToShop')){
        //     // Create
        //     items.push({
        //         menuId: '2',
        //         type: 'menu',
        //         name: '/shop-to-shop',
        //         label: translate('CorbosService::ShopToShop'),
        //         parentMenu:'',
        //         menuItemSequence: '0',
        //         menuItemVisibility: true,
        //         permittedIds: '0',
        //         show: true
        //     });
        // }

        items.push({
            menuId: '3',
            type: 'menu',
            name: 'delivery',
            label: translate('CorbosService::Deliveries'),
            parentMenu:'',
            menuItemSequence: '0',
            menuItemVisibility: true,
            permittedIds: '0',
            show: true
        });

        setMenuList( items);
    }, [permissions]);

    // Udate menu with identity
    useEffect( () => {
        // console.log('Header - Identity', identity);
        setUser(identity);
    }, [identity]);


    const goTo = (url: any) =>{

    }

    const SettingsButton = () => (
        <ListItem button key={'a'} onClick={() => goTo('_menuUrl')}>
        <ListItemText primary={'aaaa'}/>
    </ListItem>
    );
    
    return (
        <nav className={classes.root}>
            <AppBar position="fixed" color="primary" className={classes.appBar}>
                <Toolbar variant="dense">
                    <Box flex={1} display="flex" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                                <SideBarMenu menu = {menuList}/>
                            </Box>
                            <Box display="flex" width={'60%'} justifyContent="center">
                                <img
                                    className={classes.logo}
                                    src={woraLogoWhite}
                                    alt="WORA Logo"
                                />
                            </Box>
                            <Box  display="flex" justifyContent="center">
                                <LanguageSwitcher />
                            </Box>
                            <Box display="flex">
                                <UserMenu {...props} logout={<Logout button />} >
                                    <UserMenuItems {...props} user={user} />
                                </UserMenu>
                            </Box>
                        </Box>
                </Toolbar>
            </AppBar>
        </nav>
    );
};

export default Header;
