import React, {useEffect} from 'react'
import {useSetLocale, useTranslate} from 'react-admin'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import woraCircleImg from '../assets/woraCircleImg.png';
import logoColoredYellowTick from '../assets/logoColoredYellowTick.png';
import ellipseImg from '../assets/ellipseImg.png';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Card } from '@material-ui/core';
import clsx from 'clsx';
import {getDataFromLocalDb} from "../utils/Helpers";
import {TABLE_AUTH} from "../utils/local-db";

export const useStyles = makeStyles((theme) => ({
    root: {
        background: '#F2F2F2',
        padding: '15px',
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        overflowY: 'hidden',
    },
    center: {
        margin: 'auto',
        position: 'absolute',
        transform: 'translateY(-50%)',
        [theme.breakpoints.up('md')]: {
            minHeight: '65%',
            width: '75%',
            left: '12.5%',
        },
        top: '50%',
        minHeight: '70%',
        width: '70%',
        left: '15%',
    },
    content: {
        [theme.breakpoints.up('md')]: {
            width: '50%',
            top: '50%',
            left: '25%',
        },
        width: '90%',
        margin: 'auto',
        position: 'absolute',
        top: '50%',
        left: '5%',
        transform: 'translateY(-50%)',
    },
    topRight: {
        width: '8%',
        margin: 'auto',
        position: 'absolute',
        top: '25px',
        left: '25px',
    },
    bottomRight: {
        width: '8%',
        margin: 'auto',
        position: 'absolute',
        bottom: '25px',
        right: '25px',
    },
    bottomLeftEllipse: {
        margin: 'auto',
        position: 'absolute',
        [theme.breakpoints.up('md')]: {
            bottom: '-8%',
            left: '0',
            width: '28%',
        },
        width: '40%',
        bottom: '-10px',
        left: '0',
    },
    topRightEllipse: {
        margin: 'auto',
        position: 'absolute',
        transform: 'rotate(180deg)',
        [theme.breakpoints.up('md')]: {
            top: '-8%',
            right: '0',
            width: '28%',
        },
        width: '40%',
        top: '-10px',
        right: '0',
    },
}));


const Loader = ({ loadingPrimary='AbpUi::LoadingWithThreeDot', loadingSecondary='' } : { loadingPrimary: string, loadingSecondary: string}) => {
  const translate = useTranslate()
  const classes = useStyles();
    const setLocale = useSetLocale();

    useEffect(()=>{
      getDataFromLocalDb(TABLE_AUTH.tableName).then(
          (result)=>{
              if(result?.language === 'en_us' || result?.language === 'en_en'){
                  setLocale('en')
              }
              if (result?.language === 'it_it'){
                  setLocale('it')
              }
          }
      )
  })

  return (
    <Box className={clsx(classes.root)}>
        <Box className={classes.topRightEllipse}>
            <img
                src={ellipseImg}
                alt="WORA"
                width={'100%'}
                height={'100%'}
            />
        </Box>
        <Box className={classes.bottomLeftEllipse}>
            <img
                src={ellipseImg}
                alt="WORA"
                width={'100%'}
                height={'100%'}
            />
        </Box>
        <Card elevation={4} className={clsx(classes.center)} style={{borderRadius: '10px',}}>
            <Box className={classes.topRight}>
                <img
                    src={woraCircleImg}
                    alt="WORA"
                    width={'100%'}
                    height={'100%'}
                />
            </Box>
            <Box textAlign="center" className={classes.content}>
                <Box style={{width: '40%', margin: 'auto'}}>
                    <img
                        src={logoColoredYellowTick}
                        alt="WORA Logo"
                        width={'100%'}
                        height={'100%'}
                    />
                </Box>
                <Box my={1}>
                    <Box textAlign="center">
                        <Typography variant="h6" className={'textPrimary2'} gutterBottom>
                            {translate(loadingPrimary)}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {translate(loadingSecondary)}
                        </Typography>
                    </Box>
                </Box>
                <Box mt={2} textAlign="center">
                    <LinearProgress />
                </Box>
            </Box>
            <Box className={classes.bottomRight}>
                <img
                    src={woraCircleImg}
                    alt="WORA"
                    width={'100%'}
                    height={'100%'}
                />
            </Box>
        </Card>
    </Box>
  )

}
export default Loader

export const LoaderHorizontal = ({loading} : { loading: boolean }) => {
  return (
   <div style={{opacity: loading? 1:0}}>
      <LinearProgress/>
   </div>
  ) 
}