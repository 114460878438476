import React, {useEffect, useState} from 'react';
import {
    Create,
    Edit,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar, useNotify, usePermissions,
    useRedirect,
    useTranslate
} from 'react-admin';
import {getDataFromLocalDb, permissionCheck} from "../utils/Helpers";
import {TABLE_AUTH, TABLE_DESCRIBE} from "../utils/local-db";
import {useStyles} from "../style/Index";
import {useOrderStyles} from "../order/OrderStyle";
import Card from '@material-ui/core/Card';
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FieldInput from "../utils/FieldInput";
import Typography from "@material-ui/core/Typography";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {DELIVERY_PANEL_NAME, googleMapsApiKey, PICKUP_PANEL_NAME} from "../constant";
import {doQuery, massCreate, retrieveRecord, update} from "../utils/lib";

const AddresEdit = (props: any) => {
    const [describe, setDescribe] = useState<any>(null);
    const resource: string = props.resource ?? '';
    const [displayInputs, setDisplayInputs] = useState<any[]>([]);
    const [assignedUserList, setAssignedUserList] = useState<any[]>([]);
    const classes = useStyles();
    const orderClasses = useOrderStyles();
    const path = props.location.pathname;
    const [record, setRecord] = useState<any>(null);
    const [user, setUser] = useState<any>(null);
    const [value, setValue] = useState<any>();
    const translate = useTranslate();
    const [addressError, setAddressError] = useState<string>('');
    const [wrongAddressValue, setWrongAddressValue] = useState<any>(null);
    const [temporaryAddressValue, setTemporaryAddressValue] = useState<any>(null);
    const redirect = useRedirect();
    const notify = useNotify();
    const { permissions } = usePermissions();
        useEffect(()=>{

            if (typeof permissions !== "undefined"){
                if (areWeOnCreatePath() && !permissionCheck(permissions, props.resource, 'create')) {
                    //check to see if we are on create view but the user doesn't have permission
                    redirect('/ContactRole');
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[permissions])
    useEffect(() => {
        if (props?.id && props?.id !=='create') {
            retrieveRecord(props?.id).then((result: any) => {
                setRecord(result);
            })
        }
        getDataFromLocalDb(TABLE_AUTH.tableName).then((result) => {
            setUser(result);
        });
        getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result: any) => {
            const showFields = ['contactrolename', 'cus_address'];
            setDescribe(result);
            let modFields = result[resource]?.fields ?? [];
            const dFields: any[] = [];
            //const dFilters: any[] = [];
            modFields.forEach((field: any) => {
                if (showFields.includes(field.name)) {
                    dFields.push(field);
                }
            })
            setDisplayInputs(dFields);
            //setDisplayFilters(dFilters);
            const aUserList = result[resource]?.userlist ?? [];
            setAssignedUserList(aUserList);
        });
    }, [resource]) // eslint-disable-line
    const areWeOnCreatePath = ()=>{
        return path.split('/').length >= 3 && path.split('/')[2] === 'create';
    }
    const saveRecord =
        async (values: any) => {
            if(!value?.label) {
                setAddressError(translate('ra.validation.required'));
                return;
            } 
            massCreate([
                    {
                        elementType: "ContactRole",
                        searchon: "contactrolename",
                        element: {
                            contact: user?.logindata?.user?.contactid,
                            assigned_user_id : user?.logindata?.user?.id,
                            contactrolename: values?.contactrolename,
                            cus_address: value?.label
                        }
                    }
                ]
            ).then((res : any) => {
                if (path.includes(resource) && !areWeOnCreatePath() ){ // the create view it is being opened from AddressList page
                    window.location.reload();
                }else {// the create view it is being opened from Orders page
                    if (props?.shopToShopView){ //it is opened from ShopToShop
                        if(props?.shopToShopView === DELIVERY_PANEL_NAME){ //create view is opened from delivery panel in ShopToShop form
                            props.togglePopUp(res?.success_creates[0]?.id, DELIVERY_PANEL_NAME);
                        }else {
                            props.togglePopUp(res?.success_creates[0]?.id, PICKUP_PANEL_NAME);
                        }
                    }else{ // it is opened from other views
                        props.togglePopUp(res?.success_creates[0]?.id);
                    }
                }
            }, res => {
                notify(res?.message, 'error');
            })
        };
    const editRecord = async (values: any) => {
        if (value?.label !== record?.cus_address || record?.contactrolename !== values?.contactrolename) { //one of the values changed
            if (record?.contactrolename !== values?.contactrolename) { //value of contact role has changed
                doQuery('select * from ContactRole').then((contactRoles: any) => { //query to retrieve all the contactroles
                    const existentContactRole = contactRoles.filter((contactRole: any) => contactRole?.contactrolename === values?.contactrolename);
                    if (!existentContactRole.length) { //there is no existing contactRole with that contactrolename
                        // update('ContactRole', {
                        //     id: values?.id,
                        //     contactrolename: values?.contactrolename,
                        //     cus_address: value?.label
                        // }).then(() => {
                        //     window.location.reload();
                        // }).catch((err: any) => {
                        //     notify(err, "error")
                        // })
                    } else { //name exists
                        notify(translate('translations.ruolo_conttatti_validation'), "error");
                    }
                });
            } else {
                // update('ContactRole', {
                //     id: values?.id,
                //     contactrolename: values?.contactrolename,
                //     cus_address: value?.label
                // }).then(() => {
                //     window.location.reload()
                // }).catch((err: any) => {
                //     notify(err, "error")
                // })
            }

        } else { //no value has changed
            props.togglePopUp();
        }
    }

    const updateValues = (value: any) => {
        const secondArgument = value.label.split(",")[1];
        const firstConditionForAddressValidationToFail :boolean = isNaN(Number(secondArgument)); 
        const secondConditionForAddressValidationToFail :boolean = isNaN(Number(secondArgument.split("/")[0])) || isNaN(Number(secondArgument.split("/")[1])) ;
        if ( firstConditionForAddressValidationToFail && secondConditionForAddressValidationToFail) { // * if the second argument is not a number and has not this type of value *31/4* the validation will fail
            setTemporaryAddressValue(null);
            setWrongAddressValue(value);
            setAddressError(translate('translations.address_validation_error'));
        } else {
            setValue(value);
            setAddressError('');
            setWrongAddressValue(null);
            setTemporaryAddressValue(null);
        }
    }

    //function to check if the address format is correct
    const checkTheInputChangedValue = (v: string, action: any) => {
        if (action?.action === 'input-change') {

            //we clear tha value if it is set, so the text in the input can be edited
            if (typeof value !== 'undefined' || value) {
                setValue(null);
            }
            const secondArgument = parseInt(v.split(",")[1]);

            //check if the address typed contains the building number
            if (v.split(",").length >= 1 && isNaN(secondArgument)) {
                setTemporaryAddressValue(null);
                setWrongAddressValue({
                    label: v,
                    value: {
                        description: v
                    }
                });
                setAddressError(translate('translations.address_validation_error'));
            } else {
                setWrongAddressValue(null);
                setTemporaryAddressValue({
                    label: v,
                    value: {
                        description: v
                    }
                });
                if (v.split(',').length >= 3) {
                    setAddressError(translate('translations.select_address_option'))
                }
            }
        }
    }
    const displayAddressBox = (placeHolder:string) => (
        <div key={'addressFieldBox'} style={{marginBottom: '40px'}}>
            <GooglePlacesAutocomplete
                apiKey={googleMapsApiKey}
                selectProps={{
                    value: wrongAddressValue ? wrongAddressValue : value,
                    onChange: updateValues,
                    inputName: 'customer_address',
                    placeholder: placeHolder,
                    filterOption: (v: any) => {
                        const secondArgument = parseInt(v.label.split(",")[1]);
                        if (!isNaN(secondArgument)) {
                            //if the second argumwent is a number, it is correct.
                            return v;
                        }
                    },
                    onInputChange: checkTheInputChangedValue,
                    inputValue: value?.label! ? value?.label! : wrongAddressValue ? wrongAddressValue?.label! : temporaryAddressValue?.label!,
                    closeMenuOnSelect: false,
                    styles: {
                        container: (provided: any, state: any) => ({
                            ...provided,
                            border: addressError && '1px solid red',
                            zIndex: state?.isFocused ? 2 : 1,

                        }),
                        control: (provided: any, state: any) => ({
                            ...provided,
                            zIndex: state?.isFocused ? 2 : 1,
                        }),
                        placeholder: (provided: any) => ({
                            ...provided,
                            color: '#BDBDBD'
                        })
                    }
                }}
                autocompletionRequest={{
                    componentRestrictions: {
                        country: ['it'],
                    }
                }}
            />
            {addressError && <Box display={'flex'} width={'100%'} px={1} my={1} textAlign={'start'}
                                  style={{color: 'red'}}>{addressError}</Box>}
        </div>
    );


    const ActionToolbar = (props: any) => (
        <Toolbar placeholder={'aa'}
        style={{
            backgroundColor: 'transparent',
            padding: '10px 45px',
            marginTop: '-10px',
            position: 'relative'
        }}>
            <SaveButton
                {...props}
                disabled = {addressError}
                label={props.id ? translate('translations.save_modifications') : translate('translations.save_address')}
                submitOnEnter={true}
                fullWidth
                onClick={()=>{if(!value?.label){
                    setAddressError(translate('ra.validation.required'));
                }}}
                variant="contained"
                color="primary"
                size="large"
            />

        </Toolbar>
    );
    return (
        <>
            {
                //check to see if the we are in the *create* path
                (areWeOnCreatePath()) ? (
                        <Box>
                            <Box className={'modal'}>
                                <div className="overlay" onClick={() => {
                                    redirect('/ContactRole')
                                }}></div>
                                <Box display={'flex'} flexDirection={'column'} className={'modal-content'} p={5.5}
                                     style={{width: '40%'}}>
                                    <div className={orderClasses.root} style={{margin: '0', padding: '0'}}>

                                        <Card className={classes.appCard} style={{width: '100%'}} variant="outlined">
                                            <Box mt="-1.6rem">
                                                <Create save={saveRecord} actions={false} {...props} >
                                                    <SimpleForm redirect={'list'} toolbar={<ActionToolbar {...props} togglePopUp={() => {
                                                        redirect('/ContactRole')
                                                    }}/>}>
                                                        <Box textAlign="center" style={{width: '100%'}}>
                                                            <Typography component="div">
                                                                {translate('translations.AddAddress')}
                                                            </Typography>
                                                        </Box>

                                                        <Box mt={4} style={{
                                                            width: '100%',
                                                            paddingLeft: '30px',
                                                            paddingRight: "30px"
                                                        }}>
                                                            <Grid spacing={1} style={{width: "100%"}}>
                                                                {
                                                                    displayInputs.map((input) => {
                                                                        const extraProps = {
                                                                            label: '',
                                                                            prefix: '',
                                                                            classname: ''
                                                                        };
                                                                        if (input.name === 'cus_address') {
                                                                            return <Grid key={input.name + 'Grid'} item xs={12}
                                                                                         md={12}>
                                                                                <Box mt={-1.5}>
                                                                                    {displayAddressBox(input.label)}

                                                                                </Box>
                                                                            </Grid>
                                                                        }
                                                                        const field = FieldInput(input, extraProps, '', describe, assignedUserList, translate)
                                                                        return (
                                                                            <Grid key={input.id} item xs={12} md={12}>
                                                                                <Box mt={-1.5}>
                                                                                    {field}
                                                                                </Box>
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Box>
                                                    </SimpleForm>
                                                </Create>
                                            </Box>
                                        </Card>
                                    </div>
                                </Box>

                            </Box>
                        </Box>
                    )
                    :

                    <div className={orderClasses.root} style={{margin: '0', padding: '0'}}>

                        <Card className={classes.appCard} style={{width: '100%'}} variant="outlined">
                            <Box mt="-1.6rem">
                                {props.id ?
                                    <Edit actions={false} {...props} >
                                        <SimpleForm redirect={"list"} save={editRecord}
                                                    toolbar={<ActionToolbar {...props} />}>
                                            <Box textAlign="center" style={{width: '100%', padding: '30px'}}>
                                                <Typography component="div">
                                                    {translate('translations.edit') + ' ' +  translate('translations.address') }
                                                </Typography>
                                            </Box>

                                            <Box mt={4}
                                                 style={{width: '100%', paddingLeft: '30px', paddingRight: "30px"}}>
                                                <Grid spacing={1} style={{width: "100%"}}>
                                                    {
                                                        displayInputs.map((input) => {
                                                            const extraProps = {prefix: '', classname: ''};
                                                            if (input.name === 'cus_address') {
                                                                if (typeof record?.cus_address !== 'undefined' && typeof value === "undefined") {
                                                                    setValue({
                                                                        label: record?.cus_address,
                                                                        value: {
                                                                            description: record?.cus_address,
                                                                        }
                                                                    });
                                                                }

                                                                return <Grid key={input.name + 'Grid'} item xs={12}
                                                                             md={12}>
                                                                    <Box mt={-1.5}>
                                                                        {typeof value === 'undefined' ?
                                                                            <TextInput
                                                                                variant="outlined"
                                                                                key={input.name}
                                                                                label={''}
                                                                                placeholder={input.label ?? input.label}
                                                                                source={input.name}
                                                                                fullWidth
                                                                                id={'customer_address'}
                                                                            />
                                                                            :

                                                                            displayAddressBox(input.label)}
                                                                    </Box>
                                                                </Grid>
                                                            }

                                                            const field = FieldInput(input, extraProps, '', describe, assignedUserList);

                                                            return (
                                                                <Grid item xs={12} md={12}>
                                                                    <Box mt={-3}>
                                                                        {field}
                                                                    </Box>
                                                                </Grid>
                                                            )
                                                        })


                                                    }
                                                </Grid>
                                            </Box>
                                        </SimpleForm>
                                    </Edit>
                                    :
                                    <Create  {...props} >
                                        <SimpleForm save={saveRecord}
                                                    toolbar={<ActionToolbar/>}>
                                            <Box textAlign="center" style={{width: '100%'}}>
                                                <Typography component="div">
                                                    {translate('translations.AddAddress')}
                                                </Typography>
                                            </Box>

                                            <Box mt={4}
                                                 style={{width: '100%', paddingLeft: '30px', paddingRight: "30px"}}>
                                                <Grid spacing={1} style={{width: "100%"}}>
                                                    {
                                                        displayInputs.map((input) => {
                                                            const extraProps = {label: '', prefix: '', classname: ''};
                                                            if (input.name === 'contact') {
                                                                return <TextInput
                                                                    key={input.name}
                                                                    label={''}
                                                                    placeholder={input.label ?? input.label}
                                                                    source={input.name}
                                                                    defaultValue={user.logindata.user.contactid}
                                                                    fullWidth
                                                                    style={{display: 'none'}}
                                                                />
                                                            }
                                                            if (input.name === 'cus_address') {
                                                                return <Grid key={input?.id} item xs={12} md={12}>
                                                                    <Box mt={-1.5}>
                                                                        {displayAddressBox(input.label)}
                                                                    </Box>
                                                                </Grid>
                                                            }
                                                            const field = FieldInput(input, extraProps, '', describe, assignedUserList)
                                                            return (
                                                                <Grid key={input?.id} item xs={12} md={12}>
                                                                    <Box mt={-1.5}>
                                                                        {field}
                                                                    </Box>
                                                                </Grid>
                                                            );
                                                        })
                                                    }
                                                </Grid>
                                            </Box>
                                        </SimpleForm>
                                    </Create>
                                }

                            </Box>
                        </Card>
                    </div>
            }
        </>
    );
};

export default AddresEdit;