import React from "react";
import { Box } from "@material-ui/core";
import { useStyles } from "./AuthStyle";
import Checkbox from "@material-ui/core/Checkbox";

export const CheckboxWithLabel = ({
  label,
  val,
  redirect,
  checkValue,
}: {
  label: string;
  val: string;
  redirect: any;
  checkValue: any;
}) => {
  const classes = useStyles();
  return (
    <Box display={"flex"} justifyContent={"center"} alignItems = {'center'}>
        <Checkbox color="primary" size="medium" onChange={(e )=>{
          if(e.target.checked){
            checkValue((oldChecked :string[])=>[...oldChecked, val]);
          }else{
            checkValue((oldChecked :string[])=>oldChecked.filter(item=>item!==val));
          }
          }}/>
        <span onClick={()=>redirect(val)} className={`MuiTypography-root MuiFormControlLabel-label ${classes.redirectLabel}`}>
          {label}
        </span>
    </Box>
  );
};
