/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Dm_DynamicTemplate_Layouts_GetLayoutTemplateForEditOutput } from '../models/Dm_DynamicTemplate_Layouts_GetLayoutTemplateForEditOutput';
import type { Dm_DynamicTemplate_Layouts_GetLayoutTemplateInput } from '../models/Dm_DynamicTemplate_Layouts_GetLayoutTemplateInput';
import type { Dm_DynamicTemplate_Layouts_LayoutTemplateDto } from '../models/Dm_DynamicTemplate_Layouts_LayoutTemplateDto';
import type { Dm_DynamicTemplate_Layouts_LayoutTemplateEditDto } from '../models/Dm_DynamicTemplate_Layouts_LayoutTemplateEditDto';
import type { Dm_DynamicTemplate_Layouts_LayoutTemplateType } from '../models/Dm_DynamicTemplate_Layouts_LayoutTemplateType';
import type { Dm_DynamicTemplate_Layouts_LayoutUiDto } from '../models/Dm_DynamicTemplate_Layouts_LayoutUiDto';
import type { Volo_Abp_Application_Dtos_ListResultDto_1 } from '../models/Volo_Abp_Application_Dtos_ListResultDto_1';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1 } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1';

import type { CancelablePromise } from '../../../../proxy/src/core/CancelablePromise';
import { OpenAPI } from '../../../../proxy/src/core/OpenAPI';
import { request as __request } from '../../../../proxy/src/core/request';


export class LayoutTemplateService {

    /**
     * @param requestBody 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1<Dm_DynamicTemplate_Layouts_LayoutTemplateDto_Dm_DynamicTemplate_Application_Contracts_Version_1_0_1_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static postApiDynamicTemplateTable(
requestBody?: Dm_DynamicTemplate_Layouts_GetLayoutTemplateInput,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/dynamic-template/table',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param input 
     * @returns Dm_DynamicTemplate_Layouts_LayoutUiDto Success
     * @throws ApiError
     */
    public static getApiDynamicTemplateTable(
input?: string,
): CancelablePromise<Dm_DynamicTemplate_Layouts_LayoutUiDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dynamic-template/table',
            query: {
                'input': input,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param type 
     * @param fullType 
     * @param technicalName 
     * @param isDesignMode 
     * @param id 
     * @returns Dm_DynamicTemplate_Layouts_GetLayoutTemplateForEditOutput Success
     * @throws ApiError
     */
    public static getApiDynamicTemplateTableEditColumns(
type?: Dm_DynamicTemplate_Layouts_LayoutTemplateType,
fullType?: string,
technicalName?: string,
isDesignMode?: boolean,
id?: string,
): CancelablePromise<Dm_DynamicTemplate_Layouts_GetLayoutTemplateForEditOutput> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dynamic-template/table/edit-columns',
            query: {
                'Type': type,
                'FullType': fullType,
                'TechnicalName': technicalName,
                'IsDesignMode': isDesignMode,
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Dm_DynamicTemplate_Layouts_LayoutTemplateEditDto Success
     * @throws ApiError
     */
    public static postApiDynamicTemplateTableCreate(
requestBody?: Dm_DynamicTemplate_Layouts_LayoutTemplateDto,
): CancelablePromise<Dm_DynamicTemplate_Layouts_LayoutTemplateEditDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/dynamic-template/table/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns Dm_DynamicTemplate_Layouts_LayoutTemplateEditDto Success
     * @throws ApiError
     */
    public static putApiDynamicTemplateTable(
id: string,
requestBody?: Dm_DynamicTemplate_Layouts_LayoutTemplateDto,
): CancelablePromise<Dm_DynamicTemplate_Layouts_LayoutTemplateEditDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/dynamic-template/table/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiDynamicTemplateTable(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/dynamic-template/table/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Volo_Abp_Application_Dtos_ListResultDto_1<Dm_Application_Comboxes_ComboboxItemDto_Dm_Application_Contracts_Version_1_1_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getApiDynamicTemplateTableLayoutTypes(): CancelablePromise<Volo_Abp_Application_Dtos_ListResultDto_1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dynamic-template/table/layout-types',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Volo_Abp_Application_Dtos_ListResultDto_1<Dm_DynamicTemplate_Layouts_LayoutTemplateDto_Dm_DynamicTemplate_Application_Contracts_Version_1_0_1_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getApiDynamicTemplateTableLayoutTemplateList(): CancelablePromise<Volo_Abp_Application_Dtos_ListResultDto_1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dynamic-template/table/layout-template-list',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param input 
     * @returns Dm_DynamicTemplate_Layouts_LayoutUiDto Success
     * @throws ApiError
     */
    public static getApiDynamicTemplateTableByName(
input?: string,
): CancelablePromise<Dm_DynamicTemplate_Layouts_LayoutUiDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dynamic-template/table/by-name',
            query: {
                'input': input,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param forceInitColumns 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiDynamicTemplateTableInstall(
forceInitColumns: boolean = false,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dynamic-template/table/Install',
            query: {
                'forceInitColumns': forceInitColumns,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiDynamicTemplateTableResetLayout(
id?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dynamic-template/table/reset-layout',
            query: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id 
     * @returns boolean Success
     * @throws ApiError
     */
    public static postApiDynamicTemplateTableCanDeleteExtraFieldFromLayout(
id: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/dynamic-template/table/{Id}/can-delete-extra-field-from-layout',
            path: {
                'Id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Volo_Abp_Application_Dtos_ListResultDto_1<Dm_Application_Comboxes_ComboboxItemDto_Dm_Application_Contracts_Version_1_1_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getApiDynamicTemplateTableCombobox(): CancelablePromise<Volo_Abp_Application_Dtos_ListResultDto_1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dynamic-template/table/combobox',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
