import { useInput, BooleanInput, BooleanField } from 'react-admin';
import { acceptedMinutes, COREBOS_DESCRIBE, excludedTimes, minimumHour, nightExcludedTimes } from '../constant';
import { doInvoke, getModDescribe } from './lib';
import { clearTable, getDataFromLocalDB, saveDataToLocalDB, TABLE_DESCRIBE, TABLE_PERMISSIONS } from './local-db';
import { addDays, addHours, format, getYear, isAfter, setHours, setMinutes } from "date-fns";
import { isBefore } from 'date-fns/esm';
import { Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationAuthConfigurationDto } from '../packages/proxy/src';

const formatDate = (v: any) => {
    const dateTime = v;
    if (!(v instanceof Date) || isNaN(dateTime)) return;
    const pad = '00';
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    return `${(pad + mm).slice(-2)}/${(pad + dd).slice(-2)}/${yy}`;
};
export const prepareMenu = (menuMap: any, userRole: string) => {
    if (!menuMap) return [];
    const menu: any = {};
    const menuItems: any[] = [];
    const moduleList: any[] = [];
    for (const key in menuMap) {
        if (menuMap.hasOwnProperty(key)) {
            if (!menu.hasOwnProperty(key)) {
                if (menuMap[key]["4"] === '0') { // Menu tier (Menu heading)
                    menu[key] = {
                        menuId: menuMap[key]['0'],
                        type: 'menu',
                        name: menuMap[key]['2'],
                        label: menuMap[key]['3'],
                        parentMenu: menuMap[key]['4'],
                        menuItemSequence: menuMap[key]['5'],
                        menuItemVisibility: menuMap[key]['6'],
                        permittedIds: menuMap[key]['7'],
                        icon: menuMap[key]['8'] ?? null,
                        show: menuMap[key]['7'].includes(userRole)
                    };
                } else {
                    if (menuMap[key]["1"] === 'module' || menuMap[key]["1"] === 'menu' || menuMap[key]["1"] === 'url') {
                        moduleList.push({
                            menuId: menuMap[key]['0'],
                            type: menuMap[key]["1"],
                            name: menuMap[key]['2'],
                            label: menuMap[key]['3'],
                            parentMenu: menuMap[key]['4'],
                            menuItemSequence: menuMap[key]['5'],
                            menuItemVisibility: menuMap[key]['6'],
                            permittedIds: menuMap[key]['7'],
                            icon: menuMap[key]['8'] ?? null,
                            show: menuMap[key]['7'].includes(userRole)
                        })
                    }
                }
            }
        }
    }

    for (const key in menu) { // 1st Level
        menuItems.push(
            {
                menuId: menu[key].menuId,
                type: menu[key].type,
                name: menu[key].name,
                label: menu[key].label,
                parentMenu: menu[key].parentMenu,
                menuItemSequence: menu[key].menuItemSequence,
                menuItemVisibility: menu[key].menuItemVisibility,
                permittedIds: menu[key].permittedIds,
                icon: menu[key].icon,
                menuItems: moduleList.filter(mod => mod.parentMenu === menu[key].menuId),
                show: menu[key].show
            }
        );
    }


    for (const menuItem of menuItems) { // 2nd Level
        for (const key in menuItem.menuItems) {
            menuItem.menuItems[key] = {
                menuId: menuItem.menuItems[key].menuId,
                type: menuItem.menuItems[key].type,
                name: menuItem.menuItems[key].name,
                label: menuItem.menuItems[key].label,
                parentMenu: menuItem.menuItems[key].parentMenu,
                menuItemSequence: menuItem.menuItems[key].menuItemSequence,
                menuItemVisibility: menuItem.menuItems[key]['6'],
                permittedIds: menuItem.menuItems[key].permittedIds,
                icon: menuItem.menuItems[key].icon,
                menuItems: moduleList.filter(mod => mod.parentMenu === menuItem.menuItems[key].menuId),
                show: menuItem.menuItems[key].show
            }
        }
    }
    return menuItems;
}

export const abpPermissionCheck = (policies: Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationAuthConfigurationDto, permission: string): boolean | undefined => {
    // console.log('Check Abp Permission', policies, permission);

    let isValid: boolean = false;
    if (policies && policies.grantedPolicies) {
        const found = Object.entries(policies.grantedPolicies).find((item: any) => {
            return item[0] === permission;
        });
        if (found) {
            isValid = found[1] as boolean;
        }
    }
    return isValid;
}

export const permissionCheck = (permissions: any, resorce: string, action: string): any => {
    // console.log('Check Old Permission', permissions);

    let resoruceAbp = resorce;
    if (resorce === 'delivery') {
        resoruceAbp = 'CorbosService.Deliveries';
    }
    switch (action) {
        case 'delete':
            resoruceAbp = resoruceAbp + '.Delete';
            break;
        case 'update':
            resoruceAbp = resoruceAbp + '.Edit';
            break;
        case 'list':
            // resoruceAbp = resoruceAbp; 
            break;
    }

    if (permissions) {
        const found = Object.entries(permissions[0].permissions).find((item: any) => {
            if (item[0] === resoruceAbp) {
                // console.log(item[0] + ' -> ' + resoruceAbp);
                return item[1];
            } else {
                return false;
            }
        });

        // console.log(permissions);
        // console.log(resorce);
        // console.log(action);
        // console.log('resource -> ', resorce, 'action-> ', action, 'found', found);

        return found !== undefined ? found[1] : false;
    }
    else {
        return false;
    }
}
export const innerStringWhiteSpaceRemoval = (text: string) => {
    return text.replace(/\s/g, "");
}
const parseDate = (v: any, dateFormat = 'dd/mm/yyyy') => {
    // console.log('parseDate');
    const regexp = /(\d{4})-(\d{2})-(\d{2})/;
    

    if (!v || !regexp.exec(v)) return;
    let date = new Date(v);

    let match = regexp.exec(date.toISOString());
    if (match === null) return;
    const day: any = date.getDate();
    const month: any = date.getMonth() + 1;

    switch (dateFormat) {
        case 'mm-dd-yyyy':
            return [('00' + month).slice(-2), ('00' + day).slice(-2), date.getFullYear()].join("-");
        case 'mm/dd/yyyy':
            return [('00' + month).slice(-2), ('00' + day).slice(-2), date.getFullYear()].join("/");
        case 'dd-mm-yyyy':
        case 'dd/mm/yyyy':    
            return [('00' + day).slice(-2), ('00' + month).slice(-2), date.getFullYear()].join("/");
        case 'yyyy-mm-dd':
            return [date.getFullYear(), ('00' + month).slice(-2), ('00' + day).slice(-2)].join("-");
        default:
            return [('00' + month).slice(-2), ('00' + day).slice(-2), date.getFullYear()].join("/");
    }
};

export const formatSearchObject = (field: any, searchText: any) => {
    // console.log('formatSearchObject', field, searchText);
    if (!searchText) {
        return;
    }
    let srch: any = {};
    srch[field] = searchText;
    return srch;
}

export const FormattedBooleanInput = (props: any) => {
    let input = useInput(props);
    const isChecked = { checked: false };

    if (Number(input.input.value)) {
        isChecked.checked = true;
    }
    return (<BooleanInput {...props} options={isChecked} />);
};

export const FormattedBooleanField = (props: any) => {
    props.record[props.source] = (props.record[props.source] === '1');

    return (
        <div>
            <div style={{ width: '100%' }}>
                <label className="MuiFormLabel-root">{props.label}</label>
            </div>
            <BooleanField {...props} />
        </div>
    );
};

export const calculatePackageTotalAmount = (aPackage: any, ProductSize: string, products: any) => {
    let product: any = products.filter((product: any) => product.productname === ProductSize);
    product = product && product[0] ? product[0] : null;

    const unitPrice = product && product.unit_price ? Number(product.unit_price) : 0;
    const amount = aPackage.count * unitPrice;
    return amount;
}

export const calculatePackageIVAPercentage = (ProductSize: string, products: any) => {
    let product: any = products.filter((product: any) => product.productname === ProductSize);
    product = product && product[0] ? product[0] : null;

    const ivaPercentage = product && product.commissionrate ? Number(product.commissionrate) : 0;
    return ivaPercentage;
}

export const getDataFromLocalDb = async (tableName: string) => {
    const data = await getDataFromLocalDB(tableName);
    if (tableName === 'permissions') {
        return data && data.length ? data : null;
    }
    return data && data[0] ? data[0] : null;
}

export const loginAction = async () => {
    //await clearTable(TABLE_AUTH.tableName);
    await clearTable(TABLE_PERMISSIONS.tableName);
    await clearTable(TABLE_DESCRIBE.tableName);
    //window.dispatchEvent(window.coreBOS.LoginEvent); 
    let permissions: any[] = [];
    let Describe: any = {};

    return getModDescribe(COREBOS_DESCRIBE.join(',')).then((descinfo: any) => {
        for (let key in descinfo) {
            Describe[key] = descinfo[key];
            let modulePermissions = {
                module: key,
                permissions: {
                    create: descinfo[key].createable,
                    delete: descinfo[key].deleteable,
                    update: descinfo[key].updateable,
                    list: descinfo[key].retrieveable,
                }
            };
            permissions.push(modulePermissions);
        }
        return permissions;
    }).then(async (result: any) => {
        await saveDataToLocalDB(TABLE_PERMISSIONS.tableName, result, true);
        let AssignedUserList = [];
        for (let mod = 0; mod < COREBOS_DESCRIBE.length; mod++) {
            if (Describe[COREBOS_DESCRIBE[mod]]) {
                AssignedUserList.push(
                    doInvoke('getAssignedUserList', { module: COREBOS_DESCRIBE[mod] }, 'GET').then((users: any) => {
                        Describe[COREBOS_DESCRIBE[mod]].userlist = JSON.parse(users);
                    })
                );
            }
        }


        return Promise.all(AssignedUserList).then(() => {
            return saveDataToLocalDB(TABLE_DESCRIBE.tableName, Describe, false);
        }).then(() => {
            return Promise.resolve();
        })
    });
}

export const convertFileToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

export const getFileExtension = (filename: string) => {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
}
export const parseDateForAPI = (v: any) => {
    // console.log('parse time', v);
    return parseDate(v, 'yyyy-mm-dd');
};
export const dateFormatter = (v: any) => formatDate(v);
export const dateTimeFormatter = (v: any) => {
    const dateTime = formatDate(v);
    if (!dateTime) return;
    let date = new Date(v);
    let hh: any = date.getHours();
    hh = ("0" + hh).slice(-2);
    let minutes: any = date.getMinutes();

    return `${dateTime} ${hh}:${minutes}`;
};
export const dateParser = (v: any) => parseDate(v);
export const dateTimeParser = (v: any) => {
    if (!v) return;
    const dateTime = parseDate(v);
    if (!dateTime) return;
    const date = new Date(v);
    let hh: any = date.getHours();
    hh = ("0" + hh).slice(-2);
    let minutes: any = date.getMinutes();
    minutes = ("0" + minutes).slice(-2);

    return `${dateTime} ${hh}:${minutes}`;
}
export const timeRestrictionSetter = (date?: any, maxTime?: any, minTime?: any, initialTime?: any) => {
    const timeRestrictionObject = {
        minTime: minTime ? minTime : setHours(setMinutes(new Date(), 0), minimumHour), //minTime will be with value only on time_from field
        minDate: minTime ? minTime : new Date(),
        selectedDate: date,
        maxTime: maxTime
    }
    const _minTimeNextDay: Date = setHours(setMinutes(addDays(new Date(), 1), 0), minimumHour);
    const _minTimeToday: Date = setHours(setMinutes(new Date(), 0), minimumHour);
    if (excludedTimes.includes(new Date().getHours()) || isAfter(new Date(), maxTime)) {
        if (nightExcludedTimes.includes(new Date().getHours()) || isAfter(new Date(), maxTime)) {
            timeRestrictionObject.minDate = (minTime && !isBefore(minTime, _minTimeNextDay)) ? minTime : addDays(new Date(), 1);
            timeRestrictionObject.minTime = (minTime && !isBefore(minTime, _minTimeNextDay)) ? minTime : _minTimeNextDay; //if it is currently in the night excluded times the only date available will be tomorrow
            timeRestrictionObject.maxTime = addDays(timeRestrictionObject.maxTime, 1);
            if (date) { //if it is in the night excluded times and it is selecting a date
                timeRestrictionObject.selectedDate = filterInsertedDate(date, timeRestrictionObject.minTime, timeRestrictionObject.maxTime);
            }
        } else { // it is in the morning excluded dates
            timeRestrictionObject.minTime = isAfter(timeRestrictionObject.minTime, _minTimeToday) ? timeRestrictionObject.minTime : _minTimeToday;
            // timeRestrictionObject.maxTime = addDays(timeRestrictionObject.maxTime, 1);

            // timeRestrictionObject.maxTime = isAfter(timeRestrictionObject.minTime, _minTimeToday) ? _minTimeToday : timeRestrictionObject.minTime;
            if (date && isAfter(date, new Date()) && date.getDate() > new Date().getDate()) {
                timeRestrictionObject.maxTime = addDays(timeRestrictionObject.maxTime, 1);
                timeRestrictionObject.minTime = (minTime && !isBefore(minTime, _minTimeNextDay)) ? minTime : _minTimeNextDay; // *min time restrictions for the next day. if min time given is not before next day restriction, set it
            }
            if (date) {
                timeRestrictionObject.selectedDate = filterInsertedDate(date, timeRestrictionObject.minTime, timeRestrictionObject.maxTime);
            }
        }
    } else { //if it is not in the excluded times
        if (date && isAfter(date, new Date()) && date.getDate() > new Date().getDate()) {
            timeRestrictionObject.maxTime = addDays(timeRestrictionObject.maxTime, 1);
            timeRestrictionObject.minTime = (minTime && !isBefore(minTime, _minTimeNextDay)) ? minTime : _minTimeNextDay; // *min time restrictions for the next day. if min time given is not before next day restriction, set it
            if (areSelectedDataAndCurrentOneEqual(date, initialTime)) { //* if there is initial time (startDate case )and it is currently selected dont filter the minutes
                timeRestrictionObject.selectedDate = initialTime;
            } else {
                timeRestrictionObject.selectedDate = filterInsertedDate(date, timeRestrictionObject.minTime, timeRestrictionObject.maxTime) //filter the date and set it
            }
        } else { //today is selected or it is not selected anything
            timeRestrictionObject.minTime = minTime ?
                minTime :
                setMinutes(addHours(new Date(), 1), formatMinutes(new Date().getMinutes())); //today min time restrictions

            if (date) {
                if (areSelectedDataAndCurrentOneEqual(date, initialTime)) { //* if there is initial time (startDate case )and it is currently selected dont filter the minutes
                    timeRestrictionObject.selectedDate = initialTime;
                } else {
                    timeRestrictionObject.selectedDate = filterInsertedDate(date, timeRestrictionObject.minTime, timeRestrictionObject.maxTime);
                }
            }
        }
    }
    return timeRestrictionObject;
}

export const areSelectedDataAndCurrentOneEqual = (filteredData: any, currentData: any) => {
    return (
        filteredData?.getDate() === currentData?.getDate() &&
        filteredData?.getHours() === currentData?.getHours() &&
        filteredData?.getMinutes() === currentData?.getMinutes() &&
        filteredData?.getMonth() === currentData?.getMonth() &&
        getYear(filteredData) === getYear(currentData)
    )
}
export const generateInitialStartdate = () => {
    let _date: Date = new Date();
    if (excludedTimes.includes(_date.getHours())) {
        if (nightExcludedTimes.includes(_date.getHours())) {
            _date = setHours(setMinutes(addDays(new Date(), 1), 0), minimumHour);
        } else {
            _date = setHours(setMinutes(new Date(), 0), minimumHour);

        }
    }

    return _date;
}

const filterInsertedDate = (date: any, minTime: any, maxTime: any) => {
    
    let closest: number
    let formattedDate: any;
    if (date < minTime || date > maxTime) {
        closest = formatMinutes(minTime.getMinutes())
        if (closest < minTime.getMinutes()) {
            //* check if closest minutes are smaller that min time minutes and if so set the next minutes in the @acceptedMinutes array
            const indexOfClosest: number = acceptedMinutes.indexOf(closest);
            closest = acceptedMinutes[indexOfClosest + 1];
        }
        formattedDate = setMinutes(new Date(minTime), closest);
    } else {
        let minutes = date.getMinutes();
        closest = formatMinutes(minutes);
        formattedDate = setMinutes(new Date(date), closest);
    }
    return formattedDate;
}

const formatMinutes = (minutes: number) => {
    return acceptedMinutes.reduce((prev, curr) => {
        return (Math.abs(curr - minutes) < Math.abs(prev - minutes) ? curr : prev);
    });
}
export const timeUntilRestrictionSetter = (timeFromDate: any, timeUntil: any, minTimeRestriction: any) => {
    const timeUntilRestrictionObject = {
        minTime: minTimeRestriction,
        selectedDate: timeUntil
    }
    if (timeFromDate) {
        if (timeUntil && isAfter(timeUntil, timeFromDate) && timeUntil?.getDate() > timeFromDate?.getDate()) {
            timeUntilRestrictionObject.minTime = setHours(setMinutes(addDays(new Date(), 1), 0), 9);
        } else {
            timeUntilRestrictionObject.minTime = setHours(timeFromDate, timeFromDate?.getHours() + 1);
        }
        if (timeUntilRestrictionObject.selectedDate < timeUntilRestrictionObject.minTime) timeUntilRestrictionObject.selectedDate = timeUntilRestrictionObject.minTime;
    }
    return timeUntilRestrictionObject;
}
export const genereateUid = () => {
    const head: string = Date.now().toString(36);
    const tail: string = Math.random().toString(36).substring(2);
    return head + tail;
}


// export const parsingData = (values : any) => {
//     console.log('Validate Record', values);
  
//     values.startdate = format(new Date(values.startDate ?? values.initialStartDate), "dd-MM-yyyy HH:mm:ss");
//     values.scheduledDate = format(new Date(values.timeFromDate ?? values.initialStartDate), "dd-MM-yyyy HH:mm:ss");
        
//     // Pickup
//     values.pickupContact = values.referente;
//     values.pickupContactEmail = values['email_task-Pick-up'];
//     values.pickupAddress = values['customer_address'];
//     values.pickupPhone = innerStringWhiteSpaceRemoval(values.pickupPhone);

//     // Delivery
//     values.deliveryContact = values.referente;
//     values.deliveryEmail = values['email_task-Delivery'];
//     values.deliveryPhone = innerStringWhiteSpaceRemoval(values.pickupPhone );

//     // Global       
         
//     values.tot_large_packages; 
//     values.tot_small_packages;
//     values.tot_medium_packages;


// }