import { Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_CurrentCultureDto, Volo_Abp_Localization_LanguageInfo } from "../packages/proxy/src";
import { AbpApplicationConfigurationService, 
    Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationAuthConfigurationDto, 
    Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationConfigurationDto, 
    Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_CurrentUserDto} from "../packages/routing/proxy/src";


class AbpConfigurationProvider {
    
    private _configuration: Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationConfigurationDto | undefined = undefined;

    async fetchData(withoutLocalization : boolean | undefined): Promise<any> {
        // console.log('FetchData', withoutLocalization);
        let noLocalization = withoutLocalization ? withoutLocalization : false;
        return await AbpApplicationConfigurationService.getApiAbpApplicationConfiguration(noLocalization) 
        .then((result : Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationConfigurationDto)=>{
            // console.log('Translate data', result);
            if(!noLocalization){
                this._configuration = {
                    ...this._configuration, ...result};
            }else{
                this._configuration = result;
            }
            
            // console.log('FetchData result', this._configuration);
            return result;
        });
    }

    getPermission():Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationAuthConfigurationDto | undefined{
        // console.log('GetPermission');
        return this._configuration?.auth;
    }
    
    getConfiguration():Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationConfigurationDto | undefined{
        // console.log('GetPermission');
        return this._configuration;
    }

    getUser():Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_CurrentUserDto | undefined{
        return this._configuration?.currentUser;
    }

    getLanguages(): Volo_Abp_Localization_LanguageInfo[] | null | undefined{
        return this._configuration?.localization?.languages
    }

    getCulture():Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_CurrentCultureDto | undefined{
        return this._configuration?.localization?.currentCulture;
    }

    clean() {
        this._configuration = undefined;
      }
}
// export {AbpConfigurationProvider};
export const abpConfigurationProvider = new AbpConfigurationProvider();