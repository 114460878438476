import React, {useEffect} from 'react';
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useStyles} from "./AuthStyle";
import TextField from '@material-ui/core/TextField';
import {useState} from "react";
import "../index.css";
import checked from '../assets/checked.png';
import {useRedirect, useTranslate} from "react-admin";
import {useLocation} from "react-router-dom";
import {doInvoke, getChallenge} from "../utils/lib";
import TokenError from "./TokenError";

const ResetPasswordForm = () => {
    const [firstPass, setFirstPass] = useState<string>('');
    const [secondPass, setSecondPass] = useState<string>('');
    const [wrongInput, setError] = useState({
        triggered: false,
        message: ""
    });
    const [success, setSuccess] = useState<boolean>(false);
    const classes = useStyles();
    const redirect = useRedirect();
    const {search} = useLocation();
    const [email, setEmail] = useState<any>(null);
    const [token, setToken] = useState<any>(null);
    const [tokenValidated, setTokenValidated] = useState<boolean>(false);
    const [finished, setFinished] = useState<boolean>(false);
    const [sessionName, setSessionName] = useState<string>('');
    const params = new URLSearchParams(search);
    if (!email) setEmail(params.get('email'));
    if (!token) setToken(params.get('token'));
    const translate = useTranslate();


    useEffect(() => {
        getChallenge('pwa.admin', 'xssLTMR8vNz7j252').then((data) => {
            // if (data) {
            //     if (!sessionName) setSessionName(data?.result?.sessionName)
            //     const validateTokenParams = {
            //         method: "validate_token",
            //         data: `[{\"token\": \"${token}\", \"email\": \"${email}\"}]` // eslint-disable-line
            //     }
            //     //validate the token
            //     doInvoke(
            //         'ForgotPassword',
            //         validateTokenParams,
            //         'POST'
            //     ).then(
            //         () => {
            //             setTokenValidated(true);
            //         },
            //         () => {
            //             setFinished(true)
            //         })
            // }
        });
    }, [email, sessionName, token, tokenValidated]);

    const formSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (firstPass === secondPass) {
            if (firstPass.length >= 8) {
                if (firstPass.match(/\d/)) {
                    if (firstPass.match(/[A-Z]/)) {
                        //it is okay
                        setError({
                            triggered: false,
                            message: "",
                        });
                        const changePaswordParams = {
                            method: "change_password",
                            data: `[{\"token\": \"${token}\", \"email\": \"${email}\",  \"new_password\": \"${firstPass}\", \"confirm_password\": \"${secondPass}\"}]` // eslint-disable-line
                        }
                        doInvoke(
                            'ForgotPassword',
                            changePaswordParams,
                            'POST'
                        ).then(() => {
                            setFirstPass('');
                            setSecondPass('');
                            setSuccess(!success);
                        })

                    } else {
                        setError({
                            triggered: true,
                            message: translate('translations.capital_letter_validation'),
                        });
                    }
                } else {
                    setError({
                        triggered: true,
                        message: translate('translations.number_validation'),
                    });
                }
            } else {
                setError({
                    triggered: true,
                    message: translate('translations.password_length'),
                });
            }
        } else {
            setError({
                triggered: true,
                message: translate('translations.pasword_match_validation'),
            });
        }

    }
    if (success) {
        document.body.classList.add('active-modal')
        setTimeout(() => {
            setSuccess(false);
            redirect('/login');
        }, 5000);
    } else {
        document.body.classList.remove('active-modal')
    }
    return (
        <>
            {tokenValidated && <Box display={'flex'} flexDirection={'column'} p={6} mt={10}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} style={{marginBottom: "50px"}}>
                    <Typography className={classes.chPassword}>Cambia Password</Typography>
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'}>
                    <Box>
                        <form>
                            <Box my={1}>
                                <label className={classes.formLabels}>Nuova Password</label>
                                <TextField
                                    error={wrongInput.triggered}
                                    helperText={wrongInput.message}
                                    autoComplete={'off'}
                                    value={firstPass}
                                    type={'password'}
                                    // label="Nuova Password"
                                    // placeholder="Email"
                                    name={'password'}
                                    fullWidth
                                    // id="emailInput"
                                    variant="outlined"
                                    size="small"
                                    // type="email"
                                    onChange={e => setFirstPass(e.target.value)}
                                />
                            </Box>
                            <Box my={1}>
                                <label className={classes.formLabels}>Conferma Nuova Password</label>
                                <TextField
                                    error={wrongInput.triggered}
                                    autoComplete={'off'}
                                    value={secondPass}
                                    type={'password'}

                                    // label="Nuova Password"
                                    // placeholder="Email"
                                    name={'password'}
                                    fullWidth
                                    // id="emailInput"
                                    variant="outlined"
                                    size="small"
                                    // type="email"
                                    onChange={e => setSecondPass(e.target.value)}
                                />
                            </Box>
                            <Box my={2}>
                                <button type={'submit'} onClick={e => formSubmit(e)}
                                        className={classes.savePasswordBtn}>
                                    Salva
                                </button>
                            </Box>
                        </form>
                    </Box>
                    <Box>
                        <Typography className={classes.formLabels} align={'left'} paragraph={true}>La password deve
                            contenere:</Typography>
                        <Typography className={classes.passRules} align={'left'}>Almeno 1 lettera maiuscola
                            (A-Z)</Typography>
                        <Typography className={classes.passRules} align={'left'}>Almeno 1 numero</Typography>
                        <Typography className={classes.passRules} align={'left'}>Almeno 8 caratteri</Typography>
                    </Box>
                </Box>
                {success ?
                    (<Box className={'modal'}>
                        <div onClick={() => redirect('/login')} className="overlay"></div>
                        <Box display={'flex'} flexDirection={'column'} className={'modal-content'} p={5}>
                            <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <img alt={'Checkedimage'} width={'10%'} src={checked}/>
                            </Box>
                            <Box my={1} width={'100%'} style={{fontSize: "16px"}} display={'flex'}
                                 justifyContent={'center'} alignItems={'center'}>Password Cambiata con successo!</Box>
                        </Box>
                    </Box>) : ''}
            </Box>}
            {(finished && !tokenValidated) ? <TokenError sessionName={sessionName} email={email}/> : ''}
        </>
    );
};

export default ResetPasswordForm;