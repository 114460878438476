/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_ListResultDto_1 } from '../models/Volo_Abp_Application_Dtos_ListResultDto_1';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1 } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1';
import type { wora_CorbosService_Shared_DownloadTokenResultDto } from '../models/wora_CorbosService_Shared_DownloadTokenResultDto';
import type { wora_CorbosService_Stores_GetStoresInput } from '../models/wora_CorbosService_Stores_GetStoresInput';
import type { wora_CorbosService_Stores_StoreCreateDto } from '../models/wora_CorbosService_Stores_StoreCreateDto';
import type { wora_CorbosService_Stores_StoreDto } from '../models/wora_CorbosService_Stores_StoreDto';
import type { wora_CorbosService_Stores_StoreUpdateDto } from '../models/wora_CorbosService_Stores_StoreUpdateDto';
import type { wora_CorbosService_Stores_UpdateStoreAddressInput } from '../models/wora_CorbosService_Stores_UpdateStoreAddressInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StoreService {

    /**
     * @param requestBody
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1<Dm_Application_Comboxes_ComboboxItemDto_Dm_Application_Contracts_Version_1_2_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static postApiCorbosServiceStoresComboboxItems(
        requestBody?: wora_CorbosService_Stores_GetStoresInput,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/corbos-service/stores/combobox-items',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns binary Success
     * @throws ApiError
     */
    public static postApiCorbosServiceStoresExcel(
        requestBody?: wora_CorbosService_Stores_GetStoresInput,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/corbos-service/stores/excel',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static postApiCorbosServiceStoresExcelBatch(
        requestBody?: wora_CorbosService_Stores_GetStoresInput,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/corbos-service/stores/excel-batch',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1<wora_CorbosService_Stores_StoreDto_wora_CorbosService_Application_Contracts_Version_1_3_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static postApiCorbosServiceStores(
        requestBody?: wora_CorbosService_Stores_GetStoresInput,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/corbos-service/stores',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns wora_CorbosService_Stores_StoreDto Success
     * @throws ApiError
     */
    public static getApiCorbosServiceStores(
        id: string,
    ): CancelablePromise<wora_CorbosService_Stores_StoreDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/corbos-service/stores/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns wora_CorbosService_Stores_StoreDto Success
     * @throws ApiError
     */
    public static putApiCorbosServiceStores(
        id: string,
        requestBody?: wora_CorbosService_Stores_StoreUpdateDto,
    ): CancelablePromise<wora_CorbosService_Stores_StoreDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/corbos-service/stores/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiCorbosServiceStores(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/corbos-service/stores/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns wora_CorbosService_Stores_StoreDto Success
     * @throws ApiError
     */
    public static postApiCorbosServiceStoresCreate(
        requestBody?: wora_CorbosService_Stores_StoreCreateDto,
    ): CancelablePromise<wora_CorbosService_Stores_StoreDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/corbos-service/stores/create',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param downloadToken
     * @param filterText
     * @param name
     * @param number
     * @param address
     * @param addressNumber
     * @param province
     * @param city
     * @param zipCode
     * @param vatId
     * @param fiscalCode
     * @param openingTimeMin
     * @param openingTimeMax
     * @param closingTimeMin
     * @param closingTimeMax
     * @param businessPartnerId
     * @param contactId
     * @param pickupInstructions
     * @param deliveryInstructions
     * @param timeCalendar
     * @param activeFunctions
     * @returns binary Success
     * @throws ApiError
     */
    public static getApiCorbosServiceStoresAsExcelFile(
        downloadToken?: string,
        filterText?: string,
        name?: string,
        number?: string,
        address?: string,
        addressNumber?: string,
        province?: string,
        city?: string,
        zipCode?: string,
        vatId?: string,
        fiscalCode?: string,
        openingTimeMin?: string,
        openingTimeMax?: string,
        closingTimeMin?: string,
        closingTimeMax?: string,
        businessPartnerId?: number,
        contactId?: number,
        pickupInstructions?: string,
        deliveryInstructions?: string,
        timeCalendar?: string,
        activeFunctions?: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/corbos-service/stores/as-excel-file',
            query: {
                'DownloadToken': downloadToken,
                'FilterText': filterText,
                'Name': name,
                'Number': number,
                'Address': address,
                'AddressNumber': addressNumber,
                'Province': province,
                'City': city,
                'ZipCode': zipCode,
                'VatID': vatId,
                'FiscalCode': fiscalCode,
                'OpeningTimeMin': openingTimeMin,
                'OpeningTimeMax': openingTimeMax,
                'ClosingTimeMin': closingTimeMin,
                'ClosingTimeMax': closingTimeMax,
                'BusinessPartnerId': businessPartnerId,
                'ContactId': contactId,
                'PickupInstructions': pickupInstructions,
                'DeliveryInstructions': deliveryInstructions,
                'TimeCalendar': timeCalendar,
                'ActiveFunctions': activeFunctions,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns wora_CorbosService_Shared_DownloadTokenResultDto Success
     * @throws ApiError
     */
    public static getApiCorbosServiceStoresDownloadToken(): CancelablePromise<wora_CorbosService_Shared_DownloadTokenResultDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/corbos-service/stores/download-token',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param businessPartnerId
     * @returns Volo_Abp_Application_Dtos_ListResultDto_1<Dm_Application_Comboxes_ComboboxItemDto_Dm_Application_Contracts_Version_1_2_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getApiCorbosServiceStoresGetStoresCombobox(
        businessPartnerId?: number,
    ): CancelablePromise<Volo_Abp_Application_Dtos_ListResultDto_1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/corbos-service/stores/get-stores-combobox',
            query: {
                'businessPartnerId': businessPartnerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns wora_CorbosService_Stores_StoreDto Success
     * @throws ApiError
     */
    public static postApiCorbosServiceStoresUpdateAddress(
        requestBody?: wora_CorbosService_Stores_UpdateStoreAddressInput,
    ): CancelablePromise<wora_CorbosService_Stores_StoreDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/corbos-service/stores/update-address',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
