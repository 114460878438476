import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {AppButton} from '../components/AppButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import forgotPasswordasideImg from '../assets/forgotPasswordasideImg.png';
import {Logo} from '../components/Logo';
import {AuthAsideBlock} from './AuthAside';
import {useStyles} from './AuthStyle';
import {
    useRedirect, useNotify, Notification, useTranslate
} from 'react-admin';
import AuthMessage from './AuthMessage'
import CircularProgress from '@material-ui/core/CircularProgress';
import previewEnvelopeImg from '../assets/previewEnvelopeImg.png';
import {doInvoke, getChallenge} from "../utils/lib";


const ResetPassword = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    const notify = useNotify();
    const [email, setEmail] = useState<string>('');
    const [showAuthMessage, setShowAuthMessage] = useState<boolean>(false);
    const [sLoading, setIsLoading] = useState<boolean>(false);
    const translate = useTranslate();

    const resetPasswordMessage: string = translate('translations.reset_password_message');

    const onSubmit = (event: any) => {
        event.preventDefault();
        if (!email) {
            notify(translate('AbpIdentity::EmailAddress') + '. ' + translate('ra.validation.required '), 'error')
            return;
        }
        setIsLoading(true);
        getChallenge('pwa.admin', 'xssLTMR8vNz7j252').then((data) => {
                // if (data) {
                //     const changePasswordParams = {
                //         method: "check_user_email",
                //         data: `[{\"email\": \"${email}\"}]` // eslint-disable-line
                //     }
                //     doInvoke(
                //         'ForgotPassword',
                //         changePasswordParams,
                //         'POST'
                //     ).then(
                //         () => {
                //             setShowAuthMessage(true)
                //             notify(resetPasswordMessage, 'success');
                //         },
                //         () => {
                //             notify("L'email inserita non esiste", "error");
                //         })
                //     setIsLoading(false);
                // }
            }
            , () => {
                notify(translate('translations.something_went_wrong'), "error");
                setIsLoading(false);
            }
        )
    }

    const ActionBtnGroup = () => (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Box my={2}>
                    <AppButton fullWidth variant="contained" onClick={() => redirect('/login')} size="large"
                               className={classes.btnPrimary2} style={{boxShadow: 'none'}}>
                        {translate('AbpAccount::BackToLogin')}
                    </AppButton>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box my={2}>
                    <AppButton id={'sendAgainButton'} onClick={(e) => {
                        onSubmit(e)
                    }} fullWidth variant="contained" size="large" color={'primary'}>
                        {translate('translations.send_again')}
                    </AppButton>
                </Box>
            </Grid>
        </Grid>
    )

    return (
        <>
            {showAuthMessage ? (
                <AuthMessage actionBtnGroup={<ActionBtnGroup/>} image={previewEnvelopeImg} title={''}
                             message={resetPasswordMessage}/>
            ) : (
                <div className={classes.root}>
                    <Logo/>
                    <div className={classes.authContainer}>
                        <Grid container spacing={10}>
                            <Grid item xs={12} md={6}>
                                <AuthAsideBlock imgSrc={forgotPasswordasideImg}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card className={classes.authCard} variant="outlined">
                                    <CardContent>
                                        <Typography component="div">
                                            <Box className={classes.authTitle} textAlign="center" mt={0.2} mb={1.2}>
                                                {translate('AbpAccount::ForgotPassword')}
                                            </Box>
                                            <Box textAlign="center">
                                                <Typography variant="body2" gutterBottom>
                                                    {translate('AbpIdentity::DisplayName:EmailAddress')}
                                                </Typography>
                                            </Box>
                                        </Typography>

                                        <form onSubmit={onSubmit} autoComplete="off">
                                            <Box>
                                                <TextField
                                                    placeholder="Email"
                                                    name={'email'}
                                                    fullWidth
                                                    id="emailInput"
                                                    variant="outlined"
                                                    size="small"
                                                    type="email"
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </Box>

                                            <Box my={2}>
                                                <AppButton type={'submit'} disabled={sLoading} startIcon={sLoading ?
                                                    <CircularProgress color={sLoading ? 'primary' : 'secondary'}
                                                                      size={24}/> : <></>} fullWidth variant="contained"
                                                           color="primary" size="large"
                                                           className={classes.authSubmitBtn}>
                                                    {translate('AbpAccount::ResetPassword')}
                                                </AppButton>
                                            </Box>
                                            <Box my={2}>
                                                <AppButton fullWidth variant="contained" color="secondary"
                                                           onClick={() => redirect('/login')} size="large"
                                                           className={classes.authSubmitBtn}>
                                                    {translate('AbpAccount::BackToLogin')}
                                                </AppButton>
                                            </Box>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )}
            <Notification/>
        </>
    )
};

export default ResetPassword;