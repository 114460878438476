import React, { useEffect, useState } from 'react';
import { MenuItem, Select }from '@material-ui/core';
import { useSetLocale, useTranslate } from 'react-admin';
import zIndex from '@material-ui/core/styles/zIndex';

const LanguageSwitcher = () => {

    const translate = useTranslate();
    const setLocale = useSetLocale();

    const [language, setLanguage] = useState(localStorage.getItem('localization') || 'it'); // Imposta lingua predefinita o leggi dal localStorage

    useEffect(() => {
      // Imposta la lingua salvata come lingua iniziale
      setLocale(language);
    }, [language, setLocale]);

    const changeLanguage = (event: any) => {
      const selectedLanguage = event.target.value;
      setLanguage(selectedLanguage); // Aggiorna lo stato della lingua
      setLocale(selectedLanguage); // Cambia la lingua dell'app
      localStorage.setItem('localization', selectedLanguage); // Salva la lingua nel localStorage

      // Ricarica l'applicazione
      window.location.reload();
    };

  const mystyle = {
    color: "white",
    zIndex:1000,
    fontFamily: "Arial"
  };

  return (
    <Select style={mystyle}
      label="Language"
      value={language} 
      onChange={changeLanguage}
      displayEmpty
      MenuProps={{
        PaperProps: {
          style: {
            marginTop: '60px', // Sposta il menu di 60px verso il basso
          },
        },
      }}
    >    
    <MenuItem value="it">Italiano</MenuItem>
    <MenuItem value="en">English</MenuItem> 
    <MenuItem value="de">Deutsh</MenuItem>  
      {/* Add more languages and their labels as needed */}
    </Select>
  );
};

export default LanguageSwitcher;