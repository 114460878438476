import {useEffect, useState} from 'react';
import {
    Loading,
    useGetIdentity,
    useTranslate
} from 'react-admin';
import {useStyles} from '../style/Index';
import {useParams } from "react-router-dom";
import { NewOrder } from './NewOrder';
import { ShopDeliveryService, Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_CurrentUserDto, wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto } from '../packages/proxy/src';
import useAbp from '../useAbp';
import { ReturnOrder } from './ReturnOrder';
import ShopToShopOrder from './ShopToShopOrder';

export const OrderEdit = (props: any) => {
    // console.log('OrderEdit', props);
    const translate = useTranslate();
    const [record, setRecord] = useState<wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto>({});
    const classes = useStyles();
    const { identity } = useGetIdentity();
    const { id } = useParams<{ [key: string]: string }>();
    const abp = useAbp();

    useEffect(() => {
        // console.log('Select Edit Order',props);
        
        
    }, []) // eslint-disable-line

    useEffect(() => {
        const userdata: Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_CurrentUserDto | undefined = abp && abp.configuration?.currentUser;
        const contactDetail = identity && identity.contactDetail;
        
        if (id !== undefined) {
            // console.log('Load Order with Id ->', id);
            ShopDeliveryService.getApiShopDeliveriesGetData(id)
                .then((data: wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto) => {
                    // console.log('Order to Edit ->', data);
                    setRecord(data);
                });
        }
    }, [abp])

    if (!record || Object.keys(record).length === 0) return <Loading />;
    
    return (
        <>
        {
            record && record.orderType === 1 ? <ReturnOrder {...props} record={record}></ReturnOrder> : 
                (record && record.orderType === 0 ? <NewOrder {...props} record={record}></NewOrder> : 
                                                    <ShopToShopOrder {...props} record={record}></ShopToShopOrder>)
        }
        </>
    );
};
