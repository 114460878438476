import * as React from 'react';
import { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';
import {
    Button,
    Datagrid,
    downloadCSV,
    ExportButton,
    Filter,
    List,
    ListProps,
    Pagination,
    Responsive,
    sanitizeListRestProps,
    SimpleList,
    TextInput,
    DateInput,
    useListContext,
    useTranslate,
    useDataProvider,
    useLocale,
    Record
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import Grid from '@material-ui/core/Grid';
import { parseDateForAPI } from '../utils/Helpers';
import FieldDisplay from '../utils/FieldDisplay';
import FieldInput from '../utils/FieldInput';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import AzioneField from "../components/AzioneField";
import OrderCancelBox from "./components/OrderCancelBox";
import OrderCancelNotice from "./components/OrderCancelNotice";
import InputAdornment from "@material-ui/core/InputAdornment";
import jsonexport from 'jsonexport/dist';
import {LayoutTemplateService, Dm_DynamicTemplate_Layouts_LayoutUiDto}from '../packages/routing/proxy/src';
import { FieldInputTypeDto, PickListDto } from '../types/FieldInputTypeDto';
import { FieldDisplayUiType } from '../types/FieldDisplayUiType';
import { wora_CorbosService_Deliveries_DeliveryType, wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryCreateDto, wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto } from '../packages/proxy/src';

const useActionStyles = makeStyles(theme => ({
    root: {
        '& .MuiIconButton-root': {
            padding: 0,
            fontSize: '1em',
        },
        '& .MuiSvgIcon-root': {
            width: '1em',
            height: '1em',
            fontSize: '1em',
        },
        '& .MuiFilledInput-root': {
            backgroundColor: '#fff',
        },
        '& .MuiFilledInput-underline': {
            '&:before': {
                borderBottom: '0 !important'
            },
            '&:after': {
                borderBottom: '0 !important'
            },
        },

        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        },
        /* '& .MuiInputBase-input': {
          height: '6px',
        }, */
        '& .MuiOutlinedInput-root': {
            background: '#fff',
            padding: '0 14px',
            marginTop: '-5px',
        },
        '& .MuiFormLabel-root': {
            fontSize: '14px',
            marginTop: '-5px',
            marginLeft: '8px',
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            marginTop: '-20px !important',
        },
    },
    topBarBox: {
        width: '100%',
    },
    topBarListAction: {
        justifyContent: 'flex-start',
    },
    listActionBtn: {
        padding: '8px 0px',
        borderRadius: '0',
        boxShadow: 'none',
        fontWeight: 'normal',
        minWidth: '35px',
        fontSize: '25px',
    },
    filterActionBtn: {
        paddingLeft: '2px',
        paddingRight: '10px',
        height: '38px',
        borderRadius: '5px',
        boxShadow: 'none',
        margin: '0px 5px 50px 5px !important'
    },
}));

export const OrderList = (props: ListProps) => {
    // console.log('Order list', props);
    const classes = useActionStyles();
    const dataProvider = useDataProvider();
    const resource: string = props.resource ?? '';
    // const tableService = useContext(typeTableDefinition);
    const [describe, setDescribe] = useState<any>(null);
    const [assignedUserList, setAssignedUserList] = useState<any[]>([]);
    const [displayFilers, setDisplayFilters] = useState<FieldInputTypeDto[]>([]);
    const [displayFields, setDisplayFields] = useState<FieldInputTypeDto[]>([]);
    const { data, ids, loaded, currentSort, total, selectedIds } = useListContext<any>();
    const defaultfilters = ['deliveryContact','storeName', 'orderDate',"externalOrderCode", 'city', 'to'];
    const [basicfilters, setBasicfilters] = useState<any[]>(['deliveryContact','storeName','orderDate', 'externalOrderCode']);
    const [advanced, setAdvanced] = useState<any>(false);
    const [showOrderCancelBox, setShowOrderCancelBox] = useState<boolean>(false);
    const [idOrderToBeCanceled, setIdOrderToBeCanceled] = useState<string>('');
    const [showCancelSuccess, setShowCancelSuccess] = useState<boolean>(false);
    const [recordToBeDeleted, setRecordToBeDeleted] = useState<any>({});
    const translate = useTranslate(); 
    const locale = useLocale();

    const cancelOrder = (record: any) => {
        const _recordPickUpDeliveryRelationShip = record?.externalOrderCode ?? '';
        setIdOrderToBeCanceled(_recordPickUpDeliveryRelationShip);
        setRecordToBeDeleted(record);
        setShowOrderCancelBox(true);
    }



    useEffect(() => {
        const bb = LayoutTemplateService.getApiDynamicTemplateTableByName('wora.CorbosService.Deliveries.DeliveryDto')
        // const aaa = DeliveryService.postApiCorbosServiceDeliveries({ skipCount: 0, maxResultCount: 10 })
            .then((result: Dm_DynamicTemplate_Layouts_LayoutUiDto) => {
                const uu = result;
                // Convert TelerikDto -> FieldInputTypeDto

                setDescribe(result);
                const showFields = ['creationTime','orderDate','externalOrderCode', 'city','storeName',  'deliveryContact','to', 
                 'statusName'];
                const dFields: FieldInputTypeDto[] = [];
                const dFilters: FieldInputTypeDto[] = [];
                let modFields : FieldInputTypeDto[] = [
                    
                    { name: "storeName", label: translate('CorbosService::Store'), mandatory: false, editable: true, uitype: FieldDisplayUiType.Text, defaultValue:'', placeholder:'', readonly:true },
                    { name: "externalOrderCode", label: translate('CorbosService::ExternalOrderCode'), mandatory: false, editable: true, uitype: FieldDisplayUiType.Text, defaultValue:'', placeholder:'', readonly:true },
                    { name: "orderCode", label: "Code", mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue:'', placeholder:'', readonly:true },
                    { name: "creationTime", label: translate('AbpIdentity::CreationTime'), mandatory: false, editable: true, uitype: FieldDisplayUiType.DateTime, defaultValue:'', placeholder:'', readonly:true },
                    { name: "city", label: translate('CorbosService::City'), mandatory: false, editable: true, uitype: FieldDisplayUiType.Text, defaultValue:'', placeholder:'', readonly:true },
                    { name: "deliveryContact", label: translate('CorbosService::Customer_account'), mandatory: false, editable: true, uitype: FieldDisplayUiType.Text, defaultValue:'', placeholder:'', readonly:true },
                    { name: "to", label: translate('CorbosService::Address'), mandatory: false, editable: true, uitype:  FieldDisplayUiType.Text, defaultValue:'', placeholder:'', readonly:true },
                    { name: "startTime", label: "startTime", mandatory: false, editable: true, uitype: FieldDisplayUiType.DateTime, defaultValue:'', placeholder:'', readonly:true },
                    { name: "endTime", label: "endTime", mandatory: false, editable: true, uitype: FieldDisplayUiType.DateTime, defaultValue:'', placeholder:'', readonly:true },
                    { name: "deliveryTypeName", label: translate('CorbosService::DeliveryType'), mandatory: false, editable: true, uitype: FieldDisplayUiType.Text, defaultValue:'', placeholder:'', readonly:true },
                    { name: "orderDate", label: translate('CorbosService::PickUp'), mandatory: false, editable: true, uitype:  FieldDisplayUiType.Date, defaultValue:'', placeholder:'', readonly:true },
                    { name: "orderType", label: "type_order", mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue:'', placeholder:'', readonly:true },
                    { name: "statusName", label: translate( "CorbosService::Status"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue:'', placeholder:'', readonly:true },
                ];
                showFields.forEach((fieldname :string)=>{
                    let a = modFields.find((field :any) => field?.name === fieldname);
                    if(a){
                        dFields.push(a);
                    }
                })
                defaultfilters.forEach((fieldname :string)=>{
                    let b = modFields.find((field :any) => field?.name === fieldname);
                    if(b){
                    dFilters.push(b);
                    }
                })

                setDisplayFields(dFields);
                setDisplayFilters(dFilters);
                const aUserList : any[] = [];
                setAssignedUserList(aUserList);
            })
        //    getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result: any) => {
        //     const showFields = ['time_from', 'time_until','customer_name', 'customer_address','task_type','task_id', 'related_order' , 'retailerid','type_order','stato', ];
        //     setDescribe(result);
        //     let modFields = result[resource]?.fields ?? [];
        //     const dFields: any[] = [];
        //     const dFilters: any[] = [];
        //     showFields.forEach((fieldname :string)=>{
        //         dFields.push(modFields.find((field :any) => field?.name === fieldname))
        //     })
        //     defaultfilters.forEach((fieldname :string)=>{
        //         dFilters.push(modFields.find((field :any) => field?.name === fieldname))
        //     })
        //     setDisplayFields(dFields);
        //     setDisplayFilters(dFilters);
        //     const aUserList = result[resource]?.userlist ?? [];
        //     setAssignedUserList(aUserList);
        //   });
    }, [resource, locale]) // eslint-disable-line

    const handleFilters = () => {
        setBasicfilters([...basicfilters, 'city', 'to']);
        setAdvanced(true);
    }

    const handleBasicFilters = () => {
        const advanced = basicfilters.filter(basic => !['city', 'to'].includes(basic));
        setBasicfilters(advanced);
        setAdvanced(false);
    }
    const Returntable = () => {
        setShowOrderCancelBox(false);
        setShowCancelSuccess(false);
    }

    //will be called when it is confirmed that the order will be cancelled
    const setShowCancelConfirm = () => {
        setShowOrderCancelBox(false);
        setShowCancelSuccess(true);
    }

    const ListFilter = (props: any) => {
        return (
            <Grid container spacing={0}>
                {
                    displayFilers.filter(display => basicfilters.includes(display.name)).map((field : FieldInputTypeDto) => {
                        let inputAdornment: any = <> </>;
                        let filterInput: any;
                        const extraProps = { label: '', inputAdornment: inputAdornment, alwaysOn: true };
                        if (field.name === 'contactName ') {
                            field.label = translate('translations.customer_name');
                            inputAdornment = <SearchOutlinedIcon />;
                            filterInput = <TextInput
                                variant="outlined"
                                key={field.name}
                                label={field.label}
                                source={'cblistsearch_' + resource}
                                fullWidth
                                size={'medium'}
                                alwaysOn={true}
                                InputProps={
                                    { endAdornment: <InputAdornment position={'end'}> {inputAdornment}  </InputAdornment> }
                                }
                            />

                        } else if (field.name === 'startTime' || field.name === 'endTime') {
                            filterInput = <DateInput parse={parseDateForAPI} variant='outlined' fullWidth disableUnderline source={field.name} label={field.label} alwaysOn />;
                        } else if (field.name === 'statusName') {
                            if (field.type == undefined){
                                field.type = {};
                            }
                            field.type.picklistValues = field?.type?.picklistValues?.filter((pickList: PickListDto) => pickList.value !== '--None--' && (pickList.value !== 'Awaiting Payment' || pickList.value !== 'In attesa di pagamento'))
                            filterInput = FieldInput(field, extraProps, '', describe, assignedUserList, translate);
                        } else {
                            filterInput = FieldInput(field, extraProps, '', describe, assignedUserList, translate);
                        }
                        if (field.name === 'deliveryType') {
                            if (field.type == undefined){
                            }
                            // const wrongPickUpValue = field.type.picklistValues.findIndex((option: any) => option.value === "0") || 0;
                            // wrongPickUpValue >= 0 && field.type.picklistValues.splice(wrongPickUpValue, 1);
                        }
                        if(field.uitype == FieldDisplayUiType.DateTime){
                            const objShallowCopy = Object.assign({}, field);
                            objShallowCopy.uitype = FieldDisplayUiType.Date;
                            filterInput = FieldInput(objShallowCopy, extraProps, '', describe, assignedUserList, translate);
                        }

                        return (
                            React.Children.toArray(
                                <>
                                    <Grid key={field.name + 'Grid'} item xs={12} md={3}>
                                        <Filter {...props} >
                                            {filterInput}
                                        </Filter>
                                    </Grid>
                                </>
                            )
                        )
                    })
                }
                <Grid item xs={12} md={3}>
                    <Box display="flex" style={{ paddingTop: 11 }}>
                        <Button variant="contained"
                            color="secondary"
                            className={classes.filterActionBtn}
                            onClick={() => props.setFilters({})} label={translate('AbpUi::Clear')}>
                            <></>
                        </Button>
                        {advanced ?
                            <Button
                                label={translate('CorbosService::BasicFilter')}
                                variant="contained"
                                color="secondary"
                                className={classes.filterActionBtn}
                                onClick={() => handleBasicFilters()}
                            /> :
                            <Button
                                label={translate('CorbosService::SeeAdvancedFilters')}
                                variant="contained"
                                color="secondary"
                                className={classes.filterActionBtn}
                                onClick={() => handleFilters()}
                            />}
                    </Box>
                </Grid>

            </Grid>
        )
    }

    const ListActions = (props: any) => {
        const { className, exporter, filters, maxResults, setFilters, ...rest } = props;

        const {
            currentSort,
            resource,
            filterValues,
            total,
        } = useListContext();

        return (
            <Grid container spacing={2} className={className} {...sanitizeListRestProps(rest)}>
                <Grid item xs={12} md={11}>

                    <ListFilter setFilters={setFilters} />

                </Grid>
                <Grid item xs={12} md={1}>
                    <Box display="flex" ml={3} py={2}>

                        <ExportButton
                            label=""
                            icon={<Tooltip title={translate('CorbosService::ExportToExcel')}><ArrowDownwardOutlinedIcon /></Tooltip>}
                            variant="text"
                            className={classes.listActionBtn}
                            disabled={total === 0}
                            resource={resource}
                            sort={currentSort}
                            filterValues={filterValues}
                            maxResults={maxResults}
                        />

                    </Box>
                </Grid>
            </Grid>
        );
    };

    const CustomPagination = (props: any) => {
        const labelDisplayedRows = React.useCallback(
            () =>
                '',
            []
        );
        return (
            <Pagination rowsPerPageOptions={[]} labelDisplayedRows={labelDisplayedRows} {...props} />
        )
    }

    const RowStyle = (record: any) => {
        return (
            { backgroundColor: record?.task_type === 'Delivery' ? '#BE8DB917' : '#fff', }
        )
    }
    const exporter = (data: any, fetchRelatedRecords: any) => {
        // console.log('export data call');
        
            let fetchedData = data.map((listRecord: wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto) => ({
                creationDate: listRecord.creationTime,
                pickuDate:listRecord.orderDate,
                relatedOrder: listRecord.externalOrderCode,
                // store:listRecord.storeName,
                city:listRecord.city,
                // cutomer: listRecord.deliveryContact,
                // deliveryAddress: listRecord.to,
                status:listRecord.statusName
                
                //TODO: ripristianre i campi nella lista sopra



                // deliveryType:listRecord.deliveryType,
                // task_type: listRecord?.task_type,
                // customer_name: accountNames.filter((record: any) => record.customer_name === listRecord.customer_name)[0].accountsaccountname,
                // phone: listRecord?.phone,
                // customer_address: listRecord?.customer_address,
                // startdate: listRecord?.startdate,
                // enddate: listRecord?.enddate,
                // retailerid: listRecord?.retailerid,
                // type_order: listRecord?.type_order,
                // contact_related: listRecord?.contact_related,
                // tot_medium_packages: listRecord?.tot_medium_packages,
                // tot_small_packages: listRecord?.tot_small_packages,
                // tot_large_packages: listRecord?.tot_large_packages,
                // citoffono: listRecord?.citoffono,
                // servizio_di_portineria: listRecord?.servizio_di_portineria,
                // stato: listRecord?.stato,
                // time_until: listRecord?.time_until,
                // time_from: listRecord?.time_from,
                // referente: listRecord?.,
                // modalita_di_consegna: listRecord?.modalita_di_consegna
            }))
            jsonexport(fetchedData, {
                headers: ['creationDate', 'pickuDate', 'relatedOrder', 'store', 'city', 'cutomer', 'deliveryAddress', 'deliveryType', 'status'],
            }, (err, csv) => {
                downloadCSV(csv, 'Tasks');
            });
        
    }

    function handleRecordClick(record: Record): void {
        
    }

    return (
        <div className={classes.root}>
            {showOrderCancelBox ?
                (<OrderCancelBox record={recordToBeDeleted} message={translate('CorbosService::CancelOrderConfirmationMessage') + (idOrderToBeCanceled != '' ? idOrderToBeCanceled+ '?': '')} Returntable={() => Returntable()} setShowCancelConfirm={() => setShowCancelConfirm()} />) :
                showCancelSuccess ? (<OrderCancelNotice returnBack={Returntable} message={translate('Crm::DeleteSuccessfully')} />) :
                    (
                        <>
                            <Box mt="5rem">
                                <Typography component="div">
                                    <Box fontWeight="fontWeightBold" fontSize="h4.fontSize">
                                        {translate('CorbosService::AllOrders')}
                                    </Box>
                                </Typography>
                            </Box>
                            <Box >
                                <List
                                    title={translate('translations.all_orders')}
                                    bulkActionButtons={false}
                                    resource={props.resource}
                                    perPage={20}
                                    pagination={<CustomPagination />}
                                    actions={<ListActions {...props} />}
                                    exporter={exporter}
                                    sort={{ field: 'CreationTime', order: 'ASC' }}
                                    {...props}
                                >

                                    <Responsive
                                        small={
                                            <SimpleList
                                            primaryText={record => (
                                                <span onClick={() => handleRecordClick(record)}>
                                                    {record.deliveryContact}
                                                </span>
                                            )}
                                                secondaryText={record => `${record.to}`}
                                                tertiaryText={record => record.statusName}
                                                linkType="show"
                                            />
                                        }
                                        medium={
                                            <Datagrid
                                                basePath={props.basePath}
                                                currentSort={currentSort}
                                                data={data}
                                                ids={ids}
                                                selectedIds={selectedIds}
                                                loaded={loaded}
                                                total={total}
                                                rowStyle={RowStyle}
                                                style={{ marginTop: '20px' }}
                                            >

                                                {
                                                    displayFields.map((field: FieldInputTypeDto) => {
                                                        return FieldDisplay(field, describe, assignedUserList);
                                                    })
                                                }


                                                <AzioneField {...props} cancelOrder={cancelOrder} />
                                            </Datagrid>
                                        }
                                    />
                                </List>
                            </Box>
                        </>
                    )
            }


        </div>
    );
};

