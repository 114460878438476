import {
    ImageField,
    RichTextField,
    NumberField,
    ReferenceField,
    TextField,
    SelectField,
    EmailField,
    UrlField,
    DateField,
} from 'react-admin';
import RawField from '../components/RawField';
import { FormattedBooleanField } from './Helpers';
import { FieldDisplayDto } from '../types/FieldDisplayDto';

const FieldDisplay = (field: FieldDisplayDto, describe: any = null, assignedUserList: any[] = [], record?: any) => {

    // console.log('@@@@@@@@@ FieldDisplay', field, describe, assignedUserList);
    
    if (field.name === 'id') return;

    let userlist = assignedUserList;

    switch (Number(field.uitype)) {
        //case 21: // TextBox small
        case 1:
        case 21:
            return (
                <TextField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    record={record}
                    fullWidth
                    style={(field.name === 'phone') ? { fontSize: "12px", color: "#1F1F1F" } : {}}
                />
            );
        case 5: // Date
            return (
                <DateField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    showTime={false}
                    fullWidth
                />
            );
        case 7: // Number
            return (
                <NumberField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    fullWidth
                />
            );
        case 9: // Percentage
            return (
                <NumberField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    options={{ style: 'percent' }}
                    fullWidth
                />
            );
        case 10: // Module Relation
            if (!describe) {
                return <></>;
            }
            if (!describe[field.type.refersTo[0]]) {
                return <></>;
            }
            let eidfield = describe[field.type.refersTo[0]].labelFields.split(',');
            return (
                <ReferenceField key={field.name} label={field.label} source={field.name} reference={field.type.refersTo[0]} link={false} sortBy={field.type.refersTo[0] + '.' + eidfield[0]} >
                    <TextField key={'ref' + field.name} source={eidfield[0]} />
                </ReferenceField>
            );
        case 13: // Email
            return (
                <EmailField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    fullWidth
                />
            );
        case 17: // URL
            return (
                <UrlField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    fullWidth
                />
            );
        case 19: // TextBox big
            return (
                <RichTextField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    fullWidth
                />
            );
        
         case 20: // Replace string
         return(<TextField
            key={field.name}
            label={field.label}
            source={field.name}
            fullWidth
            style={(field.name === 'phone') ? { fontSize: "12px", color: "#1F1F1F" } : {}}
        />);
        case 50: // DateTime
            return (
                <RawField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    showTime={true}
                    fullWidth
                />
            );
        case 52: // User Relation: Created and Modified by
            if (!describe || !describe[field.type.refersTo[0]]) {
                return <></>
            }

            return (
                <SelectField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    choices={userlist}
                    optionText="username"
                    optionValue="userid"
                    fullWidth
                />
            );
        case 53: // User Relation: Assigned To
            return (
                <SelectField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    choices={userlist}
                    optionText="username"
                    optionValue="userid"
                    fullWidth
                />
            );
        case 56: // Checkbox
            return (
                <FormattedBooleanField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    fullWidth
                />
            );
        case 69: // Image
            return (
                <ImageField
                    key={field.name}
                    label={field.label}
                    source={field.name + 'imageinfo.fullpath'}
                    fullWidth
                />
            );
        case 71: // Currency
            return (
                <NumberField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    options={{ style: 'currency', currency: 'EUR' }}
                    fullWidth
                />
            );
        case 85: // Skype
            // 4: mod_alert_arr.AutoGenerated,
            break;
        case 101:
            if (!describe || !describe[field.type.refersTo[0]]) {
                return <></>
            }

            return (
                <SelectField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    choices={userlist}
                    optionText="username"
                    optionValue="userid"
                    fullWidth
                />
            );
        /* case 15: // SelectWithRole,
        case 16: // Select,
        case 1613: // SelectModules,
        case 1024: // SelectRoles,
        case 33: // SelectMultiple, */
        case 3313: // SelectModulesMultiple,
            return (
                <SelectField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    choices={field.type.picklistValues}
                    optionText="value"
                    optionValue="label"
                    fullWidth
                />
            );
        
        //case 11: // Phone
        //case 14: // Time
        
        default:
            console.warn('FieldDisplay: Unknown field type', field.uitype);
            return (
                <TextField
                    key={field.name}
                    label={field.label}
                    source={field.name}
                    record={record}
                />
            );
    }
};

export default FieldDisplay;