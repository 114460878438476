import { GetListParams } from "react-admin";
import { Dm_DynamicTemplate_Layouts_DefinitionEntityDto, Dm_Filter_Application_Contracts_Filters_TelerikFilterDto, LayoutTemplateService } from "../packages/routing/proxy/src";
import { TableSchema } from "./TableSchema";

class TableDefinition {
    _tables = [] as TableSchema[];

    async getFilter(params: GetListParams, tableName: string) {

        // console.log('Get Filter for', tableName);
        let defintion = await this.getDefintion(tableName);

        let baseFilters: Dm_Filter_Application_Contracts_Filters_TelerikFilterDto = {};
        baseFilters.logic = "and";
        let filterList: Dm_Filter_Application_Contracts_Filters_TelerikFilterDto[] = [];

        const propertyArray = Object.keys(params.filter);
        propertyArray.map(propertyName => {
            let filter: Dm_Filter_Application_Contracts_Filters_TelerikFilterDto = {};
            let found = defintion.entities?.find((item: Dm_DynamicTemplate_Layouts_DefinitionEntityDto) => {
                return item.technicalName === propertyName;
            });

            filter.value = params.filter[propertyName];
            filter.baseObject = found?.baseObject
            filter.field = found?.technicalName;
            filter.propertyName = found?.propertyName;
            if (found?.baseTypeString === 'System.DateTime') {
                filter.operator = 'eq';
            } else {
                filter.operator = 'contains';
            }

            filter.logic = '';
            filterList.push(filter);
        });

        if (filterList.length > 0) {
            baseFilters.filters = filterList;
        }
        return (baseFilters.filters && baseFilters.filters?.length > 0) ? baseFilters : null;
    }

    async getSorting(params: GetListParams, tableName: string) {
        let defintion = await this.getDefintion(tableName);
        let sortItem = params.sort;
        let sort = defintion.entities?.find((item: Dm_DynamicTemplate_Layouts_DefinitionEntityDto) => {
            return item.technicalName === sortItem.field;
        });
        return sort !== undefined ? (sort?.baseObject + '.' + sort?.propertyName + ' ' + sortItem.order): undefined;
    };


    async getDefintion(name: string) {
        // console.log('Get Layout for', name);
        if(name === undefined || name === ''){
            console.warn('Table name is undefined');
            return {};
        }
        var found = this._tables.find(item => item.tableName === name);
        if (found) {
            return found.definition;
        }
        else {
            let table = await LayoutTemplateService.getApiDynamicTemplateTableByName(name).then((a) => {
                return a;
            });

            this._tables.push({ definition: table, tableName: name });
            return table;
        }
    }
}
export { TableDefinition };
