import { Box, Grid, Typography } from '@material-ui/core';
import { useNotify, useTranslate } from 'react-admin';
import { useOrderStyles } from '../OrderStyle';
import orderCancelAsideImg from '../../assets/orderCancelAsideImg.png';
import { AppButton } from '../../components/AppButton';
import clsx from 'clsx';
import { ShopDeliveryService } from '../../packages/proxy/src';

const OrderCancelBox = ({ message, setShowCancelConfirm, Returntable, record }: { message: string, setShowCancelConfirm: any, Returntable: any, record: any }) => {
    const orderClasses = useOrderStyles();
    const notify = useNotify();
    const translate = useTranslate();

    
    const cancelOrder = () => {

        ShopDeliveryService.deleteApiShopDeliveries(record?.id)
            .then(() => {
                setShowCancelConfirm()
            }, () => {
                notify(translate('translations.something_went_wrong'));
            }).catch((error: any) => { notify(error, 'error') });
    }

    return (
        <Box p={2} className={orderClasses.cancelBox}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box py={2}>
                        <img
                            className={orderClasses.asideImg}
                            src={orderCancelAsideImg}
                            alt="Aside Information"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box py={5}>
                        <Box mx={1}>
                            <Typography component="div" variant="h6">
                                <Box> {message} </Box>
                            </Typography>
                        </Box>
                        <Box mt={2} py={2} display="flex" width="100%">
                            <Box mx={1}>
                                <AppButton onClick={() => cancelOrder()} className={'success'} fullWidth variant="contained" size="large" style={{ padding: '5px 50px' }}>
                                    {translate('AbpUi::Yes')}
                                </AppButton>
                            </Box>
                            <Box mx={1}>
                                <AppButton onClick={Returntable} className={clsx(['text-danger', 'border-danger'])} fullWidth variant="outlined" size="large" style={{ padding: '5px 50px' }}>
                                    No
                                </AppButton>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default OrderCancelBox;