// import { TranslationMessages } from 'ra-core';
import { AbpApplicationLocalizationService } from '../packages/proxy/src';

// type GetMessages = (
//     locale: string
// ) => TranslationMessages | Promise<TranslationMessages>;

/**
 * Build a polyglot-based i18nProvider based on a function returning the messages for a locale
 *
 * @example
 *
 * import { Admin, Resource, polyglotI18nProvider } from 'react-admin';
 * import englishMessages from 'ra-language-english';
 * import frenchMessages from 'ra-language-french';
 *
 * const messages = {
 *     fr: frenchMessages,
 *     en: englishMessages,
 * };
 * const i18nProvider = polyglotI18nProvider(locale => messages[locale])
 */

class AbpTranslationServiceProvider {

    raLanguageItalian = require('ra-language-italian');
    raLanguageEnglish = require('ra-language-english');
    ra : any = {};

    private _locale: string = 'it';
    private _localizaztion :  Record<string, Record<string, any> | null> | null = null;
    constructor(resource:any){
        // console.log('Transle provider');
        this._locale = localStorage.getItem('localization') ?? 'en';
        if(this._locale === 'it'){
            this.ra = this.raLanguageItalian;
            
        }else{
            this.ra = this.raLanguageEnglish.default;
        }
        this._localizaztion = resource;
    }

    getRaTrasnlation(key: string, data:any): string | undefined{
        let idx = key.indexOf('.');
        if(idx > 0){
            let split = key.substring(idx+1);
            let basetype = key.substring(0,idx);
            type ObjectKey = keyof typeof data;
            const myVar = basetype as ObjectKey;
            return this.getRaTrasnlation(split, data[myVar]);
        }
        else{
            // type ObjectKey = keyof typeof data;
            // const myVar = key as ObjectKey;
            if(data === undefined){
                console.warn('RA Admin translation missing', key);
            }
            return data !== undefined ? data[key] : key;
        }
    }

    translate(key : string, options: any ) : any {
        // console.log('Translate key -> options', key, options);
        let transleted = key;
        if(key.startsWith('ra.')){
            // type ObjectKey = keyof typeof this.ra;
            // const myVar = key as ObjectKey;
            transleted = this.getRaTrasnlation(key, this.ra) ?? key;
        }else{
            let splitted = key.split('::');

            let res = this._localizaztion;
            let resItems = res != null ? res[splitted[0]] : undefined;
            if(resItems != undefined){
                let arr = Object.entries(resItems.texts);
                let f = arr.find(item => {
                    return item[0] == splitted[1];
                })
                // console.log('found', f);
                if(f !== undefined){
                    transleted = f[1] as string;
                }else{
                    console.warn('Missing translation key', key);
                }
            }
            // console.log('data avaliable', this._localizaztion);
        }

        return transleted;
    }
    async changeLocale(newLocale: string) : Promise<any> {
        console.log('Change Locale to ', newLocale);
        this._locale = newLocale;
        localStorage.setItem('localization', newLocale);
        this._localizaztion = await this.fetchData();
    }

    getLocale() : string {
        // console.log('Get Locale');
        return this._locale;
    }
    async fetchData(): Promise<any> {
        return await AbpApplicationLocalizationService.getApiAbpApplicationLocalization(this._locale)
        .then((result)=>{
            if(result){
                // if(this._localizaztion){
                //     this._localizaztion.resources = result.resources;
                // }
                let loc = result.resources || null;
                // this._localizaztion = loc;
                return loc;
            }else{
                return undefined;
            }
        });
    }
}

export {AbpTranslationServiceProvider};