import { configService } from "../abp/configService";

export const abpSessionManager = () => {
  
  let _access_token = '';
  let _expiretime = 0;
  let _refresh_token='';
  let _clientId = '';
  let _clientSecret = '';
  let _tokenEndpoint =  '/connect/token';
  
  let refreshTimeOutId : any;
  let isRefreshing : Promise<boolean>;

  const isValidSession = () : any =>{
    try{
      
      let token = localStorage.getItem("token");
      // console.log('IsValidSession', token);
      if(token){
        return true;
      }
    }catch(e){
      return false;
    }
  }

  const getClient = () : any => {
      return _clientId;
  }
  const getSecret = () => {
      return _clientSecret;
  }
  

  const login = async (usr : string, pass : string, tenant: string) =>  {
      try {
        const config = configService.get();

        const formData = new URLSearchParams();
          formData.append('username', usr.toString());
          formData.append('password', pass.toString());
          formData.append('grant_type', 'password');
          formData.append('client_id', config.clientId);
          formData.append('client_secret',config.clientSecret);
          formData.append('scope', config.scope);
          let myself = this;
    
        const response = await fetch(config.authUrl + _tokenEndpoint,{
            method:"POST",
            body:  formData.toString(),
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                '__tenant': tenant
              },
        });
        
        if (!response.ok){
          return Promise.reject('Error login ');
        }
  
        // console.log('Login response', response);
        const data = await response.json();
        _expiretime = data.expires_in;
        _access_token = data.access_token;
        _refresh_token = data.refresh_token;
        const expire_date = new Date().setSeconds(_expiretime - 60);
        localStorage.setItem("token", _access_token);
        localStorage.setItem("refreshToken", _refresh_token);
        localStorage.setItem("expire", expire_date.toString());
        
  
        return Promise.resolve();
        
      } catch (error) {
        console.error('Error fetching token:', error);
        return Promise.reject(error);
      }
  }
  
  const getConfiguration = () : any => {
      '/api/abp/application-configuration'
  }

const abordRefreshToken = () => {
  if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
  }
};
  return {
    login,
    getClient,
    getSecret,
    getConfiguration,
    isValidSession
}
}

export default abpSessionManager();