import * as React from "react";
import {usePaymentStyles} from "./PaymentStyle";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useRedirect, useTranslate} from "react-admin";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

export const PaymentMessage = ({
                                   message,
                                   redirectUrl,
                               }: {
    message: string;
    redirectUrl: string;
}) => {

    const paymentClasses = usePaymentStyles();
    const redirect = useRedirect();
    const translate = useTranslate();

    return (
        <Card className={paymentClasses.appCard} variant="outlined">
            <CardContent>
                <Box justifyContent="center" className={paymentClasses.messageBox}>
                    <Typography align="center" component="div">
                        <Box>
                            <CheckCircleIcon className={paymentClasses.paymentMessageIcon} style={{
                                width: '60px',
                                height: '60px'
                            }}/>
                        </Box>
                    </Typography>
                    <Box my="1.5rem">
                        <Typography align="center" component="div" variant="h5">
                            <Box>{message}</Box>
                        </Typography>
                    </Box>
                    <Box style={{width: "60%", margin: "auto"}}>
                        <Button
                            color="primary"
                            fullWidth
                            size="large"
                            className={paymentClasses.toolbarBtn}
                            variant="contained"
                            onClick={() => redirect(`${redirectUrl ?? "/"}`)}
                        >
                            {translate('translations.show_details')}
                        </Button>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};
