import * as React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import logo from '../assets/logoColoredYellowTick.png';

export const Logo = () => {
    const isDesktop:boolean = useMediaQuery((theme:any) => theme.breakpoints.up('md'));
    const useStyles = makeStyles({
        logo: {
            display: 'block',
            width: isDesktop ? '12%' : '30%',
            margin: '10px auto'
        },
    });
    const classes = useStyles();

    return (
        <Box alignItems="center">
            <img
                className={classes.logo}
                src={logo}
                alt="WORA Logo"
            />
        </Box>
    )
}