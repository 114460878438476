import { Log, UserManager, WebStorageStateStore } from "oidc-client-ts";
import { configService } from '../abp/configService';

export let mgr : UserManager = {} as UserManager;

export async function initializeUserManager() {
  try {
    await configService.load(); // Carica la configurazione prima di inizializzare il UserManager
    
    const client_id = configService.get().clientId;
    const authority = configService.get().authUrl!;
    const redirect_uri = configService.get().redirect_uri;
    const post_logout_redirect_uri = configService.get().post_logout_redirect_uri;
    const scope = configService.get().scope;
    const response_type = "code";
    const userStore = new WebStorageStateStore({ store: window.localStorage });

    Log.setLogger(console);

    mgr = new UserManager({
      authority,
      client_id,
      scope,
      redirect_uri,
      response_type,
      userStore,
      post_logout_redirect_uri
    });

    setupUserManagerEvents();
    
  } catch (error) {
    console.error("Failed to initialize UserManager:", error);
  }
}

function setupUserManagerEvents() {
  mgr.events.addAccessTokenExpiring(function () {
    console.log("token expiring");
    mgr.signinSilent().then((user) => {
      console.log("silent renew success", user);
    }).catch((e) => {
      console.log("silent renew error", e.message);
    });
  });

  mgr.events.addAccessTokenExpired(() => {
    console.log("token expired");
    localStorage.removeItem("token");
  });

  mgr.events.addSilentRenewError((e) => {
    console.log("silent renew error", e.message);
  });

  mgr.events.addUserLoaded((user) => {
    console.log("user loaded", user);
    mgr.getUser().then((user) =>
      console.log(`getUser loaded user ${JSON.stringify(user || {})} after userLoaded event fired`)
    );
  });

  mgr.events.addUserUnloaded(() => {
    console.log("user unloaded");
  });

  mgr.events.addUserSignedIn(() => {
    console.log("user logged in to the token server");
  });

  mgr.events.addUserSignedOut(() => {
    console.log("user logged out of the token server");
  });
}