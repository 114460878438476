import PropTypes from 'prop-types';
import { dateTimeParser } from '../utils/Helpers';
const dateInputs = [
    'requestedFrom', 'requestedEndFrom', 'requestedTo', 'requestedEndTo', 'taskEtaTo', 'taskEtaFrom'
]
const RawField = ({ source, record = {} }: { source: string, record: any }) => {
    // console.log('rawfield ->', source, record);
    if (record && record.task_type === 'Delivery' && source === 'enddate') return <span> {dateTimeParser(record['time_until'])} </span>;
    if (record && dateInputs.includes(source)) return <span> {dateTimeParser(record[source])} </span>
    return <span>{record ? dateTimeParser(record[source]) : <></>}</span>;
};

RawField.defaultProps = {
    addLabel: true,
    fullWidth: true,
    showTime: false,
};

RawField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default RawField;