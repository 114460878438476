
export enum FieldDisplayUiType {
    BaseText = 0,
    Text = 1,
    Tipo4 = 4,
    Date = 5,
    Numeric = 7,
    Percentage = 9,
    ModuleRelation = 10,
    PhoneInput = 11,
    Email = 13,
    SelectInput = 15,
    Url = 17,
    BigText = 19,
    TextArea = 21,
    DateTime = 50,
    UserRelationCreated = 52,
    UserRelationAssigner = 53,
    CheckBox = 56,
    Image = 69,
    CreatedModifiedTime = 70,
    Currency = 71,
    Skype = 85,
    SelectUser = 101,
    SelectRolesInput = 1024,
    SelectMultiple = 3313
}
