import * as React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useOrderStyles } from '../OrderStyle';
import { AppButton } from '../../components/AppButton';
import {useRedirect, useTranslate} from 'react-admin';
import CheckedImg from '../../assets/checked.png';

const OrderCancelNotice = ({message, returnBack} : {message: string, returnBack:any}) => {
    const orderClasses = useOrderStyles();
    const redirect = useRedirect();
    const translate = useTranslate();
    return (
        <Box p={2} className = {orderClasses.cancelBox}>
            <Grid container spacing={2} className={orderClasses.cancelNotice}>
                <Grid item xs={12} style = {{width: "100%"}}>
                    <Box py={5}>
                        <Box mx={1}>
                            <Typography component="div" variant="h6">
                                <Box textAlign = "center"> { message } </Box>
                            </Typography>
                        </Box>
                        <Grid item xs={12} md={6} style = {{margin: "auto"}}>
                            <Box py={2} display = 'flex' justifyContent = 'center'>
                                    <img
                                        className={orderClasses.checkedImg}
                                        src={CheckedImg}
                                        alt="Aside Information"
                                    />
                            </Box>
                        </Grid>
                        <Box mt={2} className = {orderClasses.cancelNoticeButtons} justifyContent = 'space-evenly' py={2} sizeWidth = "100%"  display="flex" mx = {'auto'}>
                            <Box my = {1} mx = {1}>
                                <AppButton onClick={() => (returnBack())}  fullWidth variant="contained" size="large" style={{padding: '12px 60px', boxShadow: "none", backgroundColor: "#FFC300"}}>
                                    {translate('CorbosService::AllOrders')}
                                </AppButton>
                            </Box>
                            <Box my = {1} mx = {1} >
                                <AppButton fullWidth variant="contained" color="primary" size="large" onClick={() => redirect('/')} style={{padding: '12px 60px', boxShadow: "none"}}>
                                    {translate('CorbosService::NewDelivery')}
                                </AppButton>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            
        </Box>
    )
}

export default OrderCancelNotice;