import React, {useRef} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {excludedTimes} from "../constant";
import {isBefore, setHours, setMinutes, setDate} from "date-fns";
import {EventOutlined} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {useOrderStyles} from "../order/OrderStyle";


const DatePickerInput = ({fieldName, fieldLabel, selectedDate, setSelectedDate, minDateRestriction, maxDateRestriction, minTimeRestriction, maxTimeRestriction, leftPosition, initialDate} : {fieldName :string , fieldLabel :string, selectedDate: any, setSelectedDate :any, minDateRestriction: any, maxDateRestriction: any, minTimeRestriction: any, maxTimeRestriction:any, leftPosition? :any, initialDate? :any }) => {
    // console.log('DatePickerInput');
    const orderClasses = useOrderStyles();
    let inputRef = useRef<any>(null);
    const getTheExcludedTimes = ()=>{
        let excludedTimesArray:any = [];
        excludedTimes.forEach((hour:number)=>{
            excludedTimesArray.push(
                setHours(setMinutes(new Date(), 0), hour),
                setHours(setMinutes(new Date(), 15), hour),
                setHours(setMinutes(new Date(), 30), hour),
                setHours(setMinutes(new Date(), 45), hour),
            );
        })
        return excludedTimesArray;
    }
    const setNewDateDate = (date :Date)=>{
            if(isBefore(date, minDateRestriction)){
                date = setDate(date, minDateRestriction.getDate());
            }
            setSelectedDate(date);
    }
    return (
        <div style={{position: 'relative'}}>
        <DatePicker
            ref = {inputRef}
            name={fieldName}
            selected={selectedDate}
            onChange={(date :Date) => {setNewDateDate(date)}}
            showTimeSelect
            timeIntervals={15}
            excludeTimes={getTheExcludedTimes()}
            minDate = {minDateRestriction}
            maxDate = {maxDateRestriction}
            minTime={minTimeRestriction}
            placeholderText={fieldLabel}
            maxTime={maxTimeRestriction}
            injectTimes={[
                initialDate,
              ]}
            dateFormat="MMMM d, yyyy HH:mm"
            timeFormat="HH:mm"
            className={'react-datePicker'}
        />
        <IconButton onClick={(e)=>{inputRef?.current?.setFocus(true)}}  className={orderClasses.eventOutlineIcon} style={{left: leftPosition}}>
        <EventOutlined  />
        </IconButton>
        </div>
    );
};

export default DatePickerInput;