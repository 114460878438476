import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    ShowBase,
    ShowProps,
    useShowContext,
    Loading, useTranslate, usePermissions, Button
} from 'react-admin';
import { Box, Grid } from '@material-ui/core';
import { useStyles } from '../style/Index';
import { useOrderStyles } from './OrderStyle';
import { useRedirect } from 'react-admin';
import { OrderShowDto } from '../types/OrderShowDto';
import { AppButton } from '../components/AppButton';
import { getDataFromLocalDb, permissionCheck, abpPermissionCheck } from '../utils/Helpers';
import { TABLE_DESCRIBE } from '../utils/local-db';
import OrderCancelBox from './components/OrderCancelBox';
import OrderCancelNotice from './components/OrderCancelNotice';
import { doQuery } from "../utils/lib";
import RelatedTaskShow from "./components/RelatedTaskShow";
import ActivityHistory from "./components/ActivityHistory";
import { Dm_DynamicTemplate_Layouts_LayoutUiDto, LayoutTemplateService } from '../packages/routing/proxy/src';
import { FieldInputTypeDto } from '../types/FieldInputTypeDto';
import { DELIVERY, PICKUP } from '../constant';
import { TaskDataDto } from '../models/Orders/TaskDataDto';
import { useHistory } from 'react-router-dom';
import { wora_CorbosService_DeliverySteps_ShopDeliveryStepDto, wora_CorbosService_DeliverySteps_StepType } from '../packages/proxy/src';
import { FieldDisplayUiType } from '../types/FieldDisplayUiType';
export const OrderShow = (props: ShowProps) => (
    <ShowBase {...props}>
        <OrderShowContent {...props} />
    </ShowBase>
);

const OrderShowContent = (props: ShowProps) => {
    // console.log('Order show', props);
    const classes = useStyles();
    const orderClasses = useOrderStyles();
    const redirect = useRedirect();
    const translate = useTranslate();
    const history: any = useHistory();
    const { permissions } = usePermissions();

    const { record } = useShowContext<OrderShowDto>();

    const [describe, setDescribe] = useState<any>(null);
    const [assignedUserList, setAssignedUserList] = useState<any[]>([]);
    const [fields, setFields] = useState<any[]>([]);
    const resource = props?.resource ?? '';
    const [showCancelConfirmBox, setShowCancelConfirmBox] = useState<boolean>(false);
    const [showCancelSuccess, setShowCancelSuccess] = useState<boolean>(false);
    const [relatedTask, setRelatedTask] = useState<any>();
    const [idOrderToBeCanceled, setIdOrderToBeCanceled] = useState<string>('');



    const getField = (fieldName: string) => {
        // console.log('getField');
        const field = fields.find((field: any) => field.name === fieldName);
        return field;
    }


    const cancelOrder = () => {
        const _orderPickUpDeliveryRelationship = record?.pickup_delivery_relationship;
        setIdOrderToBeCanceled(_orderPickUpDeliveryRelationship);
        setShowCancelConfirmBox(true);
    }
    useEffect(() => {
        const bb = LayoutTemplateService.getApiDynamicTemplateTableByName('wora.CorbosService.Deliveries.DeliveryDto')
            // const aaa = DeliveryService.postApiCorbosServiceDeliveries({ skipCount: 0, maxResultCount: 10 })
            .then((result: Dm_DynamicTemplate_Layouts_LayoutUiDto) => {
                setDescribe(result);
                let modFields: FieldInputTypeDto[] = [
                    { name: "pickupContact", label: translate("CorbosService::Store"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
                    { name: "pickupRepresentative", label: translate("CorbosService::Representative"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
                    { name: "stepCode", label: translate("CorbosService::StepCode"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
                    { name: "pickupRepresentativePhone", label: translate("CorbosService::Phone"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
                    { name: "pickupStatusName", label: translate("CorbosService::Status"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
                    { name: "requested", label: translate("CorbosService::TimeFrom"), mandatory: false, editable: true, uitype: FieldDisplayUiType.DateTime, defaultValue: '', placeholder: '', readonly: true },
                    { name: "requestedEnd", label: translate("CorbosService::TimeTo"), mandatory: false, editable: true, uitype: FieldDisplayUiType.DateTime, defaultValue: '', placeholder: '', readonly: true },
                    { name: "taskEtaFrom", label: translate("CorbosService::Eta"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
                    { name: "from", label: translate("CorbosService::Address"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },

                    { name: "deliveryStatusName", label: translate("CorbosService::Status"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
                    { name: "deliveryContact", label: translate("CorbosService::Customer"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
                    { name: "deliveryContactPhone", label: translate("CorbosService::Phone"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
                    { name: "deliveryNote", label: translate("CorbosService::Note"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
                    { name: "deliveryInterPhone", label: translate("CorbosService::InterPhone"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
                    { name: "deliveryReception", label: translate("CorbosService::Reception"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
                    { name: "requestedTo", label: translate("CorbosService::TimeFrom"), mandatory: false, editable: true, uitype: FieldDisplayUiType.DateTime, defaultValue: '', placeholder: '', readonly: true },
                    { name: "requestedEndTo", label: translate("CorbosService::TimeTo"), mandatory: false, editable: true, uitype: FieldDisplayUiType.DateTime, defaultValue: '', placeholder: '', readonly: true },
                    { name: "taskEtaTo", label: "ETA", mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
                    { name: "to", label: translate("CorbosService::Address"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },

                    { name: "contactName", label: translate("CorbosService::Customer"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
                    { name: "startTime", label: "startTime", mandatory: false, editable: true, uitype: FieldDisplayUiType.DateTime, defaultValue: '', placeholder: '', readonly: true },
                    { name: "endTime", label: "endTime", mandatory: false, editable: true, uitype: FieldDisplayUiType.DateTime, defaultValue: '', placeholder: '', readonly: true },
                    { name: "deliveryTypeName", label: translate("CorbosService::DeliveryType"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
                    // { name: "related_order", label: "related_order", mandatory: false, editable: true, uitype: FieldDisplayUiType.SmallText, defaultValue:'', placeholder:'', readonly:true },
                    { name: "retailerid", label: "retailerid", mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
                    { name: "orderType", label: "Tipo Ordine", mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },

                    { name: "totalPackages", label: translate("CorbosService::Total_packages"), mandatory: false, editable: true, uitype: FieldDisplayUiType.Numeric, defaultValue: '', placeholder: '', readonly: true },
                    { name: "totSmallPackages", label:  translate("CorbosService::TotSmallPackages"), mandatory: false, editable: true, uitype: FieldDisplayUiType.Numeric, defaultValue: '', placeholder: '', readonly: true },
                    { name: "totMediumPackages", label:  translate("CorbosService::TotMediumPackages"), mandatory: false, editable: true, uitype: FieldDisplayUiType.Numeric, defaultValue: '', placeholder: '', readonly: true },
                    { name: "totLargePackages", label:  translate("CorbosService::TotLargePackages"), mandatory: false, editable: true, uitype: FieldDisplayUiType.Numeric, defaultValue: '', placeholder: '', readonly: true },


                ];
                setFields(modFields);
            });

        // getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result: any) => {

        // //   setDescribe(result);
        // //   let modFields = result[resource]?.fields ?? [];
        // //   setFields(modFields);
        // //   const aUserList = result[resource]?.userlist ?? [];
        // //   setAssignedUserList(aUserList);
        // });
    }, [resource])

    useEffect(() => {
        // console.log('record in show', record);
        if (typeof record !== "undefined") {
            const pickup = relatedTask?.steps[0];
            setRelatedTask(record);
        }

    }, [record]) // eslint-disable-line
    if ( !record || !relatedTask) return <Loading />;


    const setShowCancelConfirm = () => {
        setShowCancelConfirmBox(false);
        setShowCancelSuccess(true);
    }


    const Returntable = () => {
        setShowCancelConfirmBox(false);
        setShowCancelSuccess(false);
    }
    const redirectToPaymentHistory = () => {
        redirect('/delivery');
    }

    const prepareTask = (record: OrderShowDto, taskType: string): TaskDataDto => {
        // console.log('Prepare data', record);
        let a: TaskDataDto = {};
        if (taskType === DELIVERY) {

        } else if (taskType === PICKUP) {

        }
        return a;
    }

    return (
        <>
            {showCancelConfirmBox ?
                (
                    <OrderCancelBox record={record} Returntable={() => Returntable()}
                        setShowCancelConfirm={() => setShowCancelConfirm()} message={`${'Sei sicuro di voler cancellare l’ordine ID '}${idOrderToBeCanceled} ?`} />
                ) : showCancelSuccess ?
                    (<OrderCancelNotice returnBack={redirectToPaymentHistory}
                        message={`${'Ti confermiamo che l’ordine ID '}${idOrderToBeCanceled}  è stato cancellato!`} />
                    )
                    : (
                        <div className={classes.componentContainer}>
                            <Box paddingRight={3} paddingLeft={3} display="flex" justifyContent={'right'} >
                                <Grid container spacing={0}>
                                    <Grid item xs={1} md={3}></Grid>
                                    <Grid item xs={8} md={6} >
                                        <Box display="flex" justifyContent={'center'}>
                                            {translate('CorbosService::OrderCode')} {record?.orderCode} - {translate('CorbosService::ExternalOrderCode')} {record?.externalOrderCode} - { record ? translate('CorbosService::'+record?.orderTypeName) : ''}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3} md={3} alignContent='flex-end'>
                                        <Box display="flex" justifyContent={'right'}>
                                            <Button label={translate('AbpUi::Back')}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => history.goBack()}></Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box p={3}>


                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={6}>
                                        {
                                            // Pickup
                                            (
                                            <RelatedTaskShow  {...props} getField={getField} aUserLIst={assignedUserList}
                                                related={true} describeTable={describe} id={relatedTask?.id} record={{delivery: relatedTask, task: relatedTask.steps.find((step : wora_CorbosService_DeliverySteps_ShopDeliveryStepDto) => step.stepType === wora_CorbosService_DeliverySteps_StepType._0)}}
                                                orderType={record.type_order} taskId={relatedTask.deliveryId} taskType={PICKUP} />)
                                        }
                                        {
                                            // Delivery
                                            (<RelatedTaskShow  {...props} getField={getField} aUserLIst={assignedUserList}
                                                related={true} describeTable={describe} id={relatedTask?.id} record={{delivery: relatedTask, task: relatedTask.steps.find((step : wora_CorbosService_DeliverySteps_ShopDeliveryStepDto) => step.stepType === wora_CorbosService_DeliverySteps_StepType._1)}}
                                                delivery={true} orderType={record?.type_order} taskId={relatedTask.pickupId} taskType={DELIVERY}
                                            />)
                                        }

                                    </Grid>
                                    <ActivityHistory currentTaskId={props.id} relatedTask={relatedTask} />
                                    {
                                        (record?.status !== undefined && record?.status == 1
                                            && abpPermissionCheck(permissions, 'CorbosService.Deliveries.Delete'))
                                        && (<Grid item xs={12} md={12} className={orderClasses.orderDetailsBox}>
                                            <Box py={2} className={orderClasses.caneclBtnBox}>
                                                <AppButton style={{ boxShadow: "none" }} onClick={() => cancelOrder()} fullWidth variant="contained" color="secondary" size="large">
                                                    {translate('AbpUi::Delete')}
                                                </AppButton>
                                            </Box>
                                        </Grid>)}
                                </Grid>
                                {/* <pre>{JSON.stringify(relatedTask, null, 2)}</pre> */}
                            </Box>
                        </div>
                    )
            }
        </>
    );
};



