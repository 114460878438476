import * as React from "react";
import {DeleteButton, EditButton, ShowButton, usePermissions} from 'react-admin';
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {DeleteRounded, EditRounded} from '@material-ui/icons'
import {abpPermissionCheck, permissionCheck} from "../utils/Helpers";
const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiButton-root': {
            minWidth: '10px',
        },
        '& .MuiIconButton-root': {
            padding: 0,
            minWidth: '10px'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1em',
        },
    },
}));
 const AzioneField = (props:any) => {
    //  console.log('Azione filed', props);
     const classes = useStyles();
     const { permissions } = usePermissions();
     const deleteButton = (stato:string, module:string) => {
        if (module === 'delivery'){
            // console.log('delete button permission');
            let stustNumber = Number(stato);
            if(stustNumber > 1){
                return;
            } 
            else if(abpPermissionCheck(permissions, 'CorbosService.Deliveries.Delete')  ){
                return (<IconButton  resource={props?.resource}  onClick={()=>props?.cancelOrder(props?.record)}> <DeleteRounded /></IconButton>)
            }
            else{
                return;
            }

            
        }else{
            return (<DeleteButton label={''} resource={props?.resource} basePath={props?.basePath} record={props?.record} />)
        }
     }

     const editButton = (stato:string, module:string) => {
        // console.log('edit button permission');
        return;
        if (module === 'delivery'){
            let stustNumber = Number(stato);
            if(stustNumber > 1){
                return;
            }
            // if (stato === 'Completed' || stato === 'Annullato' || stato === 'Iniziato' || stato === 'In progresso'){
            //     return
            // }
            else if(abpPermissionCheck(permissions, 'CorbosService.Deliveries.Create') || abpPermissionCheck(permissions, 'CorbosService.Deliveries.Edit')  ){
                return (<EditButton   label={''} resource={props?.resource} basePath={props?.basePath} record={props?.record} />)    
            }
            else{
                return;
            }
            
        }else{
            return (<EditButton   label={''} resource={props?.resource} basePath={props?.basePath} record={props?.record} />)
            
        }
     }
     return (
        <Box display="flex" justifyContent={'center'} className={classes.root}>
            {
                abpPermissionCheck(permissions, 'CorbosService.Deliveries.Delete') && (deleteButton(props?.record.status, props?.resource))
            }
            {/*{props.record.stato !== 'Completato'}*/}
            {/*    {(props?.resource === 'delivery')*/}
            {/*        ? (<IconButton  resource={props?.resource}  onClick={()=>props?.cancelOrder(props?.record.id)}> <DeleteRounded /></IconButton>)*/}
            {/*        :(<DeleteButton label={''} resource={props?.resource} basePath={props?.basePath} record={props?.record} />)*/}
            {/*    }*/}
            {(props?.resource !== 'ContactRole' && abpPermissionCheck(permissions, 'CorbosService.Deliveries') ) && (<ShowButton undoable = {true}  label={''} resource={props?.resource} basePath={props?.basePath} record={props?.record} />)}
            {(props?.resource === 'ContactRole' && abpPermissionCheck(permissions, 'CorbosService.Deliveries')) && (
                <IconButton color={'primary'} size={"small"} onClick={() => props.toggleExpand(props?.record.id)}>
                    <EditRounded/>
                </IconButton>)}
            { abpPermissionCheck(permissions, 'CorbosService.Deliveries.Edit') && (editButton(props?.record.status, props?.resource)) }
        </Box>
    );
}

AzioneField.defaultProps = {
        label: 'Azione',
      addLabel: true,
};
export default AzioneField;