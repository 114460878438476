import React from 'react';
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useStyles} from "./AuthStyle";
import TextField from '@material-ui/core/TextField';
import {useState} from "react";
import "../index.css"
import checked from '../assets/checked.png'
import {useRedirect, useGetIdentity, useNotify, useTranslate} from "react-admin";
import { AbpUserService } from '../abp/services/AbpUserService';

const ChangePassword = () => {
    const [oldPass, setOldPass] = useState<string>('');
    const [firstPass, setFirstPass] = useState<string>('');
    const [secondPass, setSecondPass] = useState<string>('');
    const [wrongInput, setError] = useState({
        triggered: false,
        message: ""
    });
    const [oldPasswordError, setOldPasswordError] = useState({
        triggered: false,
        message: ""
    });
    const {identity} = useGetIdentity();
    const [success, setSuccess] = useState<boolean>(false);
    const classes = useStyles();
    const redirect = useRedirect();
    const notify = useNotify();
    const translate = useTranslate();
    const isOldPasswordError = (errorMessage:string) =>{
        const invalidOldPasswordCode :string = 'INVALID_OLD_PASSWORD';
        return errorMessage.includes(invalidOldPasswordCode);
    }


    const formSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setOldPasswordError(
            { triggered: false,
            message: "",
        });
        if (firstPass === secondPass) { // * password match validation
            if (firstPass.length >= 8) { //* password length validation
                if (firstPass.match(/\d/)) { // *number validation
                    if (firstPass.match(/[A-Z]/)) { // * capital letters validation
                        //it is okay
                        setError({
                            triggered: false,
                            message: "",
                        });
                      
                        let passwordData = {
                            currentPassword:oldPass,
                            newPassword:firstPass
                        }
                        // alert("Password changed succesfully")
                        AbpUserService.updatePassword(passwordData).then(results => {
                            setFirstPass('');
                            setSecondPass('');
                            setSuccess(true);
                        }, (reason:any)=>{
                            if(isOldPasswordError(reason?.message)){
                                setOldPasswordError({triggered: true, message : translate('AbpIdentity::translations.incorrect_old_password')})
                            }
                        }).catch(err => {
                            notify('AbpExceptionHandling::InternalServerErrorMessage', 'error');
                        })

                    } else {
                        setError({
                            triggered: true,
                            message: translate('AbpIdentity::DisplayName:Abp.Identity.Password.RequireUppercase'),
                        });
                    }
                } else {
                    setError({
                        triggered: true,
                        message: translate('AbpIdentity::DisplayName:Abp.Identity.Password.RequiredUniqueChars'),
                    });
                }
            } else {
                setError({
                    triggered: true,
                    message: translate('AbpIdentity::DisplayName:Abp.Identity.Password.RequiredLength'),
                });
            }
        } else {
            setError({
                triggered: true,
                message: translate('AbpIdentity::translations.pasword_match_validation'),
            });
        }

    }
    if (success) {
        document.body.classList.add('active-modal')
        setTimeout(() => {
            setSuccess(false);
            redirect('/');
        }, 5000);
    } else {
        document.body.classList.remove('active-modal')
    }
    return (
        <Box display={'flex'} flexDirection={'column'} p={6}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} style={{marginBottom: "50px"}}>
                <Typography className={classes.chPassword}>{translate('AbpUi::ChangePassword')}</Typography>
            </Box>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'}>
                <Box>
                    <form>
                        <Box my={1}>
                            <label className={classes.formLabels}>{translate('AbpIdentity::DisplayName:CurrentPassword')}</label>
                            <TextField
                               error = {oldPasswordError.triggered}
                               helperText = {oldPasswordError.message}
                                autoComplete={'off'}
                                value={oldPass}
                                type={'password'}
                                // label="Nuova Password"
                                // placeholder="Email"
                                name={'oldpassword'}
                                fullWidth
                                // id="emailInput"
                                variant="outlined"
                                size="small"
                                // type="email"
                                onChange={e => setOldPass(e.target.value)}
                            />
                        </Box>
                        <Box my={1}>
                            <label className={classes.formLabels}>{translate('AbpIdentity::DisplayName:NewPassword')}</label>
                            <TextField
                                error={wrongInput.triggered}
                                helperText={wrongInput.message}
                                autoComplete={'off'}
                                value={firstPass}
                                type={'password'}
                                // label="Nuova Password"
                                // placeholder="Email"
                                name={'password'}
                                fullWidth
                                // id="emailInput"
                                variant="outlined"
                                size="small"
                                // type="email"
                                onChange={e => setFirstPass(e.target.value)}
                            />
                        </Box>
                        <Box my={1}>
                            <label className={classes.formLabels}>{translate('AbpIdentity::DisplayName:NewPasswordConfirm')}</label>
                            <TextField
                                 error={wrongInput.triggered}
                                 helperText={wrongInput.message}
                                autoComplete={'off'}
                                value={secondPass}
                                type={'password'}

                                // label="Nuova Password"
                                // placeholder="Email"
                                name={'password'}
                                fullWidth
                                // id="emailInput"
                                variant="outlined"
                                size="small"
                                onChange={e => setSecondPass(e.target.value)}
                            />
                        </Box>
                        <Box my={2}>
                            <button type={'submit'} onClick={e => formSubmit(e)} className={classes.savePasswordBtn}>
                                {translate('AbpUi::Save')}
                            </button>
                        </Box>
                    </form>
                </Box>
                <Box>
                    {/* <Typography className={classes.formLabels} align={'left'} paragraph={true}>{translate('translations.new_password_needs_to_have')}</Typography> */}
                    <Typography className={classes.passRules} align={'left'}>{translate('AbpIdentity::Volo.Abp.Identity:PasswordRequiresUpper')}</Typography>
                    <Typography className={classes.passRules} align={'left'}>{translate('AbpIdentity::Volo.Abp.Identity:PasswordRequiresDigit')}</Typography>
                    <Typography className={classes.passRules} align={'left'}>{translate('AbpIdentity::Volo.Abp.Identity:PasswordRequiresNonAlphanumeric')}</Typography>
                </Box>
            </Box>
            {success ?
                (<Box className={'modal'}>
                    <div onClick={() => redirect('/')} className="overlay"></div>
                    <Box display={'flex'} flexDirection={'column'} className={'modal-content'} p={5}>
                        <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <img alt={'checked'} width={'10%'} src={checked}/>
                        </Box>
                        <Box my={1} width={'100%'} style={{fontSize: "16px"}} display={'flex'} justifyContent={'center'}
                             alignItems={'center'}>{translate('AbpAccount::PasswordChangedMessage')}</Box>
                    </Box>
                </Box>) : ''}
        </Box>
    );
};

export default ChangePassword;