import * as React from "react";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import NotFoundImg from '../assets/NotFoundImg.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    center: {
        [theme.breakpoints.up('md')]: {
            width: '50%',
            margin: 'auto',
            position: 'absolute',
            top: '50%',
            left: '25%',
            transform: 'translateY(-50%)',
        },
        margin: '25px auto',
    },
}));

const NotFound = (props: any) => {

    const classes = useStyles();
    return (
        <Box alignItems="center" className={classes.center}>
            <Box textAlign="center" style={{width: '80%', margin: '20px auto'}}>
                <Typography component="div">
                    <Box textAlign="center">
                        <Typography variant="body2" gutterBottom>
                            {"Ci scusiamo per l'inconveniente, questa pagina è temporaneamente non disponibile. Ti preghiamo di tornare alla pagina precedente"}
                        </Typography>
                        <Typography color="primary">
                            <span onClick={() => props.history.goBack()} className={'btnPointer'} style={{textDecoration: 'underline'}}> Indietro </span>
                        </Typography>
                    </Box>
                </Typography>
            </Box>
             <Box style={{width: '60%', margin: 'auto'}}>
                <img
                    src={NotFoundImg}
                    alt="Page not found"
                    width={'100%'}
                    height={'100%'}
                />
            </Box>
        </Box>
    );

} 

export default NotFound