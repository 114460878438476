import React, { useState, useEffect, useCallback } from 'react';
import {
    Edit,
    SaveButton,
    SimpleForm,
    Toolbar,
    useNotify,
    useRecordContext,
    useRedirect,
    useTranslate,
    useUpdate
} from 'react-admin';
import { convertFileToBase64, getDataFromLocalDb, getFileExtension } from '../utils/Helpers';
import { TABLE_AUTH, TABLE_DESCRIBE } from '../utils/local-db';
import FieldInput from '../utils/FieldInput';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
//import { useStyles } from '../style/Index';
import { useUserStyles } from './UserStyle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { doCreate, retrieveRecord } from '../utils/lib';
import { FieldInputTypeDto } from '../types/FieldInputTypeDto';
import useAbp from '../useAbp';
import { AbpUserService, IdentityUserUpdateDto } from '../abp/services/AbpUserService';
import { ShopDeliveryService, OpenAPI } from '../packages/proxy/src';
import { FieldDisplayUiType } from '../types/FieldDisplayUiType';


export const UserProfileEdit = (props: any) => {
    // console.log('UserProfile');
    //const classes = useStyles();
    const pageClasses = useUserStyles();
    const abp = useAbp();
    const resource = props.resource;
    const notify = useNotify();
    const translate = useTranslate();
    const [describe, setDescribe] = useState({});
    const [assignedUserList, setAssignedUserList] = useState<any[]>([]);
    const [fields, setFields] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [update] = useUpdate();
    const [record, setRecord] = useState<any>({});
    const redirect = useRedirect();
    const [authData, setAuthData] = useState<any>({});
    const { is_admin } = authData?.user ?? {};
    const isAdmin: boolean = is_admin === 'on' || is_admin === true || is_admin === 'true';



    const requiredFields: any[] = ['surname', 'name', 'phoneNumber', 'email', 'shop_role', 'account_id', 'otherstreet'];
    const setUserData = useCallback(async () => {
        // const authUser = await getDataFromLocalDb(TABLE_AUTH.tableName);
        // setAuthData(authUser);
    }, [])

    useEffect(() => {
        // console.log('UserProfileEdit');
        let modFields: FieldInputTypeDto[] = [
            { name: "surname", label: translate('AbpIdentity::Surname'), mandatory: false, editable: true, uitype: FieldDisplayUiType.Text, defaultValue: '', placeholder: '', readonly: true },
            { name: "name", label: translate('AbpIdentity::Name'), mandatory: false, editable: true, uitype: FieldDisplayUiType.Text, defaultValue: '', placeholder: '', readonly: true },
            { name: "phoneNumber", label: translate('AbpIdentity::PhoneNumber'), mandatory: false, editable: true, uitype: FieldDisplayUiType.PhoneInput, defaultValue: '', placeholder: '', readonly: true },
            { name: "email", label: translate('AbpIdentity::EmailAddress'), mandatory: false, editable: false, uitype: FieldDisplayUiType.Email, defaultValue: '', placeholder: '', readonly: true },
            // { name: "pickupStatusName", label: "Stato", mandatory: false, editable: true, uitype: FieldDisplayUiType.SmallText, defaultValue: '', placeholder: '', readonly: true },
            // { name: "requestedFrom", label: "Dalle", mandatory: false, editable: true, uitype: FieldDisplayUiType.DateTime, defaultValue: '', placeholder: '', readonly: true },
        ];
        setFields(modFields);

        AbpUserService.getMyProfile().then((result : any) => {
            // console.log('Current user', result);
            setRecord(result);
        });

        // setUserData();
    }, [abp])

    // useEffect(() => {
    //     // retrieveRecord(props?.id).then((result: any) => {
    //     //     setRecord(result);
    //     // })
    // 	// getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
    // 	// 	setDescribe(result);
    //     //     setFields(result[resource]?.fields);
    //     //     const aUserList = result[resource]?.userlist ?? [];
    //     //     setAssignedUserList(aUserList);
    // 	// 	setLabel(result[resource]?.label);
    // 	// });
    // }, [resource, props?.id])


    const saveRecord = useCallback(
        async (values: any) => {
            // console.log('0save');
            // setIsLoading(true);
            const mobileInput: any = document.getElementById('phoneNumber');
            values.mobile = mobileInput?.value ?? '';
            if (!values.mobile) {
                notify(translate('AbpIdentity::PhoneNumber') + ' : ' + translate('ra.validation.required'), 'error');
                setIsLoading(false);
                return;
            }
            if (values.mobile.length < 5) {
                notify(translate('AbpIdentity::PhoneNumber') + ' : ' + translate('ra.validation.required'), 'error');
                setIsLoading(false);
                return;
            }

            let userData: IdentityUserUpdateDto = {
                userName: record.userName || abp.configuration.currentUser?.userName || '',
                name: values.name,
                surname: values.surname,
                email: values.email,
                phoneNumber: values.mobile,
            }

            AbpUserService.updateMyProfile(userData).then((r) => {
                // console.log('update profile');
                setIsLoading(false);
                notify(translate('AbpIdentity::SavedSuccessfully'), 'success');
            }).catch((error) => {
                notify(translate('AbpExceptionHandling::DefaultErrorMessage'), 'error');
            }).finally(() => {
                setIsLoading(false);
            });

        },
        [resource, record, update, notify, translate, redirect],
    );



    const ActionToolbar = (props: any) => (
        <Toolbar {...props} style={{ backgroundColor: 'transparent', padding: '10px 16px', marginTop: '-10px', position: 'relative' }}>

            <SaveButton
                label={translate('AbpUi::Save')}
                icon={isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary'} size={24} /> : <></>}
                submitOnEnter={true}
                disabled={isLoading ?? false}
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={pageClasses.authSubmitBtn}
                {...props}
            />

        </Toolbar>
    );


    return (
        <div className={pageClasses.root}>
            <Card className={pageClasses.appCard} variant="outlined">
                <CardContent>
                    <Box py="1rem" mt="-1.6rem">

                        <Edit component="div"  {...props} >
                            <SimpleForm save={saveRecord} toolbar={<ActionToolbar />}>
                                <Box mt={4}>
                                    <Grid container spacing={1}>
                                        {
                                            requiredFields.map((fieldName: any, idx: number) => {
                                                // console.log('Iterate', fieldName);
                                                let input: any = null;
                                                let gridSize: any = 6;
                                                const found: any = fields.filter((field: any) => field.name === fieldName);
                                                if (found.length > 0) {
                                                    const extraProps: any = { placeholder: found[0].label, label: found[0].label, elementId: found[0].name, inputAdornment: null };
                                                    input = FieldInput(found[0], extraProps, '', describe, assignedUserList);
                                                    if (found[0].name === 'imagename') {
                                                        found[0].label = '';
                                                        extraProps.multiple = false;
                                                        extraProps.labelSingle = '';
                                                        extraProps.labelMultiple = '';
                                                    } else if (found[0].name === 'phoneNumber') {
                                                        found[0].uitype = '11';
                                                        found[0].defaultValue = record?.phoneNumber;
                                                        input = <Box mt={1.1} mb={4.5} className={'PhoneInputBox'}> {FieldInput(found[0], extraProps, '', describe, assignedUserList)} </Box>
                                                    } else if (found[0].name === 'shop_role') {
                                                        if (isAdmin) {
                                                            found[0].editable = false;
                                                        }
                                                    } else {
                                                        input = FieldInput(found[0], extraProps, '', describe, assignedUserList);
                                                    }

                                                    if (found[0].name === 'otherstreet' || found[0].name === 'imagename') {
                                                        gridSize = 12;
                                                    } else {
                                                        gridSize = 6;
                                                    }
                                                }

                                                return (
                                                    <>
                                                        {input
                                                            ? <Grid item xs={12} md={gridSize} >  <Box mt={-2}>{input}</Box> </Grid>
                                                            : null
                                                        }
                                                    </>
                                                );
                                            })
                                        }
                                    </Grid>
                                </Box>
                            </SimpleForm>
                        </Edit>
                    </Box>
                </CardContent>
            </Card>
        </div>
    )
};