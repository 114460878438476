
export const appTheme = {
    palette: {
        primary: {
          light: '#c748b3',
          main: '#930483',
          dark: '#610056',
          contrastText: '#fff',
        },
        secondary: {
            light: '#ffffff',
            main: '#dcdcdc',
            dark: '#aaaaaa',
            contrastText: '#000000',
        },
    },
    typography: {
        fontFamily: 'Poppins',
        button: {
          textTransform: 'none',
        }
    },
    overrides:{
      MuiButton: {
        root: {
          borderRadius: '5px',
        },
      },
      MuiTableRow: {
        head: {
          backgroundColor: '#930483',
          color: '#fff',
          border: '2px solid #930483',
          "& > th ": {
            backgroundColor: '#930483',
            border: '0, 1px 0 0',
            borderColor: '#fff',
            borderRightColor: '#fff',
            color: '#fff',
            fontSize: '17px',
            paddingTop: '15px',
            paddingBottom: '15px',
            textAlign: 'center',
          }
        },
      },
      MuiTableCell: {
        root: {
          border: '1px solid #BDBDBD',
          borderBottom: '0',
          textAlign: 'center',
          // padding: '6px 16px !important'
        }
      },
      MuiFormLabel: {
        root: {
          fontSize: '14px',
         /*  marginTop: '-4px',
          marginLeft: '8px',
          '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            marginTop: '-15px !important',
          }, */
        },
      },
      RaList: {
        main: {
          justifyContent: 'center',
        },
    },
    },
}