import React, {useEffect, useState} from 'react';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {useTranslate} from "react-admin";
import {Box} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import {setMonth, subYears} from "date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Button from "@material-ui/core/Button";


const Chart =  ({data} : {data :any})=> {
    const [chartData, setChartData] = useState<any[]>();
    const [monthFilter, setMonthFilter] = useState<any>(null);
    const [yearFilter, setYearFilter] = useState<any>(null);
    const [filterData, setFilterData] = useState<boolean>(false);
    const translate = useTranslate();
    useEffect(()=>{
        formatData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])
    const getNumberOfOrdersPerOrderType = (data :any[], order_type:string , month:number)=>{
        const numberOfOrders = data.find((record:any)=> parseInt(record['month(vtiger_crmentity.createdtime)']) === month && record?.order_type === order_type )['no orders']
        return Number(numberOfOrders);
    }
    const formatData = (incomingData :any[], filterData?:boolean) => {
        const months:any[] = [] ;
        const distinctDataByMonth = incomingData?.filter((item:any, index:number, self:any[]) =>
                index === self.findIndex((obj:any)=>(
                    obj['month(vtiger_crmentity.createdtime)'] === item['month(vtiger_crmentity.createdtime)']
                ))
        );
        distinctDataByMonth?.forEach((record:any)=>{
            months.push(parseInt(record['month(vtiger_crmentity.createdtime)']));
        });
        let filteredData = months?.map((month:number)=>{
            return  {
                name: getMonth(month),
                Resi: getNumberOfOrdersPerOrderType(incomingData, 'Reso', month),
                Ordini: getNumberOfOrdersPerOrderType(incomingData, 'Normal Order', month),
                'Ordini shop-to-shop': getNumberOfOrdersPerOrderType(incomingData, 'Shop2Shop', month),
            }
        });
        if (filteredData.length > 6 && !filterData) filteredData =  filteredData.slice(-6);
        setChartData(filteredData)
    }
    const getMonth = (monthNumber:number) => {
      switch (monthNumber) {
          case 1: return translate('months.January');
          case 2: return translate('months.February');
          case 3: return translate('months.March');
          case 4: return  translate('months.April');
          case 5: return translate('months.May');
          case 6: return  translate('months.June');
          case 7: return  translate('months.July');
          case 8: return translate('months.August');
          case 9: return translate('months.September');
          case 10: return translate('months.October');
          case 11: return translate('months.November');
          case 12: return translate('months.December');
          default: return ;
      }
    }
    const handleFilters = () => {
        let incomingData = data;
            if (yearFilter)  incomingData = incomingData.filter((order :any)=> Number(order['year(vtiger_crmentity.createdtime)']) === Number(yearFilter?.getFullYear()));
            if (monthFilter)  incomingData = incomingData.filter((order :any)=> Number(order['month(vtiger_crmentity.createdtime)']) >=  Number(monthFilter?.getMonth()) + 1);
            setFilterData(true);
            formatData(incomingData, filterData);
    }
    return (
        <Box width="100%" height={"100%"}>
            <Box width={'100%'}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                 <Box display={'flex'} justifyContent={'space-evenly'}>
                     <Box>
                         <DatePicker inputVariant={'outlined'}  minDate={subYears(new Date(), 5)}
                                     label = {translate('translations.year')}
                                     maxDate = {new Date()}
                                     views={["year"]} value={yearFilter} onChange={(date)=>{
                             setYearFilter(date)}}/>
                     </Box>
                     <Box>
                         <DatePicker
                             inputVariant={'outlined'}
                             views={["year", "month"]}
                             label={translate('translations.month')}
                             minDate={setMonth(new Date(), new Date().getMonth() - 12)}
                             maxDate={new Date()}
                             value={monthFilter}
                             onChange={(data)=>{
                                 setMonthFilter(data)}}
                         />
                     </Box>
                     <Box display={'flex'} alignItems={'center'}>
                         <Button color={'primary'} onClick={()=>{handleFilters()}} variant={'contained'}>
                             {translate('translations.filter')}
                         </Button>
                     </Box>

                 </Box>
                </MuiPickersUtilsProvider>


            </Box>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={chartData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Bar dataKey="Ordini" fill="#1ea06e"/>
                    <Bar dataKey="Resi" fill="#fdc301"/>
                    <Bar dataKey="Ordini shop-to-shop" fill="#6c63ff"/>
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
}

export default Chart