import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Logo } from '../components/Logo';
import { useStyles } from './AuthStyle';
import {
    Notification,
} from 'react-admin';

import { useMediaQuery } from '@material-ui/core';


const AuthMessage = ({actionBtnGroup, image, title, message} : {actionBtnGroup: any, image: any, title: string, message: string}) => {
    const classes = useStyles();
    const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

    return (
        <div className={classes.root}>
            <Logo />

            <Card className={classes.authSinglePageCard} variant="outlined">
                <CardContent style = {{padding: "40px 60px"}}>
                    <Box alignItems="center"  style={{width: isDesktop ? '25%' : '50%', margin: 'auto'}}>
                        <img
                            src={image}
                            alt="Message Information"
                            width={'100%'}
                            height={'100%'}
                        />
                    </Box>
                    <Box textAlign="center" my={2}>
                        <Typography variant="h6" gutterBottom>
                            {title}
                        </Typography>
                    </Box>
                    <Box textAlign="center" style={{width: '80%', margin: '20px auto'}}>
                    <Typography component="div">
                        <Box textAlign="center">
                            <Typography variant="body2" gutterBottom>
                                {message}
                            </Typography>
                        </Box>
                    </Typography>
                    </Box>
                    {actionBtnGroup}
                </CardContent>
            </Card>
            <Notification />
        </div>
    );
};

export default AuthMessage;