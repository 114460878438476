import React, {useState, useEffect, useCallback} from 'react';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {Logo} from '../components/Logo';
import {useStyles} from './AuthStyle';
import CircularProgress from '@material-ui/core/CircularProgress';
import {doQuery, massCreate} from '../utils/lib';
import AuthMessage from './AuthMessage'

import {
    useRedirect,
    Create,
    SimpleForm,
    useCreate,
    useNotify,
    Notification,
    Toolbar,
    SaveButton,
    useTranslate,
    Loading,
} from 'react-admin';

import {getModDescribe, getChallenge} from '../utils/lib';
import FieldInput from '../utils/FieldInput';
import {PHONE_NUMBER_PREFIX} from '../constant';
import {AppButton} from '../components/AppButton';
import previewEmailImg from '../assets/previewEmailImg.png';
import { CheckboxWithLabel } from './CheckboxWithLabel';

const Register = (props: any) => {
    const resource = props.resource;
    const translate = useTranslate();
    const classes = useStyles();
    const redirect = useRedirect();
    const notify = useNotify();
    const [create] = useCreate();
    const [sLoading, setIsLoading] = useState<boolean>(false);
    const [fields, setFields] = useState<any[]>([]);
    //const formFields = ['accountname', 'firstname', 'lastname', 'mobile', 'email', 'mailingstreet'];
    const formFields = ['accountname', 'firstname', 'lastname', 'mobile', 'email', 'shop_role'];
    const [pwaUser, setPwaUser] = useState<any>(null);
    const [showAuthMessage, setShowAuthMessage] = useState<boolean>(false);
    const [acceptedTerms, setAcceptedTerms] = useState<any[]>([]);
    const userAcceptanceTerms: any[] = [
        {name: 'terms_conditions'},
        {name: 'data_processing'},
        {name: 'special_clauses'},
    ];
    const userAcceptanceTermsLabels: any[] = [
         'Acceta Termini & Condizioni',
        'Accetta Trattamento Dati',
        'Accetta Clausole speciali'
    ];

    useEffect(() => {
        getChallenge('pwa.admin', 'xssLTMR8vNz7j252').then((result) => {
            return result;
        }).then((res) => {
            setPwaUser(res);
            // getModDescribe(resource).then((modDescribe) => {
            //     return modDescribe?.fields ?? [];
            // }).then((result: any[]) => {
            //     getModDescribe('Accounts').then((modDescribe) => {
            //         let allFields = modDescribe?.fields ?? [];
            //         allFields = result.concat(allFields);
            //         setFields(allFields);
            //     })
            // })
        })
    }, [resource])

    const shouldSaveButtonBeDissabled = () => {
        if (acceptedTerms.length < userAcceptanceTerms.length) return true;
        return sLoading;
    }

    const CreateToolbar = (props: any) => (
        <Toolbar {...props}
                 style={{backgroundColor: '#fff', padding: '10px 16px', position: 'relative'}}>

            <SaveButton
                label="Registrati"
                icon={sLoading ? <CircularProgress color={sLoading ? 'primary' : 'secondary'} size={24}/> : <></>}
                submitOnEnter={true}
                disabled={shouldSaveButtonBeDissabled()}
                //fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.authSubmitBtn}
                style={{width: '50%', margin: 'auto', fontSize: '18px'}}
                {...props}
            />

        </Toolbar>
    );

    const ActionBtnGroup = () => (
        <Box my={2} style={{width: '50%', margin: 'auto'}}>
            <AppButton fullWidth variant="contained" color="primary" onClick={() => redirect('/login')} size="large"
                       className={classes.authSubmitBtn}>
                Torna al Login
            </AppButton>
        </Box>
    )

    const saveRecord = useCallback(
         (values: any) => {
            setIsLoading(true);
            const mobileInput: any = document.getElementById('mobile');
            let hasError: boolean = false;
            values.mobile = mobileInput?.value ?? '';
            if (!values.mobile) {
                notify(translate('translations.phone') + ' : ' + translate('ra.validation.required'), 'error');
                setIsLoading(false);
                hasError = true;
                // return;
            }
            if (values.mobile.length < 5) {
                notify(translate('translations.phone') + ' : ' + translate('ra.validation.required'), 'error');
                setIsLoading(false);
                hasError = true;
            }
            values.assigned_user_id = pwaUser?.userId;
            const {accountname, ...contactData} = values;
            if (!acceptedTerms || acceptedTerms.length < userAcceptanceTerms.length) {
                notify(translate('translations.please_accept_terms'), 'error',);
                setIsLoading(false);
                hasError = true;
            }

          if (!hasError){
              doQuery(`SELECT id FROM ${resource} WHERE email = '${contactData.email}' LIMIT 1`).then((contactRes: any) => {
                  let contact = contactRes && contactRes[0] ? contactRes[0] : null;
                  if (contact) {
                      notify(translate('translations.email_exist'), 'error');
                      setIsLoading(false);
                      hasError = true;
                  }
                  contactData.terms_conditions = true;
                  contactData.data_processing = true;
                  contactData.special_clauses = true;
                  if (!hasError){
                      doQuery(`SELECT id, accountname FROM Accounts WHERE accountname = '${accountname}' LIMIT 1`).then((accountRes: any) => {
                          let account = accountRes && accountRes[0] ? accountRes[0] : null;
                          contactData.assigned_user_id = '19x20';
                          contactData.created_user_id = '19x20';
                          contactData.portalloginuser = '19x8';
                          if (account && account.id) {
                              contactData.account_id = account.id;
                              contactData.phone = contactData.mobile;
                              create(resource, contactData, {
                                  onSuccess: () => {
                                      setIsLoading(false);
                                      setShowAuthMessage(true);
                                  },
                                  onFailure: () => {
                                      setIsLoading(false);
                                      notify('Qualcosa è andato storto, riprova più tardi', 'error');
                                  },
                              });
                          } else {
                              const accountData = {
                                  accountname: accountname,
                                  accounttype: 'Shop',
                                  phone: contactData.mobile,
                                  email1: contactData.email,
                                  assigned_user_id: '19x20',
                                  created_user_id: '19x20'
                              };
                              contactData.account_id = '@{REFERENCE_ACCOUNT}';
                              massCreate(
                                  [
                                      {
                                          elementType: "Accounts",
                                          referenceId: "REFERENCE_ACCOUNT",
                                          element: accountData

                                      },
                                      {
                                          elementType: resource,
                                          referenceId: "REFERENCE_CONTACT",
                                          element: contactData,
                                      }
                                  ]
                              ).then(() => {
                                  setShowAuthMessage(true)
                              }, () => {
                                  notify(translate('translations.something_went_wrong'), 'error', undefined, true)
                              })
                          }
                      })
                  }
              });
          }


        },
        [pwaUser?.userId, acceptedTerms, userAcceptanceTerms.length, notify, translate, resource, create],
    );
    const redirectToOuterPage = (valueChecked :string) =>{
        switch (valueChecked) {
            case userAcceptanceTerms[0]?.name:
                window.open('https://www.woradelivery.com/condizioni-generali', '_blank');
                break;
            case userAcceptanceTerms[1]?.name:
                window.open('https://www.woradelivery.com/trattamento-dati', '_blank');
                break;
            case userAcceptanceTerms[2]?.name:
                window.open('https://www.woradelivery.com/condizioni-generali', '_blank');
                break;
            default:
                break;
        }
    } 

    return (
        <>
            {showAuthMessage ? (
                <AuthMessage actionBtnGroup={<ActionBtnGroup/>} image={previewEmailImg}
                             title={'Grazie per aver inviato la tua registrazione'}
                             message={'Ti invieremo via mail la password di accesso temporanea non appena avremo verificato le tue informazioni.'}/>
            ) : (
                <Box className={classes.root}>
                    <Logo/>
                    <Box mt={5}>
                        <Card className={classes.authCardReg} variant="outlined">
                            <CardContent>
                                <Typography component="div">
                                    <Box className={classes.authTitle} textAlign="center" my={0.2}>
                                        Registrazione
                                    </Box>
                                </Typography>
                                <Box mt={3.5}>
                                    {fields.length <= 0 ? (
                                        <Loading loadingSecondary={''}/>
                                    ) : (
                                        <Create {...props} component="div">
                                            <SimpleForm save={saveRecord} toolbar={<CreateToolbar/>}>
                                                <Box mt={0.8}>
                                                    <Grid container spacing={2}>
                                                        {
                                                            fields.map((field: any) => {
                                                                const extraProps = {
                                                                    placeholder: '',
                                                                    label: field.label,
                                                                    prefix: field.name === 'mobile' ? PHONE_NUMBER_PREFIX : ''
                                                                };
                                                                let input: any = null;
                                                                if (formFields.includes(field.name)) {
                                                                    field.mandatory = true;
                                                                    if (field.uitype === '11') {
                                                                        input = <Box mt={1.1} mb={4}
                                                                                     className={'PhoneInputBox'}> {FieldInput(field, extraProps, '')} </Box>
                                                                    } else {
                                                                        if (field.name === 'shop_role') {
                                                                            field.editable = true;
                                                                            field.type.picklistValues = field.type.picklistValues.filter((pickList: any) => pickList.value !== '--None--')
                                                                        }
                                                                        input = FieldInput(field, extraProps, '');
                                                                    }
                                                                }

                                                                return (
                                                                    <>
                                                                        {input
                                                                            ?
                                                                            <Grid key={field.name} item xs={12} md={6}>
                                                                                <Box mt={-5}>{input}</Box> </Grid>
                                                                            : null
                                                                        }
                                                                    </>
                                                                );
                                                            })
                                                        }

                                                    </Grid>
                                                    <Box display={'flex'} justifyContent={'center'} mt={2}>
                                                        {
                                                            userAcceptanceTerms.map((term: any, index :number) => 
                                                                    <CheckboxWithLabel key={term?.name} label={userAcceptanceTermsLabels[index]} redirect = {redirectToOuterPage} val = {term?.name} checkValue={setAcceptedTerms} />
                                                            )
                                                        }
                                                    </Box>
                                                </Box>
                                            </SimpleForm>
                                        </Create>
                                    )}

                                    <Box mt={0.8}>
                                        <Typography component="div" align={'right'} variant="body2">
                                            <Box fontSize="small" mx={-2}>
                                                Hai già un account? <span className={'btnPointer'}
                                                                          onClick={() => redirect('/login')}
                                                                          style={{textDecoration: 'underline'}}>Accedi</span>.
                                            </Box>
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                    <Notification/>
                </Box>
            )}
        </>
    );
};

export default Register;