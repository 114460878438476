import { withStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Tooltip from '@material-ui/core/Tooltip';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    "& .MuiFormLabel-root": {
      opacity: "1 !important",
      color: "#BDBDBD",
    },
    "& .MuiTextField-root": {
      margin: "10px auto",
    },
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px'
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#DCDCDC",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      background: "#FDFDFD",
      "& fieldset": {
        borderColor: "#DCDCDC",
      },
      "&:hover fieldset": {
        borderColor: "#DCDCDC",
      },
      "& .Mui-focused fieldset": {
        borderColor: "#DCDCDC",
      },
    },
  },
  mainContainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "4%",
    },
    marginTop: "20%",
    width: '100%',
    backgroundColor: theme.palette.common.white,
  },
  componentContainer: {
    margin: '80px auto auto auto',
  },
  appCard: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      width: "48%",
    },
    width: "90%",
    margin: "auto",
    borderColor: "#DCDCDC",
  },
  actionToolbar: {
    margin: "auto",
  },
  cardFormHolder: {
    width: "100% !important",
    backgroundColor: "#fff",
  },
  editIcon: {
    width: "20px",
    height: "20px",
    marginRight : "5px",
  },
  accordionContent: {
    backgroundColor: "#F8F8F8",
    padding: "20px 16px",
    marginBottom: "20px",
    width: "100%",
  },
  toolbarBtn: {
    padding: "13px 22px",
    boxShadow: "none",
  },
  checkBoxLabel: {
    color: '#404040',
  },
  fieldDisplayBox: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    marginTop: "block",
  },
}));

export const Accordion = withStyles({
  root: {
    width: "100%",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    border: "1px solid #DCDCDC",
    borderRadius: "5px",
    background: "#F8F8F8",
    marginBottom: "20px",
    color: "#404040",
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    display: "block",
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#BDBDBD',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#BDBDBD',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 300,
    border: '1px solid #DCDCDC',
  },
}))(Tooltip);
