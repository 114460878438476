import { makeStyles } from '@material-ui/core/styles';

export const usePageStyles = makeStyles((theme) => ({
    root: {

    },
    editProfileBtn:{
        padding: "17px 25px"
    },
    editProfileBtnContainer:{
      width: "20%",
        [theme.breakpoints.down("xs")]: {
            width : "auto",
        }
    },
    responsiveLabelContainer: {
        margin: 'auto',
        [theme.breakpoints.down("xs")]: {
           flexDirection : "column",
        }
    },
    infoBoxes: {
        marginLeft: '6%',
        marginRight: '6%',
        width: "25%",
        padding : "12px 15px",
        border: "1px solid #dcdcdc",
        display : "flex",
        [theme.breakpoints.down("sm")]: {
            width: "auto",
            marginTop:"10px"
        }
    },
    barChartContainer : {
        [theme.breakpoints.down("md")]: {
            width: "100%",
            height: "500px",
            marginRight: "0",
            marginLeft: "0",
            marginTop: "30px"
        },
    }

}));
