import * as React from 'react';
import { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Box } from '@material-ui/core';
import { Accordion, AccordionDetails, AccordionSummary, HtmlTooltip, useStyles } from '../style/Index';
import { useOrderStyles } from './OrderStyle';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { PaymentInfo } from '../payment/PaymentInfo';

import Grid from '@material-ui/core/Grid';
import {
    AutocompleteInput,
    Create,
    FormDataConsumer,
    Notification, ReferenceInput, required,
    SaveButton,
    SimpleForm, TextInput,
    Toolbar,
    useGetIdentity,
    useNotify, usePermissions, useRedirect,
    useTranslate
} from 'react-admin';
import FieldInput from '../utils/FieldInput';

import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import {
    DELIVERY_PANEL_NAME, googleMapsApiKey,
    LARGE_PACKAGE_INFO,
    maximumHour,
    maxMinutesInTimeRange,
    MEDIUM_PACKAGE_INFO,
    PICKUP_PANEL_NAME,
    RETURN_ORDER_FIELDS,
    SMALL_PACKAGE_INFO,
    FIELD_DESCRIBE,
    staticProductList,
    DeliveryDefinition
} from '../constant';
import {
    abpPermissionCheck,
    areSelectedDataAndCurrentOneEqual,
    calculatePackageIVAPercentage,
    calculatePackageTotalAmount, formatSearchObject,
    generateInitialStartdate,
    genereateUid,
    getDataFromLocalDb, innerStringWhiteSpaceRemoval, permissionCheck,
    timeRestrictionSetter
} from '../utils/Helpers';
import AddresEdit from "../address/AddresEdit";
import IconButton from "@material-ui/core/IconButton";
import { Add } from "@material-ui/icons";
import { customAlphabet } from 'nanoid'
import ScheduleIcon from "@material-ui/icons/Schedule";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import DatePickerInput from "../utils/DatePickerInput";
import { addDays, addHours, format, setHours, setMinutes } from "date-fns";

import PhoneInput from "react-phone-input-2";
import { useHistory } from 'react-router';
import { DeliveryCreateOrEdtiDto } from '../types/DeliveryCreateOrEdtiDto';
import { useParams } from 'react-router-dom';
import { abpConfigurationProvider } from '../abp/abpConfigurationProvider';
import {
    ShopDeliveryService, wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryCreateDto, wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto,
    wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryUpdateDto, wora_CorbosService_DeliverySteps_ShopDeliveryStepCreateDto,
    wora_CorbosService_DeliverySteps_ShopDeliveryStepDto, wora_CorbosService_DeliverySteps_ShopDeliveryStepUpdateDto,
    wora_CorbosService_DeliverySteps_StepType
} from '../packages/proxy/src';
import useAbp from '../useAbp';
import { FieldInputTypeDto } from '../types/FieldInputTypeDto';
import { FormDeliveryUpdateOrCreate } from '../types/FormDeliveryUpdateOrCreate';
import { CoordinateDto } from '../types';

export const ReturnOrder = (props: any) => {

    const classes = useStyles();
    const orderClasses = useOrderStyles();
    const translate = useTranslate();
    const abp = useAbp();
    const notify = useNotify();
    const redirect = useRedirect();
    const history = useHistory();
    const { identity } = useGetIdentity();
    const { permissions } = usePermissions();

    const [expanded, setExpanded] = React.useState<any>('');
    const [expandedPanel, setExpandedPanel] = React.useState<any>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showPaymentInfo, setShowPaymentInfo] = useState<boolean>(false);
    const [massCreateData, setMassCreateData] = useState<any>(null);
    const [showAddAddres, setShowAddAddress] = useState<boolean>(false);
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [fields, setFields] = useState<FieldInputTypeDto[]>([]);
    const [describe, setDescribe] = useState<any>(null);
    const [assignedUserList, setAssignedUserList] = useState<any[]>([]);
    const [addressData, setAddressData] = useState<any>();
    const [contactDetails, setContactDetails] = useState<any>({});
    const [timeUntilDate, setTimeUntilDate] = useState<any>(null);
    const [timeFromDate, setTimeFromDate] = useState<any>(null);
    const [oPackages, setPackages] = useState<any>([]);
    const [products, setProducts] = useState<any[]>([]);
    const [userAccount, setUserAccount] = useState<any>({});
    const [generated, setGenerated] = useState<string>(''); //* generated key to make the mandatory  field rerender
    const [createdAddressId, setCreatedAddressId] = useState<string>('');

    const [extraFields, setExtraFields] = useState<boolean>(false);
    const [addressError, setAddressError] = useState<string>('');
    const [emailDelivery, setEmailDelivery] = useState<string>('');
    const [wrongAddressValue, setWrongAddressValue] = useState<any>(null);
    const [temporaryAddressValue, setTemporaryAddressValue] = useState<any>(null);
    const [editablePhone, setEditablePhone] = useState<string>('');


    const initialStartDate = generateInitialStartdate();
    const [startDate, setPickupFromDate] = useState<any>(generateInitialStartdate());

    const [filterAllShop, setFilterAllShop] = useState<boolean>(false);
    const standardDeliveryType = 1;
    const [userInput, setUserInput] = useState<DeliveryCreateOrEdtiDto>({});

    const { id } = useParams<{ [key: string]: string }>();
    const [record, setRecord] = useState<wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto>({});
    const [deliveryRecord, setDeliveryRecord] = useState<wora_CorbosService_DeliverySteps_ShopDeliveryStepDto>({});
    const [pickupRecord, setPickupRecord] = useState<wora_CorbosService_DeliverySteps_ShopDeliveryStepDto>({});
    const [deliveryDefinition, setDeliveryDefinition] = useState<DeliveryDefinition>({});
    const [houseNumber, setHouseNumber] = useState<string>('');
    const [coordinate, setCoordinate] = useState<CoordinateDto>({});
    
    // Check and validete permission
    useEffect(() => {
        if (typeof permissions !== "undefined") {
            if (!abpPermissionCheck(permissions, 'CorbosService.ShopPortal.ReturnOrder')) {
                redirect('/delivery');
            }
        }
    }, [permissions, redirect])

    useEffect(() => {
        // console.log('Id ->', id);

        if (id !== undefined) {


            const data = (props?.record ?? {}) as wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto;
            setRecord(data);
            data.steps?.map((step: wora_CorbosService_DeliverySteps_ShopDeliveryStepDto) => {
                if (step.stepType == wora_CorbosService_DeliverySteps_StepType._0) {
                    // console.log('Pickup Record', step);
                    if (step.requested) {
                        setPickupFromDate(new Date(step.requested ?? ''));
                    }
                    setTimeFromDate(new Date(step.requested ?? ''));
                    setTimeUntilDate(new Date(step.requestedEnd ?? ''));
                    setHouseNumber(step.addressNumber ?? '');
                    setCoordinate({ lat: step.lat, lng: step.lng });
                    setPickupRecord(step);
                } else if (step.stepType == wora_CorbosService_DeliverySteps_StepType._1) {
                    // console.log('Delivery Record', step);
                    setDeliveryRecord(step);
                }
            });

            // Reload time from/until
            if (data.deliveryType === 3) {
                // setTimeFromDate(new Date(deliveryRecord.requested ?? ''));
                // setTimeUntilDate(new Date(deliveryRecord.requestedEnd ?? ''));
                setDeliveryDefinition(getTimeFields(data.deliveryType));
            }
            setUserInput((prevState: any) => {
                console.log('Data from backend', data);
                return {
                    ...prevState,

                    totLargePackages: data.totLargePackages,
                    totMediumPackages: data.totMediumPackages,
                    totSmallPackages: data.totSmallPackages,
                    deliveryType: data.deliveryType,
                    externalOrderCode: data.externalOrderCode,

                    storeId: data.storeId,
                    storeName: data.storeName,
                    concurrencyStamp: data.concurrencyStamp,
                }
            });

        } else {
            setUserInput((prevState: any) => {
                // console.log('Data from backend', data);
                return {
                    ...prevState,

                    totLargePackages: 0,
                    totMediumPackages: 0,
                    totSmallPackages: 0,

                }
            });
        }

    }, [id]);



    const validateRecord = async (values: any) => {
        console.log('Validate Record', values);
        let data: FormDeliveryUpdateOrCreate = {};

        setIsLoading(true);
        // Global 
        data.deliveryType = Number(values[`modalita_di_consegna-${PICKUP_PANEL_NAME}`]);
        data.interPhone = values[`citoffono-${PICKUP_PANEL_NAME}`];
        data.retailerid = values[`retailerid-${PICKUP_PANEL_NAME}`];
        data.paymentStatus = 0; // Not Paid      
        
        data.tot_large_packages = oPackages.filter((pack: any) => pack.name === 'tot_large_packages')[0]?.count;
        data.tot_small_packages = oPackages.filter((pack: any) => pack.name === 'tot_small_packages')[0]?.count;
        data.tot_medium_packages = oPackages.filter((pack: any) => pack.name === 'tot_medium_packages')[0]?.count;

        // Pickup
        data.pickupContact = values[`customer_name-${PICKUP_PANEL_NAME}`];
        const mobileInputPickUp : any = document.getElementById(`phone-${PICKUP_PANEL_NAME}`);
        data.pickupPhone = innerStringWhiteSpaceRemoval(mobileInputPickUp?.value ?? '');
        data.pickupEmail = values[`email_task-${PICKUP_PANEL_NAME}`];
        data.reception = values[`reception-${PICKUP_PANEL_NAME}`];
        data.pickupAddress = values[`customer_address-${PICKUP_PANEL_NAME}`];
        data.pickupFromDate = format(new Date(startDate ?? initialStartDate), "yyyy-MM-dd HH:mm:ss");
        data.pickupNote = values[`task_notes-${PICKUP_PANEL_NAME}`];
        data.reception = values[`servizio_di_portineria-${PICKUP_PANEL_NAME}`];
        data.pickupAddress = addressData.label; // values[`customer_address-${DELIVERY_PANEL_NAME}`];
        data.pickupHouseNumber = houseNumber;
        // Delivery
        data.deliveryStoreId = values[`store_id-${DELIVERY_PANEL_NAME}`];
        data.deliveryContact = values[`referente-${DELIVERY_PANEL_NAME}`];
        data.deliveryEmail = values[`email_task-${DELIVERY_PANEL_NAME}`];
        const mobileInputDelivery: any = document.getElementById(`phone-${DELIVERY_PANEL_NAME}`);
        data.deliveryPhone = innerStringWhiteSpaceRemoval(mobileInputDelivery?.value ?? '');
        data.deliveryNote = values[`task_notes-${DELIVERY_PANEL_NAME}`];
        // data.deliveryFromDate = format(new Date(startDate ?? initialStartDate), "yyyy-MM-dd HH:mm:ss");
        data.coordinate = coordinate;
        
        if(data.deliveryType == 3){
            let timeFrom = values[`time_from-${PICKUP_PANEL_NAME}`];
            let timeUntil = values[`time_until-${PICKUP_PANEL_NAME}`];
            // console.log('Time From', timeFromDate);
            // console.log('Time Until', timeUntilDate);
            data.pickupFromDate = format(new Date(timeFromDate), "yyyy-MM-dd HH:mm:ss");
            data.pickupToDate = format(new Date(timeUntilDate), "yyyy-MM-dd HH:mm:ss");
        }

        // Global       
        

        let hasError: boolean = false;
        let message: string = '';
        if (!data.pickupAddress) {
            hasError = true;
            message = translate('CorbosService::InvalidAddress');
        }
        if (!data.pickupPhone || !data.deliveryPhone) {
            hasError = true;
            message = message + '\n' + translate('CorbosService::Phone') + ' : ' + translate('ra.validation.required');
        } else if (data.pickupPhone.length < 5 || data.deliveryPhone.length < 5) {
            hasError = true;
            message = message + '\n' + translate('CorbosService::Phone') + ' : ' + translate('ra.validation.required');
        }
        if (data.tot_large_packages === 0 && data.tot_medium_packages === 0 && data.tot_small_packages === 0) {
            hasError = true
            message = message + '\n' + translate('CorbosService::SelectAtLeastOnePackage');
        }

        if (hasError) {
            setTimeout(() => {
                notify(message, 'error');
                setIsLoading(false);
            }, 1000);
        } else {

            const paymentInfo: any = {
                subtotal: 0,
                ivaPercentage: 0,
                ivaValue: 0,
                total: 0
            };

            // if (expandedPanel === DELIVERY_PANEL_NAME) {
            //     oPackages.forEach((oPackage: any) => {
            //         values[oPackage.name] = oPackage.count;
            //         let packageSize = '';
            //         if (oPackage.name === 'tot_small_packages') {
            //             packageSize = 'Small';
            //         }
            //         if (oPackage.name === 'tot_medium_packages') {
            //             packageSize = 'Medium';
            //         }
            //         if (oPackage.name === 'tot_large_packages') {
            //             packageSize = 'Large';
            //         }

            //         paymentInfo.subtotal = paymentInfo.subtotal + calculatePackageTotalAmount(oPackage, packageSize, products);
            //         paymentInfo.ivaPercentage = calculatePackageIVAPercentage(packageSize, products);
            //     });
            // }
            // paymentInfo.ivaValue = paymentInfo.subtotal * paymentInfo.ivaPercentage / 100;
            // paymentInfo.total = paymentInfo.ivaValue + paymentInfo.subtotal;
            // values['payment_info_total'] = paymentInfo.total;
            //TODO skip payment info
            // setPaymentInfo(paymentInfo)

            saveRecords(data)
        }

    };

    const saveRecords = async (value: FormDeliveryUpdateOrCreate) => {
        // TODO skip payment info
        //setShowPaymentInfo(true);
        // return;

        setIsLoading(true);

        // let input: wora_CorbosService_Deliveries_DeliveryCreateShopPortal = {};


        // let timeFrom = value['time_from-Delivery'];;
        // console.log('Time From', timeFrom);

        if (id != undefined) { // Update

            let input: wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryUpdateDto = {}
            
            input.storeId = value.deliveryStoreId;
            input.totLargePackages = value?.tot_large_packages || 0;
            input.totMediumPackages = value?.tot_medium_packages || 0;
            input.totSmallPackages = value?.tot_small_packages || 0;
            input.concurrencyStamp = record.concurrencyStamp; // Concurrency Stamp
            input.externalOrderCode = value.retailerid;
            // input.orderDate = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
            input.deliveryType = value.deliveryType;
            input.steps = [];

            // Pickup Data
            let pickupStep: wora_CorbosService_DeliverySteps_ShopDeliveryStepUpdateDto = {};
            // pickupStep.stepType = wora_CorbosService_DeliverySteps_StepType._0;
            pickupStep.contactName = value.pickupContact;
            pickupStep.contactEmail = value.pickupEmail;
            pickupStep.contactPhone = value.pickupPhone;
            pickupStep.fullAddress = value.pickupAddress;
            pickupStep.houseNumber = value.pickupHouseNumber;
            pickupStep.lat = value.coordinate?.lat;
            pickupStep.lng = value.coordinate?.lng;
            pickupStep.interPhone = value.interPhone;
            pickupStep.reception = value.reception;
            pickupStep.note = value.pickupNote;
            switch (value.deliveryType) {
                case 1: // Wora Same Day
                case 2: // Wora Flash
                    pickupStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
                    break;
                case 3: // Wora Scheduled   
                    pickupStep.timeTo = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
                    pickupStep.timeTo = value.pickupToDate ? new Date(value.pickupToDate).toISOString() : null;
                    break;
            }
            pickupStep.id = pickupRecord.id;

            input.steps.push(pickupStep);

            // Delivery Data
            let deliveryStep: wora_CorbosService_DeliverySteps_ShopDeliveryStepUpdateDto = {};

            deliveryStep.storeId = value.deliveryStoreId;
            deliveryStep.contactName = value.deliveryContact;
            deliveryStep.contactEmail = value.deliveryEmail;
            deliveryStep.contactPhone = value.deliveryPhone;
            deliveryStep.note = value.deliveryNote;
            switch (value.deliveryType) {
                case 1: // Wora Same Day
                case 2: // Wora Flash
                    deliveryStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
                    break;
                case 3: // Wora Scheduled   
                    deliveryStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
                    //pickupStep.requestedEnd = value.pickupToDate ? new Date(value.pickupToDate).toISOString() : null;
                    break;
            }
            deliveryStep.id = deliveryRecord.id; // Delivery Step Id

            input.steps.push(deliveryStep);

            // console.log('Create Update',input);
            await ShopDeliveryService.putApiShopDeliveries(id, input).then(data => {
                setIsLoading(false);
                history.push('/delivery'); // Go to List
            }).catch(err => {
                setTimeout(() => {
                    notify(translate('CorbosService::SavingError'), 'error');
                    setIsLoading(false);
                }, 1000);
                setIsLoading(false);
            });
        } else {
            let input: wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryCreateDto = {};

            input.orderType = 1; // Return Order
            input.storeId = value.deliveryStoreId;
            input.totLargePackages = value?.tot_large_packages || 0;
            input.totMediumPackages = value?.tot_medium_packages || 0;
            input.totSmallPackages = value?.tot_small_packages || 0;
            input.externalOrderCode = value.retailerid;
            // input.orderDate = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
            input.deliveryType = value.deliveryType;

            input.steps = [];

            // Pickup Data
            let pickupStep: wora_CorbosService_DeliverySteps_ShopDeliveryStepCreateDto = {};
            pickupStep.contactName = value.pickupContact;
            pickupStep.contactEmail = value.pickupEmail;
            pickupStep.contactPhone = value.pickupPhone;
            pickupStep.fullAddress = value.pickupAddress;
            pickupStep.interPhone = value.interPhone;
            pickupStep.note = value.pickupNote;
            pickupStep.reception = value.reception;
            
            switch (value.deliveryType) {
                case 1: // Wora Same Day
                case 3: // Wora Flash
                    pickupStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
                    break;
                case 2: // Wora Scheduled   
                    pickupStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
                    pickupStep.timeTo = value.pickupToDate ? new Date(value.pickupToDate).toISOString() : null;
                    break;
            }

            input.steps.push(pickupStep);

            // Delivery Data
            let deliveryStep: wora_CorbosService_DeliverySteps_ShopDeliveryStepCreateDto = {};
            deliveryStep.stepType = wora_CorbosService_DeliverySteps_StepType._1;
            deliveryStep.storeId = value.deliveryStoreId;
            deliveryStep.contactName = value.deliveryContact;
            deliveryStep.contactEmail = value.deliveryEmail;
            deliveryStep.contactPhone = value.deliveryPhone;
            deliveryStep.note = value.deliveryNote;

            switch (value.deliveryType) {
                case 1: // Wora Same Day
                case 3: // Wora Flash
                    deliveryStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
                    break;
                case 2: // Wora Scheduled   
                    deliveryStep.timeFrom = value.pickupFromDate ? new Date(value.pickupFromDate).toISOString() : null;
                //deliveryStep.timeTo = value.pickupToDate ? new Date(value.pickupToDate).toISOString() : null;
                    break;
            }

            input.steps.push(deliveryStep);

            // console.log('Create Mode',input);
            //TODO disable when payment info
            await ShopDeliveryService.postApiShopDeliveriesCreate(input).then(data => {
                setIsLoading(false);
                history.push('/delivery'); // Go to List
            }).catch(err => {
                setIsLoading(false);
                setTimeout(() => {
                    notify(translate('CorbosService::SavingError'), 'error');
                    setIsLoading(false);
                }, 1000);
            });
        }



    };

    useEffect(() => {
        // console.log('NowOrder - idenitty');
        const userdata = abpConfigurationProvider.getUser();
        const contactDetail = identity && identity.contactDetail;
        if (userdata && id === undefined) {

            deliveryRecord.contactName = userdata.name;
            deliveryRecord.contactEmail = userdata.email;
            deliveryRecord.contactPhone = userdata.phoneNumber;

        }
    }, [abp])

    useEffect(() => {
        // console.log('Init New Return Order');
        // Use to design inteface
        // getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result: any) => {
        let modFields = FIELD_DESCRIBE.returnOrder;
        setFields(FIELD_DESCRIBE.returnOrder);
        //console.log('####################### Ro',FIELD_DESCRIBE.returnOrder);
        setProducts(staticProductList);
        setDeliveryDefinition(RETURN_ORDER_FIELDS); // Set the fields
        let packageList = modFields.filter((field: any) => field.name === 'tot_small_packages' || field.name === 'tot_medium_packages' || field.name === 'tot_large_packages');
        let packageList2 = packageList.map((field: any, index: number) => {
            let info: string[] = [];
            let unit_price;
            if (field.name === 'tot_small_packages') {
                info = SMALL_PACKAGE_INFO;
                unit_price = products[0]?.unit_price;
            }
            if (field.name === 'tot_medium_packages') {
                info = MEDIUM_PACKAGE_INFO;
                unit_price = products[1]?.unit_price;
            }
            if (field.name === 'tot_large_packages') {
                info = LARGE_PACKAGE_INFO;
                unit_price = products[2]?.unit_price;
            }
            const oPackage: any = oPackages.filter((pack: any) => pack.name === field.name)
            //if the package has count then it will not be zero when the other panel is expanded
            if (oPackage.length > 0) {
                if (oPackage[0]?.count && oPackage[0]?.count > 0) return {
                    name: field.name,
                    label: field.label,
                    count: 0,
                    info: info,
                    index: index,
                    unitPrice: unit_price
                }
            }
            return { name: field.name, label: field.label, count: 0, info: info, index: index, unitPrice: unit_price }
        })

        setPackages(packageList2);
        // })
    }, []) // eslint-disable-line

    // useEffect(() => {
    //     const userdata = identity && identity.userdata;
    //     const contactDetail = identity && identity.contactDetail;


    //     const shopId = contactDetail && contactDetail.shopname;
    //     const contactRoleId = userdata && (userdata.is_admin === 'on' || userdata.rolename === 'Manager') ? '' : shopId;
    //     const filterAll = userdata && (userdata.is_admin === 'on' || userdata.rolename === 'Manager');
    //     setFilterAllShop(filterAll);
    //     setCreatedAddressId(contactRoleId);

    //     doQuery('SELECT * FROM Products').then((productList: any) => {
    //         setProducts(productList);
    //     }).catch(e => console.error(e));

    //     if (deliveryDefinition.pickupInformation.orderDetails.filter(e => e.name === 'time_until').length > 0) {
    //         deliveryDefinition.pickupInformation.orderDetails.splice(
    //             5, 1
    //         );
    //         deliveryDefinition.pickupInformation.orderDetails[4].gridSize = 12
    //     }
    // }, [identity]);

    useEffect(() => {
        // getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result: any) => {
        //     let modFields = FIELD_DESCRIBE.returnOrder;
        //     setFields(FIELD_DESCRIBE.returnOrder);
        // });

    }, [expandedPanel]) // eslint-disable-line

    const getTimeFields = (status: Number) : DeliveryDefinition => {
        console.log('getTimeFields', status);
        if (status == 2 && !deliveryDefinition.pickupInformation?.orderDetails.filter(e => e.name === 'time_from').length) {

            // setExtraFields(true);
            const newOrderDetails = [...(deliveryDefinition.pickupInformation?.orderDetails || [])];
            newOrderDetails.splice(4, 0);
            newOrderDetails.splice(
                4, 0,
                { name: 'time_from', inputAdornment: ScheduleIcon, prefix: '', gridSize: 6 }
            );
            newOrderDetails.splice(
                5, 0,
                { name: 'time_until', inputAdornment: ScheduleIcon, prefix: '', gridSize: 6 }
            );
            const newDeliveryDefinition = {
                ...deliveryDefinition,
                pickupInformation: {
                    ...deliveryDefinition.pickupInformation,
                    orderDetails: newOrderDetails
                }
            };

            return newDeliveryDefinition

        } else {
            return RETURN_ORDER_FIELDS;
        }
    }

    const displayAddressBox = (placeHolder: string) => {
        const updateValues = (value: any) => {
            const secondArgument = value.label.split(",")[1];
            const secondArgumentFirstArgument = parseInt(secondArgument?.trim()?.slice(0, 1));
            const firstConditionForAddressValidationToFail: boolean = isNaN(Number(secondArgumentFirstArgument));
            const secondConditionForAddressValidationToFail: boolean = isNaN(Number(secondArgument.split("/")[0])) || isNaN(Number(secondArgument.split("/")[1]));
            if (firstConditionForAddressValidationToFail && secondConditionForAddressValidationToFail) { // * if the second argument is not a number and has not this type of value *31/4* the validation will fail
                setTemporaryAddressValue(null);
                setWrongAddressValue(value);
                setAddressError(translate('translations.address_validation_error'));
            } else {
                setAddressData(value);
                setAddressError('');
                setWrongAddressValue(null);
                setTemporaryAddressValue(null);
            }
        }
        const checkTheInputChangedValue = (v: string, action: any) => {
            if (action?.action === 'input-change') {

                //we clear tha value if it is set, so the text in the input can be edited
                if (typeof addressData !== 'undefined' || addressData) {
                    setAddressData(null);
                }
                const secondArgument = v.split(",")[1];
                const secondArgumentFirstArgument = parseInt(secondArgument?.trim()?.slice(0, 1));

                //check if the address typed contains the building number
                if (v.split(",").length >= 1 && isNaN(secondArgumentFirstArgument)) {
                    setTemporaryAddressValue(null);
                    setWrongAddressValue({
                        label: v,
                        value: {
                            description: v
                        }
                    });
                    setAddressError(translate('translations.address_validation_error'));
                } else {
                    setWrongAddressValue(null);
                    setTemporaryAddressValue({
                        label: v,
                        value: {
                            description: v
                        }
                    });
                    if (v.split(',').length >= 3) {
                        setAddressError(translate('translations.select_address_option'))
                    }
                }
            }
        }
        return (
            <div key={'addressFieldBox'} style={{ marginBottom: '40px', width:"100%" }}>
                <GooglePlacesAutocomplete
                    apiKey={googleMapsApiKey} 
                    selectProps={{
                        value: wrongAddressValue ? wrongAddressValue : addressData,
                        onChange: updateValues,
                        inputName: 'customer_address',
                        placeholder: placeHolder,
                        filterOption: (v: any) => {
                            const secondArgument = parseInt(v.label.split(",")[1]);
                            if (!isNaN(secondArgument)) {
                                //if the second argumwent is a number, it is correct.
                                return v;
                            }
                        },
                        onInputChange: checkTheInputChangedValue,
                        inputValue: addressData?.label! ? addressData?.label! : wrongAddressValue ? wrongAddressValue?.label! : temporaryAddressValue?.label!,
                        closeMenuOnSelect: false,
                        classNamePrefix: "PLACES_",
                        styles: {
                            container: (provided: any, state: any) => ({
                                ...provided,
                                border: addressError && '1px solid red',
                                zIndex: state?.isFocused ? 2 : 1,
                            }),
                            control: (provided: any, state: any) => ({
                                ...provided,
                                zIndex: state?.isFocused ? 2 : 1,
                            }),
                            placeholder: (provided: any) => ({
                                ...provided,
                                color: '#BDBDBD'
                            })
                        }
                    }}
                    autocompletionRequest={{
                        componentRestrictions: {
                            country: ['it'],
                        }
                    }}
                />
                {addressError && <Box display={'flex'} width={'100%'} px={1} my={1} textAlign={'start'}
                    style={{ color: 'red' }}>{addressError}</Box>}
            </div>
        );
    }

    const updatePackageCount = (indexValue: number, value: number) => {
        console.log('updatePackageCount', indexValue, value);
        const newPackages = oPackages;
        newPackages[indexValue].count = newPackages[indexValue].count + value;
        newPackages[indexValue].count = newPackages[indexValue].count >= 0 ? newPackages[indexValue].count : 0;

        if (indexValue == 0) {
            setUserInput((prevState: any) => {
                return { ...prevState, totSmallPackages: newPackages[indexValue].count }
            });
        } else if (indexValue == 1) {
            setUserInput((prevState: any) => {
                return { ...prevState, totMediumPackages: newPackages[indexValue].count }
            });
        } else if (indexValue == 2) {
            setUserInput((prevState: any) => {
                return { ...prevState, totLargePackages: newPackages[indexValue].count }
            });
        }

        setPackages([...newPackages]);
    }

    const handleChange = (panel: any) => (event: any, newExpanded: any) => {
        setExpandedPanel(panel);
        setExpanded(newExpanded ? panel : false);
    };



    const displayPackageBox = (packageName: any, itemNumber: number = 0) => {
        // console.log('displayPackageBox -> ', packageName, itemNumber);

        let packageInfo = oPackages.filter((opackage: any) => opackage.name === packageName)[0];
        packageInfo = packageInfo ?? {};
        packageInfo.count = itemNumber;
        return (
            <Box py={1} px={0.2} mt={1} mb={4} className={orderClasses.packageBox}>
                <Typography component="div" align="center">
                    <Box fontSize="small" justifyContent="center">
                        <Box>
                            <span className={orderClasses.packageTitleBox}>{translate(packageInfo?.label)} </span>
                            <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        {
                                            packageInfo?.info?.map((info: string, index: number) => {
                                                return (
                                                    <Box key={index}> - <span>{`${info}`}</span> </Box>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                }

                            >
                                <InfoIcon className={orderClasses.packageBoxInfoIcon} />
                            </HtmlTooltip>
                        </Box>
                    </Box>
                </Typography>
                <Typography component="div">
                    <Box mt={1} display="flex" justifyContent="center">
                        <Typography className={orderClasses.packageBoxCounterBtn}
                            onClick={() => updatePackageCount(packageInfo?.index, -1)}>
                            <RemoveCircleOutlineIcon /> </Typography>
                        <Typography> <span id={packageInfo.name + '-count'}
                            className={orderClasses.packageBoxCounter}> {packageInfo?.count} </span>
                        </Typography>
                        <Typography className={orderClasses.packageBoxCounterBtn}
                            onClick={() => updatePackageCount(packageInfo?.index, 1)}> <AddCircleOutlineIcon />
                        </Typography>
                    </Box>
                </Typography>
            </Box>
        )
    }

    const CreateToolbar = (props: any) => {
        return (
            <Toolbar {...props} className={orderClasses.actionToolbar}
                style={{ backgroundColor: '#fff', padding: '0', position: 'relative' }}>
                <SaveButton
                    label={(id == undefined ? translate('CorbosService::GoToPayment') : translate('CorbosService::Save'))}
                    icon={isLoading ? <CircularProgress color={isLoading ? 'primary' : 'secondary'} size={24} /> : <></>}
                    //submitOnEnter={true}
                    disabled={isLoading}
                    redirect={false}
                    fullWidth
                    onClick={() => setGenerated(genereateUid())} //*uid to change the key of the grid to rerender the mandatory fields
                    size="small"
                    className={classes.toolbarBtn}
                    {...props}
                />
            </Toolbar>
        );
    }

    const handleFormValuesChange = (formDataValues: any) => {
        setTimeout(() => {
            let status = formDataValues && formDataValues[`modalita_di_consegna-${PICKUP_PANEL_NAME}`] ? Number(formDataValues[`modalita_di_consegna-${PICKUP_PANEL_NAME}`]) : 0;
            //check if the value is Wora Scheduled Delivery, check if the field is not present, and if both true add the time_until field
            if (formDataValues && status === 2  &&
                !deliveryDefinition.pickupInformation?.orderDetails.filter(e => e.name === 'time_until').length && !extraFields) {
                // deliveryDefinition.pickupInformation?.orderDetails.splice(
                //     4, 1,
                //     { name: 'time_from', inputAdornment: ScheduleIcon, prefix: '', gridSize: 6 },
                //     { name: 'time_until', inputAdornment: ScheduleIcon, prefix: '', gridSize: 6 }
                // );
                // // TODO: check if the gridSize is correct
                // if(deliveryDefinition.pickupInformation && deliveryDefinition.pickupInformation?.orderDetails && deliveryDefinition.pickupInformation?.orderDetails[4]) {
                //     let orderDetailsItem = deliveryDefinition.pickupInformation?.orderDetails[4];
                //     if(orderDetailsItem) {
                //         orderDetailsItem.gridSize = 6;
                //     }
                // }
                setDeliveryDefinition(getTimeFields(status));
                setExtraFields(true);

            }
            ///remove the time_from and time_until fields
            if (formDataValues && status != 2 && extraFields) {
                // setDeliveryDefinition(RETURN_ORDER_FIELDS);
                setDeliveryDefinition(getTimeFields(status));
                setExtraFields(false);
            }
        }, 200);
    }


    return (
        <>
            {
                showPaymentInfo
                    ? <PaymentInfo packages={oPackages} massCreateData={massCreateData} account={userAccount}
                        paymentinfo={paymentInfo} {...props} />
                    : <div className={orderClasses.root}>
                        <Card className={classes.appCard} variant="outlined">
                            <CardContent>
                                <Typography component="div" variant="h6">
                                    <Box fontWeight="fontWeightBold" textAlign="center" py="1rem">
                                    {id ? translate('CorbosService::EditDelivery') : translate('CorbosService::CreateDelivery')} {translate('CorbosService::ReturnOrder')}
                                    </Box>
                                </Typography>

                                <Box py="1rem" mt="-1.6rem">

                                    <Create {...props} component="div">
                                        <SimpleForm save={validateRecord} toolbar={<CreateToolbar />}>

                                            <Accordion square expanded={expanded === PICKUP_PANEL_NAME}
                                                onChange={handleChange(PICKUP_PANEL_NAME)} style={{ width: '100%' }}>
                                                <AccordionSummary aria-controls={PICKUP_PANEL_NAME + '-content'}
                                                    id={PICKUP_PANEL_NAME + '-header'}>
                                                    <Grid container spacing={0} alignItems="center">
                                                        <Grid item xs={2} md={2}>
                                                            <Box mt={0.4}> {expanded === PICKUP_PANEL_NAME ?
                                                                <ExpandLessIcon /> : <ExpandMoreIcon />} </Box>
                                                        </Grid>
                                                        <Grid item xs={8} md={8}>
                                                            <Typography
                                                                align={'center'}>{translate('CorbosService::PickUp')} </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <div className={classes.accordionContent}>

                                                    <AccordionDetails>

                                                        <Box py="1rem" mt="-1.4rem" textAlign="center">
                                                            <Typography component="div">
                                                                {translate('AbpIdentity::Details')}
                                                            </Typography>
                                                        </Box>

                                                        <Box mt={4}>
                                                            <Grid container spacing={1}>

                                                                {
                                                                    deliveryDefinition.pickupInformation?.orderDetails.map((orderInfo: any) => {


                                                                        let input: any = null;
                                                                        let key : string = '';

                                                                        const found = fields.filter((field: FieldInputTypeDto) => field.name === orderInfo.name);
                                                                        if (found.length > 0) {
                                                                            const fieldName = found[0].name;
                                                                                                                                                        
                                                                            const fieldDefinition = JSON.parse(JSON.stringify(found[0])) as FieldInputTypeDto;
                                                                            fieldDefinition.name = fieldDefinition.name  + `-${PICKUP_PANEL_NAME}`;
                                                                            
                                                                            const extraProps = {
                                                                                placeholder: found[0].label,
                                                                                label: '',
                                                                                elementId: found[0].name+ `-${PICKUP_PANEL_NAME}`,
                                                                                prefix: orderInfo.prefix,
                                                                                inputAdornment: orderInfo.inputAdornment ?
                                                                                    <orderInfo.inputAdornment /> : <></>
                                                                            };
                                                                            
                                                                            if (fieldName === 'customer_name') {
                                                                                fieldDefinition.defaultValue = pickupRecord.contactName ?? '';

                                                                            }
                                                                            if (fieldName === 'modalita_di_consegna') {

                                                                                if (fieldDefinition.type && fieldDefinition.type.picklistValues) {
                                                                                    fieldDefinition.type.picklistValues = fieldDefinition.type.picklistValues;
                                                                                    fieldDefinition.defaultValue = userInput?.deliveryType ?? standardDeliveryType;
                                                                                }
                                                                            }

                                                                            if (fieldName === 'customer_address') {
                                                                                if (typeof pickupRecord?.address !== 'undefined' && typeof addressData === "undefined") {
                                                                                    setAddressData({
                                                                                        label: pickupRecord?.contactAddress,
                                                                                        value: {
                                                                                            description: pickupRecord?.contactAddress,
                                                                                        }
                                                                                    });
                                                                                }
                                                                                return <Grid key={fieldDefinition.name + 'Grid'}
                                                                                    item xs={12}
                                                                                    md={orderInfo.gridSize}>
                                                                                    <Box>
                                                                                        <TextInput
                                                                                            variant="outlined"
                                                                                            key={fieldDefinition.name}
                                                                                            label={''}
                                                                                            placeholder={fieldDefinition.label ?? fieldDefinition.label}
                                                                                            source={fieldDefinition.name}
                                                                                            defaultValue={addressData?.label!}
                                                                                            fullWidth
                                                                                            style={{ display: 'none' }}
                                                                                        />

                                                                                        {displayAddressBox(fieldDefinition?.label?.includes('::') ? translate(fieldDefinition.label) : fieldDefinition.label ?? '')}
                                                                                    </Box>
                                                                                </Grid>

                                                                            }
                                                                            if (fieldName === 'time_until') {

                                                                                let maxTime = setHours(setMinutes(new Date(), maxMinutesInTimeRange), maximumHour);
                                                                                let minTime = timeFromDate ? addHours(timeFromDate, 1) : null;

                                                                                const timeRestrictionObject = timeRestrictionSetter(timeUntilDate, maxTime, minTime);
                                                                                maxTime = timeRestrictionObject.maxTime;
                                                                                minTime = timeRestrictionObject.minTime;
                                                                                const minDate = timeRestrictionObject.minDate;
                                                                                const selectedDate = timeRestrictionObject.selectedDate;
                                                                                if (selectedDate && !areSelectedDataAndCurrentOneEqual(selectedDate, timeUntilDate)) setTimeUntilDate(selectedDate);

                                                                                return (
                                                                                    <Grid key={fieldDefinition.name + 'Grid'} item
                                                                                        xs={12} md={orderInfo.gridSize}
                                                                                        className={orderClasses.responsiveGrid}>
                                                                                        <Box mt={-2}>
                                                                                            <DatePickerInput
                                                                                                fieldName={fieldDefinition.name}
                                                                                                fieldLabel={fieldDefinition.label ?? ''}
                                                                                                selectedDate={selectedDate}
                                                                                                setSelectedDate={(date: any) => {
                                                                                                    setTimeUntilDate(date);
                                                                                                }}
                                                                                                minDateRestriction={minDate}
                                                                                                maxDateRestriction={addDays(new Date(), 1)}
                                                                                                minTimeRestriction={minTime}
                                                                                                maxTimeRestriction={maxTime}
                                                                                            />

                                                                                        </Box>
                                                                                    </Grid>
                                                                                );
                                                                            }

                                                                            if (fieldName === 'startdate') {
                                                                                let maxTime = setHours(setMinutes(new Date(), maxMinutesInTimeRange), maximumHour);
                                                                                const timeRestrictionObject = timeRestrictionSetter(startDate, maxTime, new Date(), initialStartDate);
                                                                                const minTime = timeRestrictionObject.minTime;
                                                                                const minDate = timeRestrictionObject.minDate;
                                                                                maxTime = timeRestrictionObject.maxTime;
                                                                                const selectedDate = timeRestrictionObject.selectedDate;
                                                                                if (selectedDate && !areSelectedDataAndCurrentOneEqual(selectedDate, startDate)) setPickupFromDate(selectedDate);
                                                                                const leftPosition = orderInfo.gridSize === 12 ? '92%' : null;
                                                                                return (
                                                                                    <Grid key={fieldDefinition.name + 'Grid'} item
                                                                                        xs={12} md={orderInfo.gridSize}
                                                                                        className={orderClasses.responsiveGrid}>
                                                                                        <Box mt={-2}>
                                                                                            <DatePickerInput
                                                                                                fieldName={fieldDefinition.name}
                                                                                                fieldLabel={fieldDefinition.label ?? ''}
                                                                                                selectedDate={selectedDate ?? initialStartDate}
                                                                                                setSelectedDate={(date: any) => setPickupFromDate(date)}
                                                                                                minDateRestriction={minDate}
                                                                                                maxDateRestriction={addDays(new Date(), 1)}
                                                                                                minTimeRestriction={minTime}
                                                                                                maxTimeRestriction={maxTime}
                                                                                                leftPosition={leftPosition}
                                                                                                initialDate={initialStartDate}
                                                                                            />

                                                                                        </Box>
                                                                                    </Grid>
                                                                                );
                                                                            }
                                                                            if (fieldName === 'time_from') {

                                                                                let maxTime = setHours(setMinutes(new Date(), maxMinutesInTimeRange), maximumHour);
                                                                                const timeRestrictionObject = timeRestrictionSetter(timeFromDate, maxTime);
                                                                                const minTime = timeRestrictionObject.minTime;
                                                                                maxTime = timeRestrictionObject.maxTime;
                                                                                const minDate = timeRestrictionObject.minDate;
                                                                                const selectedDate = timeRestrictionObject.selectedDate;
                                                                                if (selectedDate && !areSelectedDataAndCurrentOneEqual(selectedDate, timeFromDate)) setTimeFromDate(selectedDate);

                                                                                return (
                                                                                    <Grid key={fieldDefinition.name + 'Grid'} item
                                                                                        xs={12} md={orderInfo.gridSize}
                                                                                        className={orderClasses.responsiveGrid}>
                                                                                        <Box mt={-2}>
                                                                                            <DatePickerInput
                                                                                                fieldName={fieldDefinition.name}
                                                                                                fieldLabel={fieldDefinition.label ?? ''}
                                                                                                selectedDate={selectedDate}
                                                                                                setSelectedDate={(date: any) => {
                                                                                                    setTimeFromDate(date);
                                                                                                    setTimeUntilDate(date);
                                                                                                }}
                                                                                                minDateRestriction={minDate}
                                                                                                maxDateRestriction={addDays(new Date(), 1)}
                                                                                                minTimeRestriction={minTime}
                                                                                                maxTimeRestriction={maxTime}
                                                                                                initialDate={initialStartDate}
                                                                                            />

                                                                                        </Box>
                                                                                    </Grid>
                                                                                );
                                                                            }

                                                                            if (fieldName === 'email_task') {
                                                                                extraProps.label = `${fieldDefinition.label} (opzionale)`;
                                                                                fieldDefinition.defaultValue = pickupRecord.contactEmail ?? '';
                                                                            }

                                                                            if (fieldName === 'phone') {
                                                                                fieldDefinition.defaultValue = pickupRecord.contactPhone ?? '';
                                                                                
                                                                                input = <Box mt={1.5} mb={4.5}
                                                                                    className={'PhoneInputBox'}>
                                                                                    {
                                                                                        FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList, translate)
                                                                                    }
                                                                                </Box>
                                                                                
                                                                                
                                                                            } else {
                                                                                input = FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList, translate);
                                                                            }
                                                                        }

                                                                        return (
                                                                            React.Children.toArray(
                                                                                <>
                                                                                    {input
                                                                                        &&
                                                                                        <Grid key={key + 'Grid'}
                                                                                            item xs={12}
                                                                                            md={orderInfo.gridSize}> <Box
                                                                                                mt={-2}>{input}</Box> </Grid>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        );
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Box>

                                                        <Box my="1rem">
                                                            <Typography align={'center'}>
                                                                {translate('CorbosService::OtherInfo')}
                                                            </Typography>
                                                        </Box>
                                                        <Box mt={4}>
                                                            <Grid container spacing={1}>

                                                                {
                                                                    deliveryDefinition.pickupInformation?.furtherInformationDetails?.map((orderInfo: any) => {

                                                                        let input: any = null;
                                                                        let key : string = '';
                                                                        const found = fields.filter((field: FieldInputTypeDto) => field.name === orderInfo.name);
                                                                        if (found.length > 0) {
                                                                            const fieldName = found[0].name;
                                                                            key = found[0]?.mandatory ? found[0]?.name + 'Grid' + generated : found[0]?.name;
                                                                                                                                                        
                                                                            const fieldDefinition = JSON.parse(JSON.stringify(found[0])) as FieldInputTypeDto;
                                                                            fieldDefinition.name = fieldDefinition.name  + `-${PICKUP_PANEL_NAME}`;
                                                                            
                                                                            const extraProps = {
                                                                                placeholder: found[0].label,
                                                                                label: '',
                                                                                elementId: found[0].name,
                                                                                prefix: orderInfo.prefix,
                                                                                inputAdornment: orderInfo.inputAdornment ?
                                                                                    <orderInfo.inputAdornment /> : <></>
                                                                            };
                                                                            
                                                                            if (fieldName === 'task_notes') {
                                                                                fieldDefinition.defaultValue = pickupRecord.note ?? '';
                                                                            }
                                                                            if (fieldName === 'retailerid') {
                                                                                fieldDefinition.defaultValue = userInput.externalOrderCode ?? '';
                                                                            }
                                                                            if (fieldName === "servizio_di_portineria") {
                                                                                fieldDefinition.defaultValue = pickupRecord.reception ?? false;
                                                                            }
                                                                            if (fieldName === 'citoffono') {
                                                                                fieldDefinition.defaultValue = pickupRecord.interPhone ?? '';
                                                                            }
                                                                            if (fieldName.startsWith('tot_')) {
                                                                                let defValue = 0;
                                                                                if (fieldName === 'tot_small_packages') {
                                                                                    defValue = userInput.totSmallPackages ?? 0;
                                                                                }
                                                                                else if (fieldName === 'tot_medium_packages') {
                                                                                    defValue = userInput.totMediumPackages ?? 0;
    
                                                                                }
                                                                                else if (fieldName === 'tot_large_packages') {
                                                                                    defValue = userInput.totLargePackages ?? 0;
                                                                                }
                                                                                input = displayPackageBox(orderInfo.name, defValue);
                                                                            } else {
                                                                                const extraProps = {
                                                                                    placeholder: found[0].label,
                                                                                    classname: '',
                                                                                    label: '',
                                                                                    prefix: orderInfo.prefix,
                                                                                    inputAdornment: orderInfo.inputAdornment ?
                                                                                        <orderInfo.inputAdornment /> : <></>
                                                                                };
                                                                                // if (found[0].name === 'servizio_di_portineria'){
                                                                                //   extraProps['classname'] = orderClasses.zIndexToggle;
                                                                                // }
                                                                                input = FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList, translate);
                                                                            }


                                                                            // if(found[0].name === 'servizio_di_portineria'){
                                                                            //   input = (
                                                                            //             <Box>
                                                                            //               {/* <Box textAlign="right">Dalle: {servizioDiPortineriaTimeFrom} - Alle: {servizioDiPortineriaTimeTo}</Box> */}
                                                                            //               {input}
                                                                            //
                                                                            //                  <Box mt={-3} mb={3} id="servizioDiPortineriaBox" style={{display: 'none'}}>
                                                                            //
                                                                            //                     <Grid container spacing={1}>
                                                                            //                       <Grid item xs={12} md={6} >
                                                                            //                       <TextInput
                                                                            //                           source={'port_start_break'}
                                                                            //                         name={'servizioDiPortineriaTimeFrom'}
                                                                            //                         fullWidth
                                                                            //                         id="servizioDiPortineriaTimeFrom"
                                                                            //                         label={ translate('translations.fields.from')}
                                                                            //                         placeholder={ translate('translations.fields.from')}
                                                                            //                         type="time"
                                                                            //                         variant="outlined"
                                                                            //                         defaultValue={servizioDiPortineriaTimeFrom}
                                                                            //                         onChange={e => setServizioDiPortineriaTimeFrom(e.target.value)}
                                                                            //                         size="small"
                                                                            //                         inputProps={{
                                                                            //                           step: 300, // 5 min
                                                                            //                         }}
                                                                            //                       />
                                                                            //                         </Grid>
                                                                            //                         <Grid item xs={12} md={6} >
                                                                            //                         <TextInput
                                                                            //                             source={'port_end_break'}
                                                                            //                         name={'servizioDiPortineriaTimeTo'}
                                                                            //                         fullWidth
                                                                            //                         id="servizioDiPortineriaTimeTo"
                                                                            //                         label={ translate('translations.fields.to')}
                                                                            //                         placeholder={ translate('translations.fields.to')}
                                                                            //                         type="time"
                                                                            //                         variant="outlined"
                                                                            //                         defaultValue={servizioDiPortineriaTimeTo}
                                                                            //                         size="small"
                                                                            //                         onChange={e => setServizioDiPortineriaTimeTo(e.target.value)}
                                                                            //                         inputProps={{
                                                                            //                           step: 300, // 5 min
                                                                            //                         }}
                                                                            //                       />
                                                                            //                         </Grid>
                                                                            //                       </Grid>
                                                                            //
                                                                            //                 </Box>
                                                                            //
                                                                            //             </Box>
                                                                            //           )
                                                                            // }

                                                                        }

                                                                        return (
                                                                            React.Children.toArray(
                                                                                <>
                                                                                    {input
                                                                                        &&
                                                                                        <Grid key={key}
                                                                                            item xs={12}
                                                                                            md={orderInfo.gridSize}>
                                                                                            <Box mt={-3}>{input}</Box>
                                                                                        </Grid>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        );
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Box>
                                                    </AccordionDetails>
                                                </div>
                                            </Accordion>

                                            <Accordion square expanded={expanded === DELIVERY_PANEL_NAME}
                                                onChange={handleChange(DELIVERY_PANEL_NAME)} style={{ width: '100%' }}>
                                                <AccordionSummary aria-controls={DELIVERY_PANEL_NAME + '-content'}
                                                    id={DELIVERY_PANEL_NAME + '-header'}>
                                                    <Grid container spacing={0} alignItems="center">
                                                        <Grid item xs={2} md={2}>
                                                            <Box mt={0.4}> {expanded === DELIVERY_PANEL_NAME ?
                                                                <ExpandLessIcon /> : <ExpandMoreIcon />} </Box>
                                                        </Grid>
                                                        <Grid item xs={8} md={8}>
                                                            <Typography
                                                                align={'center'}> {translate('CorbosService::Delivery')} </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <div className={classes.accordionContent}>
                                                    <AccordionDetails>
                                                        <Box py="1rem" mt="-1.4rem" textAlign="center">
                                                            <Typography component="div">
                                                                {translate('AbpIdentity::Details')}
                                                            </Typography>
                                                        </Box>

                                                        <Box mt={4}>
                                                            <Grid container spacing={1}>

                                                                {
                                                                    deliveryDefinition.deliveryInformation?.orderDetails.map((orderInfo: any) => {
                                                                        // console.log('return orderInfo', orderInfo);
                                                                        let input: any = null;
                                                                        let key : string = '';
                                                                        const found = fields.filter((field: FieldInputTypeDto) => field.name === orderInfo.name);

                                                                        if (found.length > 0) {
                                                                            // console.log('return orderInfo', found[0].name);
                                                                            const fieldName = found[0].name;
                                                                            key = found[0]?.mandatory ? found[0]?.name + 'Grid' + generated : found[0]?.name;
                                                                                                                                                        
                                                                            const fieldDefinition = JSON.parse(JSON.stringify(found[0])) as FieldInputTypeDto;
                                                                            fieldDefinition.name = fieldDefinition.name  + `-${DELIVERY_PANEL_NAME}`;
                                                                            
                                                                            let extraProps = {
                                                                                placeholder: found[0].label,
                                                                                label: '',
                                                                                elementId: found[0].name + `-${DELIVERY_PANEL_NAME}`,
                                                                                prefix: orderInfo.prefix,
                                                                                onChange: (event: any) => {},
                                                                                inputAdornment: orderInfo.inputAdornment ?
                                                                                    <orderInfo.inputAdornment /> : <></>
                                                                            };
                                                                            
                                                                            if (fieldName === 'store_id') {
                                                                                // console.log('store_id', fieldDefinition.name);
                                                                                const key = expanded === DELIVERY_PANEL_NAME ? fieldDefinition.name + 'Gridupdate' : fieldDefinition.name + 'Grid'; //change the key to update the values
                                                                                return (
                                                                                    <Grid key={key} item
                                                                                        xs={12} md={orderInfo.gridSize}>
                                                                                        <Box mt={-2} display={'inline-flex'}
                                                                                            width={'100%'}>
                                                                                            <ReferenceInput
                                                                                                variant="outlined"
                                                                                                key={fieldDefinition.name}
                                                                                                label={userInput.storeName ?? fieldDefinition.label}
                                                                                                placeholder={fieldDefinition.label ?? fieldDefinition.label}
                                                                                                source={fieldDefinition.name}
                                                                                                fullWidth
                                                                                                size={'md'}
                                                                                                reference={'ContactRole'}
                                                                                                alwaysOn={true}
                                                                                                filter={filterAllShop ? {} : { id: createdAddressId }}
                                                                                                filterToQuery={searchText =>
                                                                                                    formatSearchObject('name', searchText)
                                                                                                }
                                                                                                validate={required()}
                                                                                            >
                                                                                                <AutocompleteInput
                                                                                                    defaultValue={deliveryRecord.storeId}
                                                                                                    variant="outlined"
                                                                                                    key={'ref' + fieldDefinition.name}
                                                                                                    optionText={'name'}
                                                                                                />
                                                                                            </ReferenceInput>
                                                                                            {(expanded && abpPermissionCheck(permissions, 'ContactRole')) &&
                                                                                                (<IconButton
                                                                                                    className={orderClasses.addAddressButton}
                                                                                                    color={'default'}
                                                                                                    size={"small"}
                                                                                                    onClick={() => {
                                                                                                        setShowAddAddress(true);
                                                                                                        setExpanded('')
                                                                                                    }}>
                                                                                                    <Add />
                                                                                                </IconButton>)
                                                                                            }

                                                                                        </Box>
                                                                                    </Grid>)
                                                                            }
                                                                            // if (fieldName === 'saved_add') {
                                                                            //     const key = expanded === DELIVERY_PANEL_NAME ? fieldDefinition.name + 'Gridupdate' : fieldDefinition.name + 'Grid'; //change the key to update the values

                                                                            //     // fieldDefinition.readonly = true;
                                                                            //     // fieldDefinition.defaultValue = userAccount?.firstname+' '+userAccount?.lastname;
                                                                            //     return (<Grid key={key} item
                                                                            //         xs={12}
                                                                            //         md={orderInfo.gridSize}>
                                                                            //         <Box mt={-2} display={'inline-flex'}
                                                                            //             width={'100%'}>
                                                                            //             <ReferenceInput
                                                                            //                 variant="outlined"
                                                                            //                 key={fieldDefinition.name}
                                                                            //                 label={extraProps.placeholder ?? fieldDefinition.label}
                                                                            //                 placeholder={fieldDefinition.label ?? fieldDefinition.label}
                                                                            //                 source={fieldDefinition.name}
                                                                            //                 fullWidth
                                                                            //                 size={'md'}
                                                                            //                 reference={'ContactRole'}
                                                                            //                 alwaysOn={true}
                                                                            //                 filter={filterAllShop ? {} : { id: createdAddressId }}
                                                                            //                 filterToQuery={searchText =>
                                                                            //                     formatSearchObject('contactrolename', searchText)
                                                                            //                 }
                                                                            //                 validate={required()}
                                                                            //             >
                                                                            //                 <AutocompleteInput
                                                                            //                     defaultValue={createdAddressId}
                                                                            //                     variant="outlined"
                                                                            //                     key={'ref' + fieldDefinition.name}
                                                                            //                     optionText={'contactrolename'}
                                                                            //                 />
                                                                            //             </ReferenceInput>
                                                                            //             {(expanded && abpPermissionCheck(permissions, 'ContactRole')) &&
                                                                            //                 (<IconButton
                                                                            //                     className={orderClasses.addAddressButton}
                                                                            //                     color={'default'}
                                                                            //                     size={"small"}
                                                                            //                     onClick={() => {
                                                                            //                         setShowAddAddress(true);
                                                                            //                         setExpanded('')
                                                                            //                     }}>
                                                                            //                     <Add />
                                                                            //                 </IconButton>)
                                                                            //             }

                                                                            //         </Box>
                                                                            //     </Grid>)
                                                                            // }
                                                                            if (fieldName === 'referente') {
                                                                                fieldDefinition.defaultValue = ((deliveryRecord?.contactName == undefined) ? '' : deliveryRecord?.contactName);
                                                                            }
                                                                            if (fieldName === 'task_notes') {
                                                                                fieldDefinition.defaultValue = deliveryRecord.note ?? '';
                                                                            }
                                                                            if (fieldName === 'email_task') {
                                                                                extraProps.label = `${fieldDefinition.label} (opzionale)`;
                                                                                fieldDefinition.defaultValue = deliveryRecord?.contactEmail ?? '';
                                                                                extraProps.onChange = (event: any) => {
                                                                                    setEmailDelivery(event.target.value);
                                                                                }
                                                                            }
                                                                            
                                                                            if (fieldName === 'phone') {
                                                                                fieldDefinition.defaultValue = deliveryRecord?.contactPhone ?? '';
                                                                                input = <Box mt={1.5} mb={4.5}
                                                                                    className={'PhoneInputBox'}>
                                                                                    {
                                                                                        FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList, translate)
                                                                                    }
                                                                                </Box>
                                                                                // fieldDefinition.defaultValue = undefined;
                                                                            } else {
                                                                                input = FieldInput(fieldDefinition, extraProps, '', describe, assignedUserList, translate);
                                                                            }
                                                                        }

                                                                        return (
                                                                            React.Children.toArray(
                                                                                <>
                                                                                    {input
                                                                                        &&
                                                                                        <Grid key={key + 'Grid'}
                                                                                            item xs={12}
                                                                                            md={orderInfo.gridSize}> <Box
                                                                                                mt={-2}>{input}</Box> </Grid>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        );
                                                                    })
                                                                }
                                                            </Grid>
                                                        </Box>

                                                    </AccordionDetails>
                                                </div>
                                            </Accordion>

                                            <FormDataConsumer>
                                                {({ formData }) => {
                                                    handleFormValuesChange(formData)
                                                    return (<></>);
                                                }}
                                            </FormDataConsumer>

                                        </SimpleForm>
                                    </Create>
                                    <Notification />
                                </Box>
                            </CardContent>
                        </Card>
                    </div>
            }
            {showAddAddres ?
                (<Box className={'modal'}>
                    <div onClick={() => {
                        setShowAddAddress(false);
                        setExpanded(DELIVERY_PANEL_NAME)
                    }} className="overlay"></div>
                    <Box display={'flex'} flexDirection={'column'} className={'modal-content'} p={7.5}
                        style={{ width: '40%' }}>
                        <AddresEdit {...props} resource={'ContactRole'} togglePopUp={(createdAddress?: string) => {
                            setShowAddAddress(false);
                            setExpanded(DELIVERY_PANEL_NAME);
                            if (createdAddress) setCreatedAddressId(createdAddress);
                        }} />
                    </Box>

                </Box>) : ''}
        </>
    );
};
