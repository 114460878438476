import { makeStyles, Theme } from '@material-ui/core/styles';
//import bgImg from '../assets/img-city-transparent.png';

export const useOrderStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    margin: 'auto -25px',
    padding: '7% 0',
    '& .MuiInputBase-input': {
      fontSize: '14px',
    },
    '& tooltip': {
      backgroundColor: 'blue !important'
    },
    '& .RaFormInput-input': {
      width: '100%'
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    }
  },
  showForm:{
    '& div': {
      marginTop: '0'
    }
  },
  actionToolbar: {
    width: '50%',
    margin: 'auto',
  },
  packageBox: {
    background: '#fff',
    border: '1px solid #DCDCDC',
    borderRadius: '5px',
  },
  packageTitleBox: {
    color: '#BDBDBD',
  },
  packageBoxInfo: {
    margin: '0 1px auto auto',
  },
  packageBoxInfoIcon: {
    position: 'relative',
    top: '7px',
    width: '20px', 
    height: '20px'
  },
  packageBoxCounterBtn: {
   cursor: 'pointer',
  },
  packageBoxCounter: {
    margin: '-18px 2px auto 2px',
  },
  orderHeaderBox: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  addAddressButton:{
    position: 'absolute',
    [theme.breakpoints.down("md")]: {
      left: '86%'
    },
    [theme.breakpoints.down("sm")]: {
      left: '90%'
    },
    [theme.breakpoints.down("xs")]: {
      left: '75%'
    },


    left: '88%',
    marginTop: '13px'

  },
  addAddressButtonReverse:{
    position: 'absolute',
    [theme.breakpoints.down("md")]: {
      left: '42%'
    },
    [theme.breakpoints.down("sm")]: {
      left: '90%'
    },
    [theme.breakpoints.down("xs")]: {
      left: '77%'
    },


    left: '44%',
    marginTop: '13px'

  },
  eventOutlineIcon: {
    position: 'absolute',
    display: 'inline',
    padding: '8px',
    top: '-4%',
    color: '#000',
    left: '84%',
    [theme.breakpoints.down("md")]: {
      left: '81%'
    },
    [theme.breakpoints.down("sm")]: {
      left: '93%'
    },
    [theme.breakpoints.down("xs")]: {
      left: '85%'
    },
  },
  responsiveGrid:{
      marginBottom: '15px'
  },
  zIndexToggle:{
    '& .MuiInputLabel-outlined': {
        zIndex: 0
    },
  },
  addAddressButtonShopToShop:{
    position: 'absolute',
    [theme.breakpoints.down("md")]: {
      left: '43%'
    },
    [theme.breakpoints.down("sm")]: {
      left: '90%'
    },
    [theme.breakpoints.down("xs")]: {
      left: '77%'
    },


    left: '44%',
    marginTop: '13px'
  },
  orderStatusBox: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    color: '#fff',
    borderRadius: '5px',
    background: theme.palette.primary.main,
    padding: '18px 25px',
    width: '43%',height: '40px'
  },
  mostraDiPiu: {
   backgroundColor: '#fff',
   fontSize: '14px',
   color: '#2F2F2F',
  },
  orderDetailsBox: {
    border: '1px solid #DCDCDC',
  },
  timeLineBox: {
    /* background: '#F1F1F1', */
    border: '1px solid #DCDCDC',
    overflowY: 'scroll'
  },
  timeLineCard: {
    padding: '15px 16px',
    borderRadius: '10px',
  },
  caneclBtnBox: {
    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
    width: '50%',
    margin: 'auto',
  },
  loadMoreBtnlg: {
    paddingTop: '12px',
    paddingBottom: '12px',
    margin: 'auto',
    height: "40px"
  },
  editTaskBtnLgBox: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    border: '1px solid #939393',
    borderRadius: "5px",
    padding: '18px 7px',
    width: '43%',
    height: '40px'
  },
  statoBtn: {
   background: theme.palette.primary.main,
   color: '#fff',
   
  },
  asideImg: {
   width: '90%',
   margin: 'auto',
  },
  checkedImg: {
    width : '83px',
    height : '83px',
  },
  cancelBox: {
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
        top: '50%',
        transform: 'translateY(-50%)',
        left: '20%',
        width: '60%',
        margin: '10px auto',
    },
    top: '50%',
    transform: 'translateY(-35%)',
    margin: 'auto',
    left: '10%',
    width: '80%',
    border: '2px solid #DCDCDC'
  },
  cancelNoticeBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cancelNoticeButtons : {
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column"
    },
  },
  sidebarTitle :{
    fontSize: "18px",
    fontWeight: 600
  },
  cancelNotice: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    margin:"auto",
  },
  loadMoreBtnHolder: {
    width: '36%',
    margin: 'auto',
  },
}));

