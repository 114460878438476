import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    userLogin,
    useRedirect,
    useLogin,
    useNotify,
    Notification,
    useTranslate, usePermissions,
    useAuthenticated
} from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';


import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { AppButton } from '../components/AppButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import signinAsideImg from '../assets/signinAsideImg.png';
import { Logo } from '../components/Logo';
import { AuthAsideBlock } from './AuthAside';
import { useStyles } from './AuthStyle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { abpPermissionCheck, permissionCheck } from "../utils/Helpers";
import { AbpTenantService } from '../packages/proxy/src/services/AbpTenantService';
import { Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationAuthConfigurationDto } from '../packages/proxy/src';
import useAbp from '../useAbp';

const PARAM_CODE = 'code';
const PARAM_STATE = 'state';

type OidcLoginFormProps = {
    login: (params: any, pathName?: string) => Promise<any>;
};

function Login(props: any) {
    console.log('login page');
    // useAuthenticated();
    const classes = useStyles();

    const translate = useTranslate();
    const redirect = useRedirect();
    const { permissions } = usePermissions<Volo_Abp_AspNetCore_Mvc_ApplicationConfigurations_ApplicationAuthConfigurationDto | undefined>();
    const abp = useAbp();
    const valT = localStorage.getItem('_tenantName');
    // console.log("tenant",valT);
    const [userSpace, setUserSpace] = useState(valT);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [remember, setRemember] = useState(false);
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState('Please wait.')

    // console.log('context abp',abp);

    const login = useLogin();
    const notify = useNotify();


    const onSubmit = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        // abpProvider.getToken(username,password).then( async (result) => {
        //     console.log(result);
        //     console.log('After login');


        //     const _permissions = await authProvider.getPermissions();
        //     permissionCheck(_permissions, 'delivery', 'create') ? redirect('/') : redirect('/delivery') ;
        //     // redirect('/');
        // });
        // login({ username, password, userSpace }).then(async () =>  {
        //     console.log('Login success!');
        //     //const _permissions = await abpProvider.getPermissions();
        //     //const perm = await abpAuthProvider.getPermissions();
        //     //permissionCheck(_permissions, 'delivery', 'create')? redirect('/') : redirect('/delivery') ;
        //     //console.log(permissions);

        // }).catch((reason) => {
        //     notify(translate('AbpAccount::InvalidUserNameOrPassword'), 'warning');
        //     setIsLoading(false);
        // });
    };

    const getUserSpace = () => {
        const storedValue = localStorage.getItem('_tenantName');
        if (storedValue) {
            setUserSpace(storedValue);
        }
        return '';
    }
    const handleRemember = (value: any) => {
        sessionStorage.setItem('remember', value);
        setRemember(value);
    }

    const handleUserSpaceLoad = (event: string) => {
        localStorage.setItem('_tenantName', event);
        // Try to get tenant ID
        AbpTenantService.getApiAbpMultiTenancyTenantsByName(event).then((result) => {
            localStorage.setItem('_tenantId', result.tenantId || '');
        })
        setUserSpace(event);
    };

    const handleUserSpace = (event: string) => {
        localStorage.setItem('_tenantName', event);
        // Try to get tenant ID
        AbpTenantService.getApiAbpMultiTenancyTenantsByName(event).then((result) => {
            localStorage.setItem('_tenantId', result.tenantId || '');
        })
        setUserSpace(event);
    };

    const handleClick = () => {
        setVisible(!visible);
    };

    // useEffect(()=>{
    //     getUserSpace();

    //     if (permissions && permissions.grantedPolicies && Object.keys(permissions.grantedPolicies).length > 0){
    //         console.log('redeirect on login');
    //         const remember = sessionStorage.getItem('remember');
    //         const initialize = sessionStorage.getItem('firstinitialize');
    //         abpPermissionCheck(permissions,'CorbosService.Deliveries.Create') ? redirect('/') : redirect('/delivery');
    //     }

    // },[permissions, redirect])

    useEffect(() => {
        const { searchParams } = new URL(window.location.href);
        const code = searchParams.get(PARAM_CODE);
        const state = searchParams.get(PARAM_STATE);

        // If code is present, we came back from the provider
        if (code && state) {
            login({ code, state }).then(() => redirect(window.location.origin));
        } else {
            setMessage('One moment. You will be redirected to the login page');
            login({}); // Did not provide code, trigger the redirection
        }
    }, [login, redirect]);


    return (
        <div className={classes.root}>
            <Logo />

            <div className={classes.authContainer}>
                <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                        <AuthAsideBlock imgSrc={signinAsideImg} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card className={classes.authCard} variant="outlined">
                            <CardContent>
                                <Typography component="div">
                                    <Box className={classes.authTitle} textAlign="center" mt={0.2} mb={1.2}>
                                        {translate('AbpUi::Login')}
                                    </Box>
                                </Typography>

                                

                                <Notification />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Login;