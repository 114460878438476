import * as React from 'react';
import {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import {
    ListProps,
    List,
    Datagrid,
    useListContext,
    SimpleList,
    Filter,
    Pagination,
    sanitizeListRestProps,
    Responsive,
    useGetIdentity, useTranslate,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import Grid from '@material-ui/core/Grid';
import {getDataFromLocalDb} from '../utils/Helpers';
import {TABLE_DESCRIBE} from '../utils/local-db';
import FieldDisplay from '../utils/FieldDisplay';
import FieldInput from '../utils/FieldInput';
import './styles.css';
import { FieldDisplayUiType } from '../types/FieldDisplayUiType';

const useActionStyles = makeStyles(theme => ({
    root: {
        border: '1px solid #DCDCDC',
        [theme.breakpoints.up("md")]: {
            width: "75%",
        },
        width: "90%",
        '& .MuiTableCell-root': {
            border: '1px solid #930483',
        },
        '& .MuiTableRow-head': {
            border: '1px solid #930483',
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        },
        '& .MuiTableRow-head > th': {
            background: '#fff',
            color: '#000',
            borderRightColor: '#930483',
            paddingTop: '8px',
            paddingBottom: '8px',
        },
        '& .MuiIconButton-root': {
            padding: 0,
            fontSize: '1em',
        },
        '& .MuiSvgIcon-root': {
            width: '1em',
            height: '1em',
            fontSize: '1em',
        },
        /* '& .MuiInputBase-input': {
          height: '6px',
        }, */
        '& .MuiOutlinedInput-root': {
            background: '#fff',
            padding: '0 14px',
            marginTop: '-5px',
        },
        '& .MuiTypography-h6': {
            fontSize: "18px",
        },
        '& .MuiFormLabel-root': {
            fontSize: '14px',
            marginTop: '-5px',
            marginLeft: '8px',
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            marginTop: '-20px !important',
        },
    },
    topBarBox: {
        width: '100%',
    },
    topBarListAction: {
        justifyContent: 'flex-start',
    },
    listActionBtn: {
        padding: '8.5px 5px',
        borderRadius: '5px',
        boxShadow: 'none',
        fontWeight: 'normal'
    },
    RaListMain: {
        margin: '0px -8px',
    },
    filter: {
        width: 200,
        fontSize: '10px Important',
        paddingLeft: `-16px !important`,
        marginRight: `0px !important`,
    }
}));

export const PaymentHistory = (props: ListProps) => {
    const {identity} = useGetIdentity();
    const accountIdValue = `${identity?.accountDetail?.account_id}`;
    const classes = useActionStyles();
    const resource: string = props.resource ?? '';
    const [describe, setDescribe] = useState<any>(null);
    const [assignedUserList, setAssignedUserList] = useState<any[]>([]);
    const [displayFields, setDisplayFields] = useState<any[]>([]);
    const {data, ids, loaded, currentSort, total, selectedIds} = useListContext<any>();
    const [defaultFilter] = useState<any>({
        block: {
            blockid: "209",
            blocksequence: "1",
            blocklabel: "LBL_DELIVERYTASK_INFORMATION",
            blockname: "Delivery Task Information"
        },
        default: "",
        displaytype: "1",
        editable: true,
        label: "Cerca...",
        placeholder: "Cerca...",
        label_raw: "Task ID",
        mandatory: false,
        name: "retailer_id",
        nullable: true,
        quickcreate: false,
        sequence: "1",
        summary: "T",
        type: {name: "string"},
        typeofdata: "V~O",
        uitype: FieldDisplayUiType.Text
    });
    const translate = useTranslate();
    useEffect(() => {
        getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result: any) => {
            const showFields = ['related_id', 'retailer_id', 'amount', 'id_transazione', 'paymentmode', 'copropago_status'];
            const showLabels: any = {
                id: 'WORA ID',
                task_id: 'Retailer ID',
                stato: 'Status',
                customer_name: 'Customer name',
            };
            setDescribe(result);
            let modFields = result[resource]?.fields ?? [];
            const dFields: any[] = [showFields.length];
            modFields.forEach((field: any) => {
                const name: string = field.name;
                if (showFields.includes(name)) {
                    const showField = {
                        ...field,
                        label: showLabels[name] || field.label,
                        index: showFields.indexOf(name)
                    }
                    dFields[showField.index] = showField
                }
            })
            setDisplayFields(dFields);
            const aUserList = result[resource]?.userlist ?? [];
            setAssignedUserList(aUserList);
        });
    }, [resource])

    const ListFilter = (props: any) => {
        return (
            <Filter {...props} >
                {FieldInput(defaultFilter, {
                    placeholder: '',
                    classname: classes.filter,
                    label: translate('translations.search'),
                    inputAdornmentPosition: 'end',
                    inputAdornment: <SearchOutlinedIcon/>,
                    alwaysOn: true
                }, '', describe, assignedUserList)}
            </Filter>
        )
    }

    const ListActions = (props: any) => {
        const {className, exporter, filters, maxResults, ...rest} = props;


        return (
            <Grid container spacing={2} className={className} {...sanitizeListRestProps(rest)}
                  style={{background: '#930483', color: '#fff'}}>
                <Grid item xs={12} md={8} key='pageTitle'>
                    <Box px={2} mt="-0.8rem">
                        <Typography variant="h6"> {translate('translations.CobroPago')}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} style={{margin: 0, padding: 0}}>
                    <Box mt="-2rem">
                        <ListFilter/>
                    </Box>
                </Grid>
            </Grid>
        );
    };

    const CustomPagination = (props: any) => {
        const labelDisplayedRows = React.useCallback(
            () =>
                '',
            []
        );
        return (
            <Pagination rowsPerPageOptions={[]} labelDisplayedRows={labelDisplayedRows} {...props} />
        )
    }

    const RowStyle = () => {
        return (
            {backgroundColor: '#fff',}
        )
    }
    return (
        <Box className={classes.root} style={{border: 0}} mt={15} mx={'auto'}>

            <Box>
                {describe && <List
                    title={'Tutti gli ordini'}
                    bulkActionButtons={false}
                    filterDefaultValues={{cp_relaccount: accountIdValue}}
                    resource={props.resource}
                    perPage={20}
                    pagination={<CustomPagination/>}
                    actions={<ListActions {...props} />}
                    {...props}
                    classes={{main: classes.RaListMain}}
                >

                    <Responsive
                        small={
                            <SimpleList
                                primaryText={record => record.task_id}
                                secondaryText={record => `${record.customer_address}`}
                                tertiaryText={record => record.task_type}
                            />
                        }
                        medium={
                            <Datagrid
                                className="payment__datagrid"
                                basePath={props.basePath}
                                currentSort={currentSort}
                                data={data}
                                ids={ids}
                                selectedIds={selectedIds}
                                loaded={loaded}
                                total={total}
                                rowStyle={RowStyle}
                            >
                                {
                                    displayFields.map((field: any) => {
                                        const fieldDisplay = FieldDisplay(field, describe, assignedUserList);
                                        return fieldDisplay;
                                    })
                                }


                            </Datagrid>
                        }
                    />
                </List>}
            </Box>
        </Box>
    );
};

