import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

export const AuthAsideBlock = ({ imgSrc }: { imgSrc: string }) => {
    const isDesktop:boolean = useMediaQuery((theme:any) => theme.breakpoints.up('md'));
    const useStyles = makeStyles({
        authSideBarImg: {
            display: 'block',
            /* width: isDesktop ? '75%' : '80%', */
            width: isDesktop ? '100%' : '75%',
            height: isDesktop ? '80%' : '100%',
            margin:  isDesktop ? '10%  auto' : 'auto',
        },
    });
    
    const classes = useStyles();

    return (
        <Box alignItems="center">
            <img
                className={classes.authSideBarImg}
                src={imgSrc}
                alt="Welcome WORA Logo"
            />
        </Box>
    )
}