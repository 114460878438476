import * as React from "react";
import {usePaymentStyles} from './PaymentStyle';
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Radio} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Visa from "../assets/cardLogos/visaCardIcon.svg"
import MasterCard from '../assets/cardLogos/logos_mastercard.svg'
import AmericanExpress from '../assets/cardLogos/AmericanExpress.svg'
import Discover from '../assets/cardLogos/Discover.svg'
import UnionPay from '../assets/cardLogos/UnionPay.svg'
import {
    Done,
} from "@material-ui/icons";

export const CardDetails = (props: any) => {

    const paymentClasses = usePaymentStyles();
    const savedCards = props.savedCards;
    const handleRadioClick = (cardId: string) => {
        props.setAddNewPaymentMethod(false);
        props.setSelectedCardId(cardId);
    }
    return (
        <Grid container spacing={2} style={{padding: '10px'}}>
            {savedCards.map((card: any) => {
                let logoSrc;
                switch (card?.card?.brand) {
                    case 'Visa':
                        logoSrc = Visa;
                        break;
                    case 'MasterCard':
                        logoSrc = MasterCard;
                        break;
                    case 'American Express':
                        logoSrc = AmericanExpress;
                        break;
                    case 'Discover & Diners':
                        logoSrc = Discover;
                        break;
                    case 'China UnionPay':
                        logoSrc = UnionPay;
                        break;
                }
                return (
                    React.Children.toArray(
                        <Grid container spacing={2} onClick={() => {
                            handleRadioClick(card?.id)
                        }} className={paymentClasses.cardDetailsPaymentDetailsGridRows}>
                            <Grid item xs={6} md={6}>
                                <Typography align="left" component="div">

                                    <Box display="flex" alignItems={'center'} textAlign="left" my="0.4rem">
                                        <Box display={'flex'} alignItems={'center'}>
                                            <div style={{
                                                backgroundColor: '#fff',
                                                padding: '1px 2px 1px 4px',
                                                borderRadius: '2px',
                                                border: '1px solid #E7E7E7',
                                                marginRight: '5px'
                                            }}>
                                                <img alt={card?.card?.brand} style={{width: '30px', marginRight: '5px'}} src={logoSrc}/>
                                            </div>
                                            <Typography align="left" component="div" variant="body2">
                                                <Box display={'flex'}
                                                     textAlign="right">  {card?.card?.brand + '-' + card?.card?.last4}</Box>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Box textAlign={'right'}>
                                    <Radio checkedIcon={<Done style={{
                                        border: '1px solid',
                                        padding: '2px',
                                        borderRadius: '50%',
                                        fill: '#fff',
                                        backgroundColor: '#2e7d32'
                                    }}/>} style={{color: '#2e7d32'}} checked={props?.selectedCardId === card?.id}
                                           onClick={() => {
                                               handleRadioClick(card?.id)
                                           }} disabled={false}/>
                                </Box>
                            </Grid>
                        </Grid>
                    )
                )


            })}


        </Grid>
    );
};
