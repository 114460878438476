import {
    AutocompleteInput,
    DateTimeInput,
    ImageField,
    ImageInput,
    NumberInput,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    TextInput,
    DateInput,
    required
    
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FormattedBooleanInput, formatSearchObject } from './Helpers';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React from 'react';
import { FieldInputTypeDto } from '../types/FieldInputTypeDto';
import { configOptions } from 'final-form';
import { fi } from 'date-fns/locale';
import { FieldDisplayUiType } from '../types/FieldDisplayUiType';
//import formatRelativeWithOptions from 'date-fns/fp/formatRelativeWithOptions/index';




const FieldInput = (field: FieldInputTypeDto, extraProps: any = null, autogeneratedPlaceholder = '', describe: any = null, assignedUserList: any[] = [], translate: any = null) => {

    // const translate = useTranslate();
    // console.log('FieldInput field', field);
    // console.log('FieldInput extra', extraProps);
    // console.log('FieldInput place', autogeneratedPlaceholder);
    // console.log('FieldInput descr', extraProps);
    // console.log('FieldInput userlist', assignedUserList);

    const label = extraProps?.label?.includes('::') ? translate(extraProps.label) : extraProps?.label;
    const classname = extraProps?.classname;
    const placeholder = extraProps?.placeholder?.includes('::') ? translate(extraProps.placeholder) : extraProps?.placeholder;
    const size = extraProps?.size ?? 'medium';
    const inputAdornment = extraProps?.inputAdornment;
    const prefix = extraProps?.prefix;
    const elementId = extraProps?.elementId ?? field.name;
    const alwaysOn = extraProps?.alwaysOn ?? false;
    const onChange = extraProps?.onChange;
    const inputAdornmentPosition = extraProps?.inputAdornmentPosition ?? 'start';
    let inputAdornmentProp: any = <></>
    if (inputAdornment && (field.uitype !== FieldDisplayUiType.PhoneInput && field.uitype !== FieldDisplayUiType.SelectInput)) {
        inputAdornmentProp = inputAdornmentPosition === 'start' 
            ? { startAdornment: <InputAdornment position={inputAdornmentPosition}> {inputAdornment} {prefix} </InputAdornment> }
            : { endAdornment: <InputAdornment position={inputAdornmentPosition}> {inputAdornment} {prefix} </InputAdornment> }
    }

    const inputDisable = !field.editable;

    let userlist = assignedUserList;
    if (field.editable === false && field.uitype === FieldDisplayUiType.Tipo4) {
        return (
            <TextInput
                variant="outlined"
                key={field.name}
                label={placeholder ?? field.label}
                placeholder={label ?? field.label}
                source={field.name}
                readonly
                disabled
                fullWidth
                className={classname}
                value={autogeneratedPlaceholder}
                size={size}
                alwaysOn={alwaysOn}
                InputProps={
                    inputAdornmentProp
                }
            />
        );
    }

    // if (field.editable === false) {
    //     return null;
    // }

    const isMandatory: any = field.mandatory ? required() : null;

    // console.log('FieldInput mantaory', isMandatory);
    // console.log('FieldInput field before switch', field);
    try {


        switch (Number(field.uitype)) {
            case FieldDisplayUiType.Text:
                return (
                    <TextInput
                        variant="outlined"
                        key={field.name}
                        label={placeholder ?? field.label}
                        placeholder={label ?? field.label}
                        source={field.name}
                        disabled={inputDisable}
                        defaultValue={field.defaultValue}
                        validate={isMandatory}
                        fullWidth
                        className={classname}
                        size={size}
                        alwaysOn={alwaysOn}
                        InputProps={
                            inputAdornmentProp
                        }
                    />
                )
            case FieldDisplayUiType.Date:
                return (
                    <DateInput
                        variant="outlined"
                        key={field.name}
                        label={field.label}
                        source={field.name}
                        defaultValue={field.defaultValue}
                        validate={isMandatory}
                        fullWidth
                        className={classname}
                        disabled={inputDisable}
                        size={size}
                        alwaysOn={alwaysOn}
                        InputProps={
                            inputAdornmentProp
                        }
                    />
                )
            case FieldDisplayUiType.ModuleRelation:
                if (field.type !== undefined && field.type.refersTo != undefined && field.type.refersTo.length === 0) {
                    return null;
                }

                if (field.readonly && field.defaultValue) {
                    return <TextInput
                        variant="outlined"
                        key={field.name}
                        label={placeholder ?? field.label}
                        placeholder={label ?? field.label}
                        source={field.name}
                        defaultValue={field.defaultValue}
                        disabled={inputDisable}
                        fullWidth
                        className={classname}
                        size={size}
                        alwaysOn={alwaysOn}
                        InputProps={
                            inputAdornmentProp
                        }
                    />
                }
                if (field.type?.refersTo == undefined) {
                    return null;
                }
                if (!describe || !describe[field.type.refersTo[0]]) {
                    return null
                }
                const refmod = describe[field.type.refersTo[0]] ?? {};
                let labelFields = refmod.labelFields

                if (labelFields && labelFields.indexOf(',') > -1) {
                    labelFields = labelFields.split(',')[0]
                }

                return (
                    <ReferenceInput
                        variant="outlined"
                        key={field.name}
                        label={placeholder ?? field.label}
                        placeholder={label ?? field.label}
                        source={field.name}
                        fullWidth
                        className={classname}
                        size={size}
                        disabled={inputDisable}
                        reference={refmod.name}
                        alwaysOn={alwaysOn}
                        filterToQuery={searchText =>
                            formatSearchObject(labelFields, searchText)
                        }
                        InputProps={
                            inputAdornmentProp
                        }
                        validate={isMandatory}>
                        <AutocompleteInput
                            variant="outlined"
                            key={'ref' + field.name}
                            optionText={labelFields}
                        />
                    </ReferenceInput>
                )
            case FieldDisplayUiType.PhoneInput:
                return (
                    <PhoneInput
                        country={'it'}
                        inputStyle={{
                            width: "100%",
                        }}
                        disabled={inputDisable}
                        enableSearch={true}
                        key={field.name}
                        inputClass={'PhoneInput'}
                        inputProps={{
                            id: elementId,
                            name: field.name,
                            source: field.name
                        }}
                        value={field.defaultValue?.toString()}
                        placeholder={field.label}
                    />
                )
            case FieldDisplayUiType.Email:
                return (
                    <TextInput
                        variant="outlined"
                        key={field.name}
                        label={placeholder ?? field.label}
                        placeholder={label ?? field.label}
                        source={field.name}
                        disabled={inputDisable}
                        onChange={onChange}
                        type="email"
                        className={classname}
                        defaultValue={field.defaultValue}
                        validate={isMandatory}
                        fullWidth
                        size={size}
                        alwaysOn={alwaysOn}
                        InputProps={
                            inputAdornmentProp
                        }
                    />
                )
            case FieldDisplayUiType.SelectInput:
                return (
                    <SelectInput
                        variant="outlined"
                        key={field.name}
                        validate={isMandatory}
                        label={field.label}
                        source={field.name}
                        choices={field.type?.picklistValues}
                        optionText="label"
                        optionValue="value"
                        className={classname}
                        defaultValue={field?.defaultValue}
                        allowEmpty={false}
                        fullWidth
                        size={size}
                        disabled={inputDisable}
                        alwaysOn={alwaysOn}
                        // inputProps={
                        //     { ...inputAdornmentProp, ...(inputDisable && { IconComponent: () => null }) }
                        // }
                        InputProps={
                            inputAdornmentProp
                        }
                    />
                )
            case FieldDisplayUiType.Url:
                return (
                    <TextInput
                        variant="outlined"
                        key={field.name}
                        label={placeholder ?? field.label}
                        placeholder={label ?? field.label}
                        source={field.name}
                        type="url"
                        disabled={inputDisable}
                        className={classname}
                        defaultValue={field.defaultValue}
                        validate={isMandatory}
                        fullWidth
                        size={size}
                        alwaysOn={alwaysOn}
                        InputProps={
                            inputAdornmentProp
                        }
                    />
                )
            case FieldDisplayUiType.BigText:
                return (
                    <RichTextInput
                        variant="outlined"
                        key={field.name}
                        label={placeholder ?? field.label}
                        placeholder={label ?? field.label}
                        source={field.name}
                        defaultValue={field.defaultValue}
                        validate={isMandatory}
                        fullWidth
                        size={size}
                        disabled={inputDisable}
                        className={classname}
                        InputProps={
                            inputAdornmentProp
                        }
                    />
                )
            case FieldDisplayUiType.TextArea:
                return (
                    <TextInput
                        variant="outlined"
                        key={field.name}
                        label={placeholder ?? field.label}
                        placeholder={label ?? field.label}
                        source={field.name}
                        defaultValue={field.defaultValue}
                        validate={isMandatory}
                        fullWidth
                        multiline
                        minRows={3}
                        size={size}
                        disabled={inputDisable}
                        className={classname}
                        alwaysOn={alwaysOn}
                        InputProps={
                            inputAdornmentProp
                        }
                    />

                );
            case FieldDisplayUiType.DateTime:
                return (
                    <DateTimeInput
                        variant="outlined"
                        key={field.name}
                        label={field.label}
                        source={field.name}
                        validate={isMandatory}
                        defaultValue={field.defaultValue}
                        fullWidth
                        className={classname}
                        disabled={inputDisable}
                        size={size}
                        alwaysOn={alwaysOn}
                        InputProps={
                            inputAdornmentProp
                        }
                    />
                )
            case FieldDisplayUiType.UserRelationAssigner:
                return (
                    <ReferenceInput
                        variant="outlined"
                        key={field.name}
                        label={field.label}
                        source={field.name}
                        reference="Users"
                        fullWidth
                        alwaysOn={alwaysOn}
                        className={classname}
                        validate={isMandatory}>
                        <SelectInput
                            variant="outlined"
                            key={'ref' + field.name}
                            optionText={(user: any) => `${user.first_name} ${user.last_name}`}
                            fullWidth
                            size={size}
                            disabled={inputDisable}
                        /*  InputProps={
                             inputAdornmentProp
                         } */
                        />
                    </ReferenceInput>
                )
            case FieldDisplayUiType.CheckBox:
                return (
                    <FormattedBooleanInput
                        key={field.name}
                        label={placeholder ?? field.label}
                        placeholder={label ?? field.label}
                        source={field.name}
                        defaultValue={field.defaultValue}
                        fullWidth
                        size={size}
                        className={classname}
                        alwaysOn={alwaysOn}
                        disabled={inputDisable}
                        InputProps={
                            inputAdornmentProp
                        }
                    />
                )
            case FieldDisplayUiType.Image:
                return (
                    <ImageInput
                        disabled={inputDisable}
                        key={field.name}
                        label={field.label}
                        placeholder={field.placeholder}
                        source={field.name}
                        defaultValue={field.defaultValue}
                        multiple={extraProps?.multiple}
                        labelSingle={extraProps?.labelSingle}
                        labelMultiple={extraProps?.labelMultiple}
                        accept="image/*">
                        <ImageField key={'ref' + field.name} source={field.name} />
                    </ImageInput>
                )
            case FieldDisplayUiType.CreatedModifiedTime:
                return null
            case FieldDisplayUiType.Currency:
                return (
                    <NumberInput
                        variant="outlined"
                        key={field.name}
                        label={placeholder ?? field.label}
                        placeholder={label ?? field.label}
                        source={field.name}
                        validate={isMandatory}
                        defaultValue={field.defaultValue}
                        fullWidth
                        disabled={inputDisable}
                        className={classname}
                        size={size}
                        alwaysOn={alwaysOn}
                        InputProps={
                            inputAdornmentProp
                        }
                    />
                )
            case FieldDisplayUiType.SelectUser:
                if (field.type?.refersTo == undefined) {
                    return null;
                }
                if (!describe || !describe[field.type?.refersTo[0]]) {
                    return null;
                }
                //userlist = describe && describe[field.type.refersTo[0]] ? describe[field.type.refersTo[0]].userlist : []
                return (
                    <SelectInput
                        key={field.name}
                        label={field.label}
                        source={field.name}
                        choices={userlist}
                        optionText="username"
                        optionValue="userid"
                        defaultValue={field.defaultValue}
                        placeholder={field.label}
                        fullWidth
                        disabled={inputDisable}
                        size={size}
                        className={classname}
                        alwaysOn={alwaysOn}
                    /*  InputProps={
                         inputAdornmentProp
                     } */
                    />
                )
            case FieldDisplayUiType.SelectRolesInput:
                return (
                    <SelectInput
                        variant="outlined"
                        disabled={inputDisable}
                        key={field.name}
                        label={field.label}
                        source={field.name}
                        choices={field.type?.picklistValues}
                        optionText="value"
                        optionValue="label"
                        defaultValue={field.defaultValue}
                        validate={isMandatory}
                        fullWidth
                        size={size}
                        alwaysOn={alwaysOn}
                    /* InputProps={
                        inputAdornmentProp
                    } */
                    />
                )
            case FieldDisplayUiType.SelectMultiple:
                return (
                    <SelectArrayInput
                        variant="outlined"
                        key={field.name}
                        disabled={inputDisable}
                        label={placeholder ?? field.label}
                        placeholder={label ?? field.label}
                        source={field.name}
                        choices={field.type?.picklistValues}
                        optionText="value"
                        optionValue="label"
                        defaultValue={field.defaultValue}
                        validate={isMandatory}
                        fullWidth
                        size={size}
                        alwaysOn={alwaysOn}
                        InputProps={
                            inputAdornmentProp
                        }
                    />
                );
            default:
                if(field.uitype !== 0){
                    console.warn(`Use TextInput -> FieldInput: FieldDisplayUiType not found for ${field.name} with uitype: ${field.uitype}`);
                }

                return (
                    <TextInput
                        variant="outlined"
                        key={field.name}
                        label={placeholder ?? field.label}
                        placeholder={label ?? field.label}
                        source={field.name}
                        disabled={inputDisable}
                        defaultValue={field.defaultValue}
                        validate={isMandatory}
                        fullWidth
                        className={classname}
                        size={size}
                        alwaysOn={alwaysOn}
                        InputProps={
                            inputAdornmentProp
                        }
                    />
                );
        }
    } catch (e) {
        console.error('FieldInput field before swithc', e);
    }
};


export default FieldInput;