import * as React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import {
    ListProps,
    List,
    Datagrid,
    useListContext,
    SimpleList,
    Filter,
    Pagination,
    sanitizeListRestProps,
    Responsive, useTranslate, usePermissions,
    useGetIdentity,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import Grid from '@material-ui/core/Grid';
import {getDataFromLocalDb, permissionCheck} from '../utils/Helpers';
import { TABLE_DESCRIBE } from '../utils/local-db';
import FieldDisplay from '../utils/FieldDisplay';
import FieldInput from '../utils/FieldInput';
import { usePageStyles } from './AddressStyle';
import AzioneField from "../components/AzioneField";
import AddresEdit from "./AddresEdit";




export const AddressList = (props: ListProps) => {

    const classes = usePageStyles();
    const resource: string = props.resource??'';
    const [describe, setDescribe] = useState<any>(null);
    const [assignedUserList, setAssignedUserList] = useState<any[]>([]);
    const [displayFields, setDisplayFields] = useState<any[]>([]);
    const { data, ids, loaded, currentSort, total, selectedIds } = useListContext<any>();
    const [showAddAddres, setShowAddAddres] = useState<boolean>(false);
    const [id, setId] = useState<string>();
    const translate = useTranslate();
    const [defaultFilter,setDefaultFilter] = useState<any>({});
    const { permissions } = usePermissions();
    const {identity} = useGetIdentity();
    const userdata = identity && identity.userdata;
    const accountDetail = identity && identity.accountDetail;
    const contactDetail = identity && identity.contactDetail;

    const accountId = accountDetail && accountDetail.account_id;
    const shopId = contactDetail && contactDetail.shopname;

    let queryParam = {};

    if(userdata){
      if(userdata.is_admin === 'on' || userdata.rolename === 'Manager'){
        queryParam = {account: accountId}
      } else {
        queryParam = {id: shopId};
      }
    } else {
      queryParam = {id: shopId};
    }
    


    useEffect(() => {
      getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result: any) => {
        const showFields = ['firstName', 'address'];
        setDescribe(result);
        let modFields = [{
          "name": "firstName",
          "label": "firstName",
          "label_raw": "firstName",
          "mandatory": false,
          "type": {
              "name": "string"
          },
          "nullable": true,
          "editable": true,
          "uitype": "21",
      },
      {
        "name": "address",
        "label": "address",
        "label_raw": "address",
        "mandatory": false,
        "type": {
            "name": "string"
        },
        "nullable": true,
        "editable": true,
        "uitype": "21",
    },];
        const labelFields = '';
        const dFields: any[] = [];
        modFields.forEach((field: any) => {
          if(showFields.includes(field.name)){
            dFields.push(field);
          }
          if (field.name === labelFields){
              setDefaultFilter(field);
          }
        })
        setDisplayFields(dFields);
        const aUserList = result[resource]?.userlist ?? [];
        setAssignedUserList(aUserList);
      });
    }, [resource])

    const showEditAddres = (id:string) => {
        setId(id);

      setShowAddAddres(true);
    }

    const ListFilter = (props: any) => {
        defaultFilter.uitype = 1;
        defaultFilter.mandatory = false;
      return (
        <Filter {...props} >
            {FieldInput(defaultFilter, { placeholder: '', label: translate('translations.search'), inputAdornmentPosition: 'end', inputAdornment: <SearchOutlinedIcon />, alwaysOn: true}, '' , describe, assignedUserList)}
        </Filter>
      )
    }

    const ListActions = (props: any) => {
      const { className, exporter, filters, maxResults, ...rest } = props;

      return (
        <Grid container spacing={5} className={className} {...sanitizeListRestProps(rest)} style={{background: '#fff'}}>
          <Grid item xs={12} md={12} style={{margin: '5px auto', padding: 0}}>
              <Grid style={{justifyContent: permissionCheck(permissions, resource, 'create') ?"space-between" : 'center'  }} container>
                  {permissionCheck(permissions, resource, 'create') && <Grid item xs={12} md={5}>
                  <Box mt={1.4} className = {classes.listActionBtnContainer}>
                    <button
                        className={classes.createBtn }
                        onClick={()=>{
                           if (permissionCheck(permissions, resource, 'create')) setShowAddAddres(!showAddAddres)
                        }}
                      >
                        {translate('translations.AddAddress')}
                    </button>

                  </Box>
                </Grid>}
                <Grid item xs={12} md={5}>
                  <Box className = {classes.listActionBtnContainer}> <ListFilter /> </Box>
                </Grid>
              </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box mt={-6} px={2}>
              <Typography component="div" variant="h6">
                <Box fontWeight="fontWeightBold" textAlign="center" py="1.5rem">
                    {translate('translations.shop_saved')}
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    };

    const CustomPagination = (props: any) => {
      const labelDisplayedRows = React.useCallback(
          () =>
              '',
          []
      );
      return (
        <Pagination  rowsPerPageOptions={[]} labelDisplayedRows={labelDisplayedRows} {...props} />
      )
    }

    const RowStyle = () => {
      return (
        {backgroundColor: '#FDFDFD',}
      )
    }
    const checkForCreate = React.useCallback(()=>{
      if(typeof permissions === 'undefined' || !permissionCheck(permissions, resource, 'create')) return false
       return undefined;
    }, [permissions, resource])

    useEffect(() => {
      checkForCreate()
    },[checkForCreate, permissions])

    return (
      <Box className={classes.root} mt={15} mx={'auto'} mb={10}>
        <Box py={5} px={5} mb={2}>
            <List
            title={'Tutti gli ordini'}
            empty = {checkForCreate()}
            filterDefaultValues={queryParam}
            bulkActionButtons={false}
            resource={props.resource}
            perPage={10}
            pagination={<CustomPagination />}
            actions={<ListActions {...props} />}
            {...props}
            classes={{main: classes.RaListMain}}
          >

            <Responsive
                small={
                    <SimpleList
                      primaryText={record => record.task_id}
                      secondaryText={record => `${record.customer_address}`}
                      tertiaryText={record => record.task_type}
                    />
                }
                medium={
                  <Datagrid
                  basePath={props.basePath}
                  currentSort={currentSort}
                  data={data}
                  ids={ids}
                  selectedIds={selectedIds}
                  loaded={loaded}
                  total={total}
                  rowStyle={RowStyle}
                >

                {
                  displayFields.map((field: any) => {
                    const fieldDisplay = FieldDisplay(field, describe, assignedUserList);
                    return  fieldDisplay;
                  })
                }
                      {
                          (permissionCheck(permissions, resource, 'update') ||
                          permissionCheck(permissions, resource, 'delete') ) &&
                          <AzioneField {...props} toggleExpand={showEditAddres}  />
                      }

                </Datagrid>
                }
            />            
          </List>
        </Box>
          {showAddAddres ?
              (<Box className={'modal'}>
                  <div onClick={()=>{setShowAddAddres(false); setId('') }}  className="overlay"></div>
                      <Box display={'flex'} flexDirection={'column'} className = {'modal-content'} p={5.5} style={{width: '40%'}}>
                          <AddresEdit {...props} id = {id} togglePopUp = {()=>{setShowAddAddres(false)}}/>
                      </Box>

              </Box>): ''}
      </Box>
    );
};

