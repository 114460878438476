import React from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import tokenExpiredSvg from "../assets/svgIcons/tokenxpired.svg"
import {AppButton} from "../components/AppButton";
import {useOrderStyles} from "../order/OrderStyle";
import {Notification, useNotify, useRedirect, useTranslate} from "react-admin";
import {doInvoke} from "../utils/lib";

const TokenError = (props:any) => {
    const orderClasses = useOrderStyles();
    const redirect = useRedirect();
    const notify = useNotify();
    const translate = useTranslate();
    const generateToken = () => {
        const changePasswordParams = {
            method: "check_user_email",
            data: `[{\"email\": \"${props.email}\"}]` // eslint-disable-line
        }
        doInvoke(
            'ForgotPassword',
            changePasswordParams,
            'POST'
        ).then(
            () => {
                //password will be changed
                notify(translate('translations.reset_password_email'), "success");
                setTimeout(()=>{
                    redirect('/login');
                }, 5000)
            },
            () => {
                notify(translate('translations.email_does_not_exist'), "error");
            })
    }
    return (
        <>
        <Box p={2} className = {orderClasses.cancelBox}>
            <Grid container spacing={2} className={orderClasses.cancelNotice}>
                <Grid item xs={12} style = {{width: "100%"}}>
                    <Box py={5}>
                        <Grid item xs={12} md={6} style = {{margin: "auto"}}>
                            <Box py={2} display = 'flex' justifyContent = 'center' style={{marginLeft: '-45px'}}>
                                <img
                                    src={tokenExpiredSvg}
                                    alt="Aside Information"
                                />
                            </Box>
                        </Grid>
                        <Box mx={1}>
                            <Typography component="div" variant="h6">
                                <Box textAlign = "center"> Il link di reimpostazione è scaduto </Box>
                                <Box textAlign = "center"> Si prega di riprovare la richiesta.</Box>
                            </Typography>
                        </Box>
                        <Box mt={2} className = {orderClasses.cancelNoticeButtons} justifyContent = 'space-evenly' py={2} sizeWidth = "100%"  display="flex" mx = {'auto'}>
                            <Box my = {1} mx = {1}>
                                <AppButton onClick = {() => {redirect('/login')}}  fullWidth variant="contained" size="large" style={{padding: '12px 60px', boxShadow: "none", backgroundColor: "#FFC300"}}>
                                    Torna al Login
                                </AppButton>
                            </Box>
                            <Box my = {1} mx = {1} >
                                <AppButton onClick={()=>{generateToken()}} fullWidth variant="contained" color="primary" size="large"  style={{padding: '12px 60px', boxShadow: "none"}}>
                                    Invia di nuovo
                                </AppButton>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

        </Box>
            <Notification />
        </>
    );
};

export default TokenError;