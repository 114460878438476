
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        overflow: 'hidden',
        width: 'auto',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            padding: '30px 60px',
        },
        padding: '5px 20px',
        backgroundColor: theme.palette.background.paper,
        '& .MuiFormLabel-root': {
            opacity: '1 !important',
            color: '#BDBDBD',
        },
        '& .MuiTextField-root': {
            margin: '10px auto',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#DCDCDC',
        },
        '& .MuiOutlinedInput-inputMarginDense': {
            padding: '10px 14px 12px 14px',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#DCDCDC',
            },
            '&:hover fieldset': {
              borderColor: '#DCDCDC',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#DCDCDC',
            },
        },
        '& .MuiTypography-body1': {
            fontSize: '12px'
        },
        '& .MuiBox-root': {
            width: '100%',
        },
    },
    authSinglePageContainer: {
        [theme.breakpoints.up('md')]: {
            margin: '0',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
        },
        margin: '25px auto',
    },
    authContainer: {
        position: 'absolute',
        [theme.breakpoints.up('md')]: {
            top: '50%',
            transform: 'translateY(-50%)',
            left: '15%',
            width: '70%',
            margin: '10px auto',
        },
        [theme.breakpoints.down('sm')]: {
           paddingTop: '6em'
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0
        },
        top: '50%',
        transform: 'translateY(-35%)',
        margin: 'auto',
        left: '10%',
        width: '80%',
    },
    redirectLabel : {
        fontSize: '14px',
        '&:hover':{
            textDecoration: "underline",
            cursor: "pointer"
        },

    },
    authSinglePageCard: {
        position: 'absolute',
        borderRadius: '5px',
        borderColor: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
            top: '50%',
            transform: 'translateY(-50%)',
            left: '25%',
            width: '50%',
        },
        '& .MuiCardContent-root': {
            [theme.breakpoints.up('md')]: {
                padding: '20px 60px',
            },
            padding: '20px 30px',
        },
        top: '50%',
        transform: 'translateY(-40%)',
        margin: 'auto',
        left: '10%',
        width: '80%',
    },
    authCardReg: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            width: '56%',
        },
        borderRadius: '5px',
        borderColor: theme.palette.secondary.main,
        width: 'auto',
        margin: '12px auto',
        padding: '2px 10px',
    },
    authCard: {
        display: 'block',
        borderRadius: '5px',
        borderColor: theme.palette.secondary.main,
        width: 'auto',
        [theme.breakpoints.up('md')]: {
            margin: '12px 20px',
        },
        margin: '12px 10px',
        padding: '2px 12px',
    },
    authTitle: {
        fontSize: '20px'
    },
    authSubmitBtn: {
        boxShadow: 'none',
        padding: '7px 22px',
    },
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginBottom: 50,
    },
    chPassword : {
        fontSize: "25px",
        fontWeight: 600,
        textAlign: "center"
    },
    chPasswordone : {
        fontSize: "20px",
        fontWeight: 600,
        textAlign: "center",
        marginTop: 50,
        marginBottom: 10
    },
    chPasswordtwo : {
        fontSize: "16px",
        fontWeight: 400,
        textAlign: "center"
    },
    chPasswordthree : {
        fontSize: "16px",
        fontWeight: 400,
        marginBottom: 10,
        textAlign: "center"
    },
    formLabels : {
        fontSize: "14px",
        fontWeight: 600
    },
    savePasswordBtn: {
        border:"0",
        borderRadius:"5px",
        width: "100%",
        boxShadow: 'none',
        padding: '10px 22px',
        backgroundColor: "#57b894",
        fontSize: "17px",
        fontWeight: "bolder",
        color: "#fff",
        transition: "0.3s",
        '&:hover':{
            backgroundColor: "#327e62",
            cursor: "pointer"
        }
    },
    passRules: {
        fontSize: '14px',
    },
    btnPrimary2 : {
        background: '#ffc300 !important',
        color: '#000 !important',
        borderRadius: '5px !important',
        boxShadow: 'none',
        '&:hover':{
            background: '#deaa00 !important'
        }
    }
}));