import * as React from "react";
import { usePaymentStyles } from './PaymentStyle';
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import boxSvg from "../assets/svgIcons/box.svg"


import Grid from "@material-ui/core/Grid";
import {useTranslate} from "react-admin";
import { BankTransferPaymentMethod } from "../constant";

export const PaymentDetails = (props: any) => {

  const paymentClasses = usePaymentStyles();
  const paymentMethod = props.paymentMethod;
  const translate = useTranslate();

  return (
    <Grid container spacing={2}>
            <Grid container spacing={2} className={paymentClasses.paymentDetailsGridRows}>
                <Grid item xs={6} md={6}>
                <Typography align="left" component="div" variant="body2">
                    
                        <Box py={0.5} fontSize="small" textAlign="left">
                        <div className={paymentClasses.boxImgContainer}>
                            <img src = {boxSvg} alt = {'boxSvg'} className = {paymentClasses.boxImg} width = "22px" height = "22px"/>
                        </div>
                            {(paymentMethod !== BankTransferPaymentMethod) && props?.packages[2]?.count} {translate('CorbosService::TotLargePackages')}
                        </Box>
                </Typography>
                    <Typography align="left" component="div" variant="body2">
                        <Box py={0.5} fontSize="small" textAlign="left">
                            <div className={paymentClasses.boxImgContainer}>
                                <img alt = {'boxSvg'} src = {boxSvg} className = {paymentClasses.boxImg} width = "18px" height = "18px"/>
                            </div>
                            {(paymentMethod !== BankTransferPaymentMethod) && props?.packages[0]?.count} {translate('CorbosService::TotMediumPackages')}
                        </Box>
                    </Typography>
                    <Typography align="left" component="div" variant="body2">
                        <Box py={0.5} fontSize="small" textAlign="left">
                            <div className={paymentClasses.boxImgContainer}>
                                <img alt = {'boxSvg'} src = {boxSvg} className = {paymentClasses.boxImg} width = "15px" height = "15px"/>
                            </div>
                            {(paymentMethod !== BankTransferPaymentMethod) && props?.packages[1]?.count} {translate("CorbosService::TotSmallPackages")}</Box>
                    </Typography>
                    {
                        paymentMethod !== BankTransferPaymentMethod && (
                            <Typography align="left" component="div" variant="body2">
                                <Box py={0.5} fontSize="small" textAlign="left">{translate('translations.subtotal')}</Box>
                            </Typography>
                        )
                    }
                    {
                        paymentMethod !== BankTransferPaymentMethod && (
                            <Typography align="left" component="div" variant="body2">
                                <Box py={0.5} fontSize="small" textAlign="left">IVA({ props?.paymentinfo?.ivaPercentage } %)</Box>
                            </Typography>
                        )
                    }
                </Grid>
                <Grid item xs={6} md={6}>
                <Typography align="left" component="div" variant="body2">
                    <Box py={0.5} fontSize="small" textAlign="right">{paymentMethod !== BankTransferPaymentMethod ? '€ ' + Math.round(((props?.packages[2]?.unitPrice * props?.packages[2]?.count ) + Number.EPSILON) * 100) / 100: props?.packages[2]?.count } </Box>
                </Typography>
                <Typography align="left" component="div" variant="body2">
                    <Box py={0.5} fontSize="small" textAlign="right">{paymentMethod !== BankTransferPaymentMethod ? '€ ' + Math.round(((props?.packages[0]?.unitPrice * props?.packages[0]?.count ) + Number.EPSILON) * 100) / 100: props?.packages[0]?.count } </Box>
                </Typography>
                <Typography align="left" component="div" variant="body2">
                    <Box py={0.5} fontSize="small" textAlign="right">{paymentMethod !== BankTransferPaymentMethod ? '€ ' + Math.round(((props?.packages[1]?.unitPrice * props?.packages[1]?.count ) + Number.EPSILON) * 100) / 100: props?.packages[1]?.count }</Box>
                </Typography>
                    {
                        paymentMethod !== BankTransferPaymentMethod && (
                            <Typography align="left" component="div" variant="body2">
                                <Box py={0.5} fontSize="small" textAlign="right">€ {Math.round(((props?.paymentinfo?.subtotal) + Number.EPSILON) * 100) / 100 } </Box>
                            </Typography>)
                    }
                    {
                        paymentMethod !== BankTransferPaymentMethod && (
                            <Typography align="left" component="div" variant="body2">
                                <Box py={0.5} fontSize="small" textAlign="right">€ {Math.round(((props?.paymentinfo?.ivaValue) + Number.EPSILON) * 100) / 100 } </Box>
                            </Typography>)
                    }
                </Grid>
            </Grid>
        <Grid container spacing={2} className={paymentClasses.paymentDetailsGridRows}>
            <Grid item xs={6} md={6}>
                <Typography align="left" component="div" variant="body2">
                    <Box py={0.5} fontSize="small" textAlign="left">{paymentMethod !== BankTransferPaymentMethod ? translate('translations.total_amount') : translate('CorbosService::Total_packages')}</Box>
                </Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <Typography align="left" component="div" variant="body2">
                    <Box py={0.5} fontSize="small" textAlign="right"> { paymentMethod !== BankTransferPaymentMethod ? '€' +Math.round(((props?.paymentinfo?.total) + Number.EPSILON) * 100) / 100   : props?.packages[1]?.count + props?.packages[0]?.count + props?.packages[2]?.count } </Box>
                </Typography>
            </Grid>
        </Grid>
    </Grid>
  );
};
