import React from 'react';
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Card } from '@material-ui/core';



const StripeContainer = (props:any) => {

    const { stripeKey } = props;
    const stripePromise = loadStripe(stripeKey ??'');

    return (
        <Card>
            <Elements stripe={stripePromise}>
                <CheckoutForm salesOrderRecord = {props.salesOrderRecord}  setShowPaymentMessage = {props.setShowPaymentMessage}  {...props} />
            </Elements>
        </Card>
    );
};

export default StripeContainer;