/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Dm_Filter_Application_Contracts_Filters_FilterMapDto } from '../models/Dm_Filter_Application_Contracts_Filters_FilterMapDto';
import type { Dm_Filter_Application_Contracts_Filters_TelerikFilterDto } from '../models/Dm_Filter_Application_Contracts_Filters_TelerikFilterDto';
import type { Volo_Abp_Application_Dtos_ListResultDto_1 } from '../models/Volo_Abp_Application_Dtos_ListResultDto_1';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1 } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1';
import type { wora_CorbosService_Deliveries_DeliveryStatus } from '../models/wora_CorbosService_Deliveries_DeliveryStatus';
import type { wora_CorbosService_Deliveries_DeliveryType } from '../models/wora_CorbosService_Deliveries_DeliveryType';
import type { wora_CorbosService_Deliveries_OrderType } from '../models/wora_CorbosService_Deliveries_OrderType';
import type { wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryCreateDto } from '../models/wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryCreateDto';
import type { wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto } from '../models/wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto';
import type { wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryUpdateDto } from '../models/wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryUpdateDto';
import type { wora_CorbosService_Deliveries_ShopPortal_ShopGetDeliveriesInput } from '../models/wora_CorbosService_Deliveries_ShopPortal_ShopGetDeliveriesInput';
import type { wora_CorbosService_DeliveryHistories_DeliveryHistoryDto } from '../models/wora_CorbosService_DeliveryHistories_DeliveryHistoryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ShopDeliveryService {

    /**
     * @param filterText
     * @param contactIdMin
     * @param contactIdMax
     * @param startTimeMin
     * @param startTimeMax
     * @param endTimeMin
     * @param endTimeMax
     * @param totSmallPackagesMin
     * @param totSmallPackagesMax
     * @param totMediumPackagesMin
     * @param totMediumPackagesMax
     * @param totLargePackagesMin
     * @param totLargePackagesMax
     * @param externalOrderCode
     * @param description
     * @param note
     * @param storeId
     * @param tokanId
     * @param teamId
     * @param status
     * @param deliveryType
     * @param orderType
     * @param pickupId
     * @param deliveryId
     * @param randomCode
     * @param expireDate
     * @param orderDate
     * @param orderCode
     * @param deliveryExternalCode
     * @param customer
     * @param deliveryTypeName
     * @param onlyZaraDec
     * @param onlyToday
     * @param onlyCompleted
     * @param filtersBaseObject
     * @param filtersPropertyName
     * @param filtersField
     * @param filtersIgnoreCase
     * @param filtersOperator
     * @param filtersValue
     * @param filtersLogic
     * @param filtersFilters
     * @param maps
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1<Dm_Application_Comboxes_ComboboxItemDto_Dm_Application_Contracts_Version_1_2_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getApiShopDeliveriesCombobox(
        filterText?: string,
        contactIdMin?: number,
        contactIdMax?: number,
        startTimeMin?: string,
        startTimeMax?: string,
        endTimeMin?: string,
        endTimeMax?: string,
        totSmallPackagesMin?: number,
        totSmallPackagesMax?: number,
        totMediumPackagesMin?: number,
        totMediumPackagesMax?: number,
        totLargePackagesMin?: number,
        totLargePackagesMax?: number,
        externalOrderCode?: string,
        description?: string,
        note?: string,
        storeId?: string,
        tokanId?: string,
        teamId?: string,
        status?: wora_CorbosService_Deliveries_DeliveryStatus,
        deliveryType?: wora_CorbosService_Deliveries_DeliveryType,
        orderType?: wora_CorbosService_Deliveries_OrderType,
        pickupId?: string,
        deliveryId?: string,
        randomCode?: string,
        expireDate?: string,
        orderDate?: string,
        orderCode?: string,
        deliveryExternalCode?: string,
        customer?: string,
        deliveryTypeName?: string,
        onlyZaraDec?: boolean,
        onlyToday?: boolean,
        onlyCompleted?: boolean,
        filtersBaseObject?: string,
        filtersPropertyName?: string,
        filtersField?: string,
        filtersIgnoreCase?: boolean,
        filtersOperator?: string,
        filtersValue?: any,
        filtersLogic?: string,
        filtersFilters?: Array<Dm_Filter_Application_Contracts_Filters_TelerikFilterDto>,
        maps?: Array<Dm_Filter_Application_Contracts_Filters_FilterMapDto>,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/shop/deliveries/combobox',
            query: {
                'FilterText': filterText,
                'ContactIdMin': contactIdMin,
                'ContactIdMax': contactIdMax,
                'StartTimeMin': startTimeMin,
                'StartTimeMax': startTimeMax,
                'EndTimeMin': endTimeMin,
                'EndTimeMax': endTimeMax,
                'TotSmallPackagesMin': totSmallPackagesMin,
                'TotSmallPackagesMax': totSmallPackagesMax,
                'TotMediumPackagesMin': totMediumPackagesMin,
                'TotMediumPackagesMax': totMediumPackagesMax,
                'TotLargePackagesMin': totLargePackagesMin,
                'TotLargePackagesMax': totLargePackagesMax,
                'ExternalOrderCode': externalOrderCode,
                'Description': description,
                'Note': note,
                'StoreId': storeId,
                'TokanId': tokanId,
                'TeamId': teamId,
                'Status': status,
                'DeliveryType': deliveryType,
                'OrderType': orderType,
                'PickupId': pickupId,
                'DeliveryId': deliveryId,
                'RandomCode': randomCode,
                'ExpireDate': expireDate,
                'OrderDate': orderDate,
                'OrderCode': orderCode,
                'DeliveryExternalCode': deliveryExternalCode,
                'Customer': customer,
                'DeliveryTypeName': deliveryTypeName,
                'OnlyZaraDec': onlyZaraDec,
                'OnlyToday': onlyToday,
                'OnlyCompleted': onlyCompleted,
                'Filters.BaseObject': filtersBaseObject,
                'Filters.PropertyName': filtersPropertyName,
                'Filters.Field': filtersField,
                'Filters.IgnoreCase': filtersIgnoreCase,
                'Filters.Operator': filtersOperator,
                'Filters.Value': filtersValue,
                'Filters.Logic': filtersLogic,
                'Filters.Filters': filtersFilters,
                'Maps': maps,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1<wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto_wora_CorbosService_Application_Contracts_Version_1_3_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static postApiShopDeliveries(
        requestBody?: wora_CorbosService_Deliveries_ShopPortal_ShopGetDeliveriesInput,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/shop/deliveries',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto Success
     * @throws ApiError
     */
    public static getApiShopDeliveries(
        id: string,
    ): CancelablePromise<wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/shop/deliveries/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto Success
     * @throws ApiError
     */
    public static putApiShopDeliveries(
        id: string,
        requestBody?: wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryUpdateDto,
    ): CancelablePromise<wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/shop/deliveries/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiShopDeliveries(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/shop/deliveries/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto Success
     * @throws ApiError
     */
    public static postApiShopDeliveriesCreate(
        requestBody?: wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryCreateDto,
    ): CancelablePromise<wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/shop/deliveries/create',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param parentId
     * @returns Volo_Abp_Application_Dtos_ListResultDto_1<Dm_Application_Comboxes_ComboboxItemLongDto_Dm_Application_Contracts_Version_1_2_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getApiShopDeliveriesGetContactsCombobox(
        parentId?: number,
    ): CancelablePromise<Volo_Abp_Application_Dtos_ListResultDto_1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/shop/deliveries/get-contacts-combobox',
            query: {
                'parentId': parentId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Volo_Abp_Application_Dtos_ListResultDto_1<Dm_Application_Comboxes_ComboboxItemIntDto_Dm_Application_Contracts_Version_1_2_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getApiShopDeliveriesGetDeliveryType(): CancelablePromise<Volo_Abp_Application_Dtos_ListResultDto_1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/shop/deliveries/get-delivery-type',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Volo_Abp_Application_Dtos_ListResultDto_1<Dm_Application_Comboxes_ComboboxItemIntDto_Dm_Application_Contracts_Version_1_2_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getApiShopDeliveriesGetDeliveryStatus(): CancelablePromise<Volo_Abp_Application_Dtos_ListResultDto_1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/shop/deliveries/get-delivery-status',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Volo_Abp_Application_Dtos_ListResultDto_1<Dm_Application_Comboxes_ComboboxItemIntDto_Dm_Application_Contracts_Version_1_2_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getApiShopDeliveriesGetOrderType(): CancelablePromise<Volo_Abp_Application_Dtos_ListResultDto_1> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/shop/deliveries/get-order-type',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static getApiShopDeliveriesCancelOrder(
        id?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/shop/deliveries/cancel-order',
            query: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param deliveryId
     * @returns wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto Success
     * @throws ApiError
     */
    public static getApiShopDeliveriesGetData(
        deliveryId?: string,
    ): CancelablePromise<wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/shop/deliveries/get-data',
            query: {
                'deliveryId': deliveryId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns wora_CorbosService_DeliveryHistories_DeliveryHistoryDto Success
     * @throws ApiError
     */
    public static getApiShopDeliveriesGetHistory(
        id?: string,
    ): CancelablePromise<Array<wora_CorbosService_DeliveryHistories_DeliveryHistoryDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/shop/deliveries/get-history',
            query: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
