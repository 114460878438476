import jwtDecode, { JwtPayload } from "jwt-decode";

export default (tokenJson : any) => {
    console.log('tokenJson');
    try {
        const jwt: JwtPayload = jwtDecode(tokenJson);
        // const a = atob(tokenJson);

        // const token = JSON.parse(tokenJson);
        // const jwt = JSON.parse(atob(token.id_token.split(".")[1]));

        return { id: 'my-profile', ...jwt }
    } catch (e) {
        var jwt2 = JSON.parse(atob(tokenJson));
        return null;
    }   
    // const token = JSON.parse(tokenJson);
    // const jwt = JSON.parse(atob(token.id_token.split(".")[1]));

    // return { id: 'my-profile', ...jwt }
}       