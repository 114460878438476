import { makeStyles, Theme } from '@material-ui/core/styles';
//import bgImg from '../assets/img-city-transparent.png';

export const useUserStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
    width: 'auto',
    height: '100%',
    [theme.breakpoints.up('md')]: {
        padding: '30px 60px',
    },
    padding: '5px 20px',
    backgroundColor: theme.palette.background.paper,
    '& .MuiFormLabel-root': {
        opacity: '1 !important',
        color: '#BDBDBD',
    },
    '& .MuiTextField-root': {
        margin: '10px auto',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#DCDCDC',
    },
    '& .MuiOutlinedInput-inputMarginDense': {
        padding: '10px 14px 12px 14px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#DCDCDC',
        },
        '&:hover fieldset': {
          borderColor: '#DCDCDC',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#DCDCDC',
        },
    },
    '& .MuiTypography-body1': {
        fontSize: '12px'
    },
    '& .MuiBox-root': {
        width: '100%',
    },
  },
  appCard: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
    width: "90%",
    margin: "auto",
    borderColor: "#DCDCDC",
    background: "#F9F9F9",
    padding: "10px 20px",
  },
  actionToolbar: {
    width: '50%',
    margin: 'auto',
  },
  authSubmitBtn: {
    boxShadow: 'none',
    padding: '7px 22px',
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    width: "80%",
    margin: 'auto', 
    fontSize: '15px'
  },
  imgSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  imgLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

