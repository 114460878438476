import React, { useState, useEffect } from 'react';
import { List, Datagrid, useTranslate } from 'react-admin';
import FieldInput from "../utils/FieldInput";
import { Box } from '@material-ui/core';
import {getDataFromLocalDb} from "../utils/Helpers";
import {TABLE_DESCRIBE} from "../utils/local-db";


export const CbListGuesser = props => {
	let resource = props.resource;
	const [describe, setDescribe] = useState({});
	const [fields, setFields] = useState([]);
	const [label, setLabel] = useState('');
	const pagesize = useState(25);
	const modPermission = props?.options?.permission[0]?.permissions ?? null;
	const translate = useTranslate();
	const [assignedUserList, setAssignedUserList] = useState([]);

	
	useEffect(() => {
		getDataFromLocalDb(TABLE_DESCRIBE.tableName).then((result) => {
			return result;
		}).then(describeData => {
			setDescribe(describeData);
			setLabel(describeData[resource]?.label);
			let ffields = describeData[resource]?.filterFields?.fields ?? [];
			const objFilterFields = [];
			let modFields = [];
			for (let f = 0; f<ffields.length; f++) {
				const field = describeData[resource]?.fields.find((element) => {
					return element.name === ffields[f];
				});
				if(field){
					modFields.push(field);
					objFilterFields.push(field);
				}
			}
			const aUserList = describeData[resource]?.userlist ?? [];
            setAssignedUserList(aUserList);
			setFields(modFields);
		}).catch(() => {
			console.log(translate('translations.somethingWentWrong'));
		})
	}, [resource, translate])


	return (
		<>
		
		{!modPermission 
			? 	<Box justifyContent="center" alignContent="center" alignItems="center"> 
					<h2>ACCESS DENIED</h2>
				</Box>
			: 	<List
					{...props}
					title={label}
					perPage={pagesize}
					>
						<Datagrid>
							{
								fields.map((field, index) => {
									return FieldInput(field, {}, '', describe, assignedUserList);
								})
							}
						</Datagrid>
				</List>
		}
		</>
	)
};
