import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loader from './components/Loader'
import { abpSessionManager } from './auth/AbpProvider';


class Auth extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
      error: false,
      code: '',
      isLoading: true,
      user: null,
    }
  }

  static propTypes = {
    user: PropTypes.object,
    validateToken: PropTypes.func,
  }


  componentDidMount() {
    // console.log('Custom AUTH');
    this.authenticate();
  }

  async authenticate(any, permissions) {
    console.log('Check Auth state');
    if (abpSessionManager().isValidSession()) {
      this.setState({ isLoading: false });
    }
    else {
      //abpAuthProvider.logout();
      localStorage.removeItem('firstinitialize');
      window.location.href = '#/login';
    }
  }

  render() {

    const { children } = this.props;

    return this.state.isLoading ? <Loader /> : children

  }

}


export default Auth;