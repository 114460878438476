import { GetListParams } from 'react-admin';
import {TableDefinition} from '../types/TableDefinition';
import {  ShopDeliveryService, StoreService,   Volo_Abp_Application_Dtos_ListResultDto_1,   Volo_Abp_Application_Dtos_PagedResultDto_1, 
     wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto, 
     wora_CorbosService_Deliveries_ShopPortal_ShopGetDeliveriesInput, 
     wora_CorbosService_Stores_GetStoresInput, 
     } from '../packages/proxy/src';
import { AbpUserService } from '../abp/services/AbpUserService';

class AbpServiceCustomProvider {
    _table  = {} as TableDefinition;
    constructor(table : TableDefinition){
        this._table = new TableDefinition();
    }

    async getList (resource : string, params: GetListParams, additionalWhereClause: any, additionalFields: any)  {
        // console.log('Get list', resource, params);

        let schemaName = '';
        let skip = (params.pagination.page - 1) * params.pagination.perPage;
        let count = params.pagination.perPage;

        // let definition = {} as TableDefinition;
        if(resource ==='delivery'){
            schemaName = 'wora.CorbosService.Deliveries.DeliveryDto';
            if( params.sort && params.sort?.field === 'statusName'){
                params.sort.field = 'status';
            }
            //let definition = await this._table.getDefintion(schemaName);
            let input : wora_CorbosService_Deliveries_ShopPortal_ShopGetDeliveriesInput = {};
            input.filters = await this._table.getFilter(params, schemaName) || undefined;
            input.sorting = await this._table.getSorting(params, schemaName);
            input.skipCount = skip;
            input.maxResultCount = count;
            return ShopDeliveryService.postApiShopDeliveries(input)
            .then((data : Volo_Abp_Application_Dtos_PagedResultDto_1) => {
                return { 'data': data.items, 'total': Number(data.totalCount) }
            }).catch((er) => { 
               return { data: [], total: 0 }
            });
        }else if (resource ==='ContactRole'){
            let input : wora_CorbosService_Stores_GetStoresInput = {};
            if(this._table._tables && this._table._tables.length > 0){
                input.filters = await this._table.getFilter(params, schemaName) || undefined;
                input.sorting = await this._table.getSorting(params, schemaName);
            }
            
            input.skipCount = skip;
            input.maxResultCount = count;
            // console.log('Get Store', input);
            return StoreService.getApiCorbosServiceStoresGetStoresCombobox()
                .then((data: Volo_Abp_Application_Dtos_ListResultDto_1) => {
                    const keyValueList = data.items ? data.items.map((item: any) => ({ id: item.value, name: item.displayText })) : [];
                    return { 'data': keyValueList, 'total': Number(data.items?.length) };
                }).catch((er) => {
                    return { data: [], total: 0 }
                });

        }else if (resource === 'AAContactRole'){
            // let input : Dm_Crm_Contacts_GetContactsInput = {};
            let input : any = {};
            input.filters = await this._table.getFilter(params, schemaName) || undefined;
            input.sorting = await this._table.getSorting(params, schemaName);
            input.skipCount = skip;
            input.maxResultCount = count;
            // return ContactService.postApiCrmContacts(input)
            // .then((data : Volo_Abp_Application_Dtos_PagedResultDto_1) => {
            //     return { 'data': data.items, 'total': Number(data.totalCount) }
            // }).catch((er) => { 
            //    return { data: [], total: 0 }
            // });
        }

    }
    async getOne (resource : any, params : any) 
    {
        // console.log('Get One', resource, params);
        if(resource === 'delivery'){
            return ShopDeliveryService.getApiShopDeliveriesGetData(params.id)
            .then((data : wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto) => {
                return { 'data': data }
            }).catch((er) => { 
               return { data: {} }
            });
        }else if(resource === 'my-profile'){
            return AbpUserService.getApiIdentityUserById(params.id)
            .then((data:any)=>{
                return { 'data': data };
            })
            .catch((error:any)=>{
                return error;
            });
        }
    }
    
    async getMany (resource : any, params : any) 
    {
        console.log('Custom Get Many', resource, params);
        return { data: [] };
    }
}

export {AbpServiceCustomProvider};