import * as React from 'react';
import {useState} from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {useMediaQuery} from '@material-ui/core';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {useRedirect, Logout, useTranslate } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ordine from './assets/menu/newOrderIcon.svg';
import reso from './assets/menu/effettua un reso.svg';
import shopToShop from './assets/menu/shop to shop.svg';
import ordini from './assets/menu/cronologia ordini.svg';
import salvati from './assets/menu/saved addresses.svg';
import cronologia from './assets/menu/cronologia pagamenti.svg';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    fullList: {
        width: '100%',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    menuIcon: {
        minWidth: '45px',
        color: '#930483'
    },
    menuImages: {
        width: '35px',
        paddingRight: '5px',
    }
}));

const SideBarMenu = (props: any) => {

    const classes = useStyles();
    const menuItems = props?.menu;
    const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
    const [menuAnchorPosition] = useState<any>('left');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const redirect = useRedirect();
    const translate = useTranslate();
    const location = useLocation();

    const toggleDrawer = (anchor: any, open: any) => (event: any) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setIsOpen(!isOpen);
    };

    const goTo = (path: string) => {
        const params = new URLSearchParams(location?.search);
        const reload = params.get('reload');
        if (reload && path === '/') {
            redirect(path);
            window.location.reload();
        } else {
            redirect(path);
        }
    }
    

    return (
        <>
            <Typography component="span" variant="h5">
                <IconButton onClick={toggleDrawer(menuAnchorPosition, true)} edge="start" className={classes.menuButton}
                            color="inherit" aria-label="menu">
                    <MenuIcon/>
                </IconButton>
            </Typography>
            <SwipeableDrawer
                anchor={menuAnchorPosition}
                open={isOpen}
                onClose={toggleDrawer(menuAnchorPosition, false)}
                onOpen={toggleDrawer(menuAnchorPosition, true)}
            >
                <Toolbar/>
                <div
                    className={clsx(classes.list, {
                        [classes.fullList]: menuAnchorPosition === 'top',
                    })}
                    role="presentation"
                    onClick={toggleDrawer(menuAnchorPosition, false)}
                    onKeyDown={toggleDrawer(menuAnchorPosition, false)}
                >
                    <List className={'sideBarMenuList'}>
                        {menuItems.map((menuItem: any) => {
                            let _menuUrl = menuItem.name;
                            if (menuItem.name === '/') {
                                //make order icon
                                menuItem.icon = ordine;
                            } else if (menuItem.name === '/order-return') {
                                menuItem.icon = reso;
                            } else if (menuItem.name === '/shop-to-shop') {
                                menuItem.icon = shopToShop;
                            } else if (menuItem.name === 'delivery') {
                                menuItem.icon = ordini;
                            } else if (menuItem.name === 'CobroPago') {
                                menuItem.icon = cronologia;
                            }else if (menuItem.name === 'ContactRole') {
                                menuItem.icon = salvati;
                            }else {
                                menuItem.icon = null;
                            }
                            if(_menuUrl.charAt(0) !== '/') _menuUrl = `/${_menuUrl}`;
                            return (
                                menuItem.show &&
                                <ListItem button key={menuItem.menuId} onClick={() => goTo(_menuUrl)}>
                                    <ListItemIcon className={classes.menuIcon}> {menuItem.icon ?
                                        <img alt={menuItem.name} className={classes.menuImages}
                                             src={menuItem.icon}></img> : <DefaultIcon/>} </ListItemIcon>
                                    <ListItemText primary={translate(`${menuItem.label}`)}/>
                                </ListItem>
                            )
                        })}
                    </List>
                    <Divider/>
                    {isXSmall &&
                        <List>
                            <Logout {...props} />
                        </List>
                    }
                </div>
            </SwipeableDrawer>
        </>
    );
}

export default SideBarMenu;