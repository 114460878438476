import React from 'react';
import { TableDefinition } from '../types/TableDefinition';
import { AbpContextProps } from '../types/AbpContextProps';

export type Abp = (options?: any) => Abp;

export const AbpContext = React.createContext<AbpContextProps>({
  // locale: {} as Abp,
  setTable: () => { },
  configuration: {
    localization: undefined,
    auth: undefined,
    setting: undefined,
    currentUser: undefined,
    features: undefined,
    globalFeatures: undefined,
    multiTenancy: undefined,
    currentTenant: undefined,
    timing: undefined,
    clock: undefined,
    objectExtensions: undefined,
    extraProperties: undefined
  },
  dataProvider : {},
  tables: new TableDefinition()
});

// AbpContext.displayName = 'AbpContext';

const AbpContextProvider = (props : any)=>{

    const setTableHandler = (text: string)=>{

    }

    const contextValue : AbpContextProps = {
      setTable : setTableHandler, 
      configuration: props.value.configuration,
      dataProvider: props.value.providerCustom,
      tables:props.value.table};

    return (
        <AbpContext.Provider value={contextValue}>
          {props.children}
        </AbpContext.Provider>
      );
}

export {AbpContextProvider};