import * as React from "react";
import {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Box } from "@material-ui/core";
import { useStyles } from "../style/Index";
import { usePaymentStyles } from "./PaymentStyle";
import Typography from "@material-ui/core/Typography";
import {
  Notification,
  useTranslate,
  useNotify,
} from "react-admin";
import {doCreate, doInvoke, update, updateMany} from '../utils/lib';
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { PaymentDetails } from "./PaymentDetails";
import { PaymentMessage } from "./PaymentMessage";
import {getDataFromLocalDb} from '../utils/Helpers';
import {TABLE_AUTH} from "../utils/local-db";
import StripeContainer from "./StripeContainer";
import { BankTransferPaymentMethod } from "../constant";

export const PaymentInfo = (props: any) => {
  // console.log('PaymentInfo');
  const classes = useStyles();
  const paymentClasses = usePaymentStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPaymentMessage, setPaymentMessage] = useState<boolean>(false);
  const translate = useTranslate();
  const notify = useNotify();
  const [userAccount,setUserAccount] = useState<any>(null);
  const [contactId, setContactId] = useState<string>('');
  const [taskIds,setTasksIds] = useState<any []>([]);
  const [salesOrderRecordId, setSalesOrderRecordId] = useState<string>('');
  const [ stripeKey, setStripeKey ] = useState<string>('');




  useEffect(()=>{
    // console.log('Payment');
    // doInvoke('SearchGlobalVar', {gvname: 'Application_Stripe_Key', defaultvalue: '', gvmodule: 'Invoice'}, 'GET').then((result: any) => {
    //   setStripeKey(result);
    // }).catch(err => console.log(err));
    setStripeKey('pk_test_RlI38BaAVLt4IBGGGQWbkEWk');
    setUserAccount({accountsretailer_id: 'Bonifico'});
    // getDataFromLocalDb(TABLE_AUTH.tableName).then((result) => {
    //     setUserAccount(result?.accountDetail);
    //   if (result?.accountDetail?.accountsretailer_id === BankTransferPaymentMethod) setContactId(result?.logindata?.user?.contactid);
    // });
    //   let deliveryRecordsIds:any = [];
    //   let salesOrderRecordId:string = '';
    //   for (const recordId in props.massCreateData){
    //       if(props.massCreateData[recordId].id.substring(0,2) === '60'){
    //           deliveryRecordsIds.push(recordId);
    //       }
    //       if (props.massCreateData[recordId].id.substring(0,2) === '6x'){
    //           salesOrderRecordId = recordId;
    //       }
    //   }
    //   setSalesOrderRecordId(salesOrderRecordId);
    //   setTasksIds(deliveryRecordsIds);
  }, []);



  const saveRecord = async () => {
      const paymentMethod:string = userAccount?.accountsretailer_id;
      const userAccountId:string = userAccount?.account_id;


    setLoading(true);

    // Call the API to create the record
    


      //will create only CobroPago
      // doCreate(
      //     'CobroPago',
      //     {
      //       elementType: 'CobroPago',
      //       related_id: props.massCreateData[taskIds[0]].related_order,
      //       copropago_status: 'Created',
      //       parent_id: contactId,
      //       payment_method: paymentMethod,
      //         cp_relaccount: userAccountId
      //     }
      // ).then(()=>{
      //   update(
      //       'SalesOrder',
      //       {
      //         id: props.massCreateData[salesOrderRecordId].id,
      //         subject: props.massCreateData[salesOrderRecordId].subject,
      //           order_id: props.massCreateData[salesOrderRecordId].order_id,
      //           invoicestatus: 'Approved'
      //       }
      //   ).then(
      //       updateMany(
      //           'delivery',
      //           [
      //               {
      //               id: props.massCreateData[taskIds[0]].id,
      //               stato: 'Importato'
      //               },
      //               {
      //                   id: props.massCreateData[taskIds[1]].id,
      //                   stato: 'Importato'
      //               }
      //           ]
      //       ).then(
      //           ()=>{
      //               setPaymentMessage(true);
      //               notify(translate('translations.order_saved'), "success");
      //           },
      //           ()=>{
      //               notify(translate('translations.something_went_wrong'), "error")
      //           }
      //       )
      //   )
      //     },
      //     ()=>{
      //         notify(translate('translations.something_went_wrong'), "error")
      //     })
  };
  return (
    <div className={paymentClasses.root}>
      
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
      {showPaymentMessage && (
        <PaymentMessage
          message={translate('CorbosService::OrderCreated')}
          redirectUrl={`${props?.resource}/${ props?.massCreateData != undefined ? props?.massCreateData[taskIds[0]].id: '123'}/show`}
        />
      )}
      {!showPaymentMessage && (
        <Card className={classes.appCard} variant="outlined">
        <CardContent>
              <Typography component="div" variant="h6">
                <Box fontWeight="fontWeightBold" textAlign="center" mb={1} py="1rem">
                    { userAccount?.accountsretailer_id !== BankTransferPaymentMethod ?   translate('CorbosService::PaymentInfo') :  translate('CorbosService::OrderInfo')}
                </Box>
              </Typography>

              <Box py="1rem" mt="-2.4rem" px="1rem">
                <>
                    <Box mt={5}>
                      {
                          userAccount?.accountsretailer_id !== BankTransferPaymentMethod ? (
                                  <>
                                  {stripeKey &&
                                      <StripeContainer stripeKey={stripeKey} 
                                      setShowPaymentMessage = {setPaymentMessage}  {...props}/>
                                    
                                  }
                                  </>
                        ) :
                        (
                        <Box {...props} component="div">
                              <Box className={classes.cardFormHolder}>
                                  <Box p="1rem" mt="-0.5rem">
                                    {
                                      <PaymentDetails 
                                      packages = {props?.packages} 
                                      paymentinfo={props?.paymentinfo} 
                                      paymentMethod = {userAccount?.accountsretailer_id}/>
                                      }
                                  </Box>
                                <Box mt="0.8rem" mx="-1rem">
                                  <Button
                                      color="primary"
                                      startIcon={
                                        loading ? (
                                            <CircularProgress
                                                color={loading ? "primary" : "secondary"}
                                                size={24}
                                            />
                                        ) : (
                                            <></>
                                        )
                                      }
                                      disabled={loading}
                                      fullWidth
                                      size="large"
                                      className={paymentClasses.toolbarBtn}
                                      variant="contained"
                                      onClick={saveRecord}
                                  >
                                      {translate('CorbosService::Save')}
                                  </Button>
                                </Box>
                              </Box>
                        </Box>
                        )
                      }

                    </Box>
                </>
              </Box>
        </CardContent>
      </Card>
      )}

    <Notification />
    </div>
  );
};
