/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
//import type { Volo_Abp_AspNetCore_Mvc_MultiTenancy_FindTenantResultDto } from '../models/Volo_Abp_AspNetCore_Mvc_MultiTenancy_FindTenantResultDto';

import type { CancelablePromise } from '../../packages/proxy/src/core/CancelablePromise';
import { OpenAPI } from '../../packages/proxy/src/core/OpenAPI';
import { request as __request } from '../../packages/proxy/src/core/request';

export interface IdentityUserUpdateDto {
    userName?:            string;
    name?:                string;
    surname?:             string;
    email?:               string;
    phoneNumber?:         string;
}


export class AbpUserService {

   /**
     * @param id 
     * @param requestBody 
     * @returns wora_RoutingService_Deliveries_DeliveryDto Success
     * @throws ApiError
     */
   public static putApiIdentityServiceUsers(
    id: string,
    requestBody?: IdentityUserUpdateDto,
    ): CancelablePromise<any> {
            return __request(OpenAPI, {
                method: 'PUT',
                url: '/api/identity/users/{id}',
                path: {
                    'id': id,
                },
                body: requestBody,
                mediaType: 'application/json-patch+json',
                errors: {
                    400: `Bad Request`,
                    401: `Unauthorized`,
                    403: `Forbidden`,
                    404: `Not Found`,
                    500: `Server Error`,
                    501: `Server Error`,
                },
            });
        }

    /**
     * @param id 
     * @returns Volo_Abp_AspNetCore_Mvc_MultiTenancy_FindTenantResultDto Success
     * @throws ApiError
     */
    public static getApiIdentityUserById(
id: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/identity/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    public static getMyProfile(){
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/account/my-profile',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
        
    }

    public static updateMyProfile( requestBody: any){
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/account/my-profile',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
        
    }

    public static updatePassword(requestBody: any){
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/my-profile/change-password',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
        
    }
}

