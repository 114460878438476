import React, { useEffect, useState, } from 'react';
import {Admin, Resource} from 'react-admin';
import {
    createTheme,
    ThemeProvider,
    responsiveFontSizes
} from '@material-ui/core/styles';
// import dataProvider from './corebosServerProviderTest';
import Layout from './Layout';
import Login from './auth/Login';
import customRoutes from './routes';
import {appTheme} from './theme';
import * as locales from '@material-ui/core/locale';
import {itIT} from '@material-ui/core/locale';
import NotFound from './components/NotFound';
import {UserProfileEdit} from './user/UserProfileEdit';
import {PaymentHistory} from "./payment/PaymentHistory";
import {AddressList} from "./address/AddressList";
import AddresEdit from "./address/AddresEdit";
import { NewOrder } from "./order/NewOrder";
import { OrderList } from "./order/OrderList";
import { OrderShow } from "./order/OrderShow";
import { OrderEdit } from "./order/OrderEdit";
import { CbListGuesser } from "./corebosGuessers/cbListGuesser";
import { AbpAuthServiceProvider} from './auth/AbpAuthProvider';
import { AbpServiceCustomProvider} from './abp/abpServiceCustomProvider';
import { AbpTranslationServiceProvider } from './abp/abpTranslationService';
import { abpConfigurationProvider } from './abp/abpConfigurationProvider';
import { TableDefinition } from './types/TableDefinition';

import {AbpContextProvider} from './abp/AbpContext'
import { configService }  from './abp/configService';
import authProviderWithOpenId from "./auth/authProviderWithOpenId";
import { initializeUserManager } from './auth/odicUserManager'; 
let theme = createTheme(appTheme, locales[itIT]);
theme = responsiveFontSizes(theme);

if (window.coreBOS === undefined) {
    window.coreBOS = {};
}

const table = new TableDefinition();


const App = () => {
    console.log('app.js call');
    // console.log(process.env.REACT_APP_API_SERVER);

    const [isLoading, setIsLoading] = useState(true);
    const [translate, setTranslate] = useState(null);
    // const [resources, setResources] = useState(null);
    const [permissions, setPermission] = useState(null);
    const [configuration, setConfiguration] = useState(null);
    const [configError, setConfigError] = useState(null);
    // const [tableDefinition, setTableDefinition] = useState();
    
    // cbconn.setURL(apiUrl);
    // const routeWithoutLayouts = ['#/set-password', '#/reset-password', '#/register'];
    const [configLoaded, setConfigLoaded] = useState(false);

    const providerCustom = new AbpServiceCustomProvider();
    const transaltionProvider = new AbpTranslationServiceProvider(translate);
    const abpAuthServiceProvider = new AbpAuthServiceProvider(configuration);
    // const configurationProvider = new AbpConfigurationProvider(configuration);
    //const config = new ConfigService();

    const fetchData = async () => {
        await configService.load();
        await abpConfigurationProvider.fetchData(true);
        console.log('compare config');
        const localization = localStorage.getItem('localization') ?? 'en';
        //transaltionProvider.changeLocale(localization.cultureName);
        let translate =  await transaltionProvider.fetchData();
        // console.log('compare translate');
        // console.log('trasnlate call', c);
        //console.log('configuration', configProvider.getTranslation());
        setTranslate(translate);
        setPermission(abpConfigurationProvider.getPermission());
        setConfiguration(abpConfigurationProvider.getConfiguration());

        setIsLoading(false); // Set loading to false when the operation is done
      };

    useEffect(() => {
        console.log('Init APP');
        // Call your async function when the component mounts
        async function loadConfig() {
            try {
              await configService.load();
              await initializeUserManager();
              setConfigLoaded(true);
              fetchData();
            } catch (error) {
              setConfigError(error.message);
            }
          }
          loadConfig();
        
      }, []); // The empty dependency array ensures this effect runs only once, on component mount
    

    

    const fetchResources = async (permissions) => {
        // console.log('fetch resoure');
        if(permissions && permissions.grantedPolicies){
            const resourceList = [];

            resourceList.push({
                name : 'delivery',
                list : true,
                create : true,
                show : true,
                edit : true});
            resourceList.push({
                name : 'CobroPago',
                list : true,
                create : true,
                show : true,
                edit : true});
            resourceList.push({
                name : 'my-profile',
                list : true,
                create : true,
                show : true,
                edit : true});
            resourceList.push({
                name : 'ContactRole',
                list : true,
                create : true,
                show : true,
                edit : true});

            const resources = resourceList.map((item) => {
                let shouldModuleBeDisplayed = true;
                if (item.name === 'delivery') {
                    item.list = OrderList;
                    item.create = NewOrder;
                    item.show = OrderShow;
                    // item.edit = NewOrder; // only normal order can be edited
                    item.edit = OrderEdit; // wrapper widget to show the edit form
                } else if (item.name === 'CobroPago') {
                    item.list = PaymentHistory;
                    item.create = null;
                    item.show = null;
                    item.edit = null;
                } 
                else if (item.name === 'my-profile') {
                    shouldModuleBeDisplayed = false;
                    item.list = null;
                    item.create = null;
                    item.show = null;
                    item.edit = UserProfileEdit;
                }
                else if (item.name === 'ContactRole') {
                    item.list = AddressList;
                    item.create = AddresEdit;
                    item.show = null;
                    item.edit = AddresEdit;
                } else {
                    shouldModuleBeDisplayed = false;
                    item.list = CbListGuesser;
                    item.create = null;
                    item.show = null;
                    item.edit = null;
                }
                return <Resource
                    name={item.name}
                    list={item.list}
                    show={item.show}
                    create={item.create}
                    edit={item.edit}
                    options={{
                        label: item.name,
                        show: shouldModuleBeDisplayed,
                        permission: {create:true, delete:true,list:true,update:true} //permissions?.filter(permission => permission.module === item.name)
                    }}/>;
            });
            return resources;
        }else{
            return;
        }
    }

    return (
        
        isLoading ? (
            <p>Loading...</p>
          ) : (
            <AbpContextProvider value={{configuration, table, providerCustom}}>
            <ThemeProvider theme={theme}>
                <Admin
                    catchAll={NotFound}
                    i18nProvider={transaltionProvider}
                    loginPage={Login}
                    customRoutes={customRoutes}
                    dataProvider={providerCustom}
                    authProvider={authProviderWithOpenId}
                    layout={Layout}
                    dashboard={NewOrder}
                >
                    {resources => fetchResources(permissions)}
                </Admin>
            </ThemeProvider>
            </AbpContextProvider>
          )
    )
}

export default App;

