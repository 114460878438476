import * as React from 'react';

import {Box} from "@material-ui/core";
import {usePageStyles} from './DashboardStyles'
import checkedIMg from '../assets/svgIcons/checked (3) 2.svg';
import cancelIcon from '../assets/svgIcons/cancel (1) 11.svg';
import Chart from '../components/Chart'
import {useEffect, useState} from "react";
import {doInvoke} from "../utils/lib";
import {useTranslate} from "react-admin";

export const Dashboard = () => {
    const classes = usePageStyles();
    const [cancelledOrders, setCancelledOrders] = useState<number>();
    const [doneOrders, setDoneOrders] = useState<number>();
    const [chartData, setChartDate] = useState<any []>();
    const translate = useTranslate();
    useEffect(() => {
        const ordiniEffetuatiName = 'Ordini_effetuati';
        const ordiniCancellatiName = 'Ordini_cancellati';
        const chartDataId = 'Orders_by_Time_and_Type';
        doInvoke('cbQuestionAnswer', {
            qid: ordiniEffetuatiName,
        }, 'GET').then((result: any) => {
            setDoneOrders(result?.answer[0]['total orders']);
            doInvoke('cbQuestionAnswer', {
                qid: ordiniCancellatiName
            }, 'GET').then((result: any) => {
                setCancelledOrders(result?.answer[0]['cancelled orders']);
                doInvoke('cbQuestionAnswer', {
                    qid: chartDataId
                }, 'GET').then((result: any) => {
                    setChartDate(result?.answer);
                })
            })
        })
    }, [])
    return (

        <Box py={3}>
            <Box display={"flex"} width={"80%"}  flexDirection={"row"} justifyContent={"space-between"}
                 className={classes.responsiveLabelContainer}>
                <Box className={classes.infoBoxes}>
                    <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
                        <div>{translate('translations.done_orders')}</div>
                        <div>{doneOrders}</div>
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <img alt={'checkedImg'} src={checkedIMg} width={"35px"} height={"35px"}/>
                    </Box>
                </Box>
                <Box className={classes.infoBoxes}>
                    <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
                        <div>{translate('translations.canceled_orders')}</div>
                        <div>{cancelledOrders}</div>
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <img src={cancelIcon} width={"35px"} height={"35px"} alt="Canecl Icon"/>
                    </Box>
                </Box>
            </Box>
            <Box width={"80%"} height={"500px"} style={{margin: "auto", marginTop: "50px"}} my={10}
                 className={classes.barChartContainer}>
                <Chart data={chartData}/>
            </Box>
        </Box>
    );
};
