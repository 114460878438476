import { makeStyles } from '@material-ui/core/styles';

export const usePageStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #DCDCDC',
    [theme.breakpoints.up("md")]: {
      width: "75%",
    },
    width: "90%",
    '& .MuiTableCell-root': {
      border: '1px solid #DCDCDC',
    },
      '& .MuiPaper-elevation1': {
        boxShadow: 'none'
      },
    '& .MuiTableRow-head': {
      border: '1px solid #DCDCDC',
    },
      '& .MuiTableHead-root':{
        opacity: 0.5
      },
    '& .MuiTableRow-head > th': {
       background: '#E9E9E9',
       color: '#000',
       borderRightColor: '#DCDCDC',
       paddingTop: '8px',
       paddingBottom: '8px',
        fontSize: "16px"
    },
    '& .MuiIconButton-root': {
      padding: 0,
      fontSize: '1em',
    },
    '& .MuiSvgIcon-root': {
        width: '1em',
        height: '1em',
        fontSize: '1em',
    },
    '& .MuiOutlinedInput-root': {
      background: '#fff',

      padding: '0 14px',
      marginTop: '-5px',
    },
    '& .MuiFormLabel-root': {
      fontSize: '14px',
      marginTop: '-5px',
      marginLeft: '8px',
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      marginTop: '-20px !important',
    },
  },
  topBarBox: {
    width: '100%',
  },
  topBarListAction: {
    justifyContent: 'flex-start',
  },
    listActionBtnContainer:{
        [theme.breakpoints.down("xs")]: {
            paddingRight: '14px',
            paddingLeft: '0'
        },
        paddingLeft:'20px'
    },
  listActionBtn: {
      padding: '8.5px 5px',
      borderRadius: '5px',
      boxShadow: 'none',
      fontWeight: 'normal'
  },
  RaListMain: {
    margin: 'auto',
  },
    createBtn: {
        background: '#ffc300 !important',
        color: '#fff !important',
        borderRadius: '5px !important',
        boxShadow: 'none',
        padding: '8px 22px',
        width: '100%',
        border: 0,
        fontSize: '18px',
        cursor: 'pointer'
    },
}));

