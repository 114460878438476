// import * as cbconn from 'corebos-ws-lib/WSClientm';

export const getChallenge = async (username, password) => {
    // const challengeRes = await cbconn.doLogin(username, password);
    // return challengeRes;
}

export const getListTypes = async () => {
    // const listTypes = await cbconn.doListTypes();
    // return listTypes;
}

export const getModDescribe = async (moduleName) => {
    // const describe = await cbconn.doDescribe(moduleName);
    // return describe;
}

export const retrieveRecord = async (record) => {
    // const result = await cbconn.doRetrieve(record);
    // return result;
}

export const massRetrieve = async (ids) => {
    // const result = await cbconn.doMassRetrieve(ids);
    // return result;
}

export const massCreate = async (data) => {
    // const result = await cbconn.doMassUpsert(data);
    // return result;
}

export const doQuery = async (query) => {
    // const result = await cbconn.doQuery(query);
    // return result;
}
export const  updateMany =  (resource, params) => {
    // return cbconn.doInvoke('MassUpdate', {'elements': JSON.stringify(params)}, 'POST')
    // .then((data) => { return { 'data': data } })
}
export const  update =  (resource, params)=>{
    // return cbconn.doUpdate(resource, params)
    // .then((data) => { return { 'data': data } })
}

export const doInvoke = async (method, params, type) => {
    // const result = await cbconn.doInvoke(method, params, type);
    // return result;
}

export const doListTypes = async () => {
    // const result = await cbconn.doListTypes();
    // return result;
}

export const doCreate = async (moduleName, data) => {
    // const result = await cbconn.doCreate(moduleName, data);
    // return result;
}
