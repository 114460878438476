import React, { useEffect, useState } from 'react';
import {
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    usePermissions,
    useRecordContext,
    useRedirect,
    useTranslate
} from 'react-admin';
import { Box, Grid, Typography } from "@material-ui/core";
import { AppButton } from "../../components/AppButton";
import FieldDisplay from "../../utils/FieldDisplay";
import Button from "@material-ui/core/Button";
import { EditRounded } from "@material-ui/icons";
import { useOrderStyles } from "../OrderStyle";
import { retrieveRecord } from "../../utils/lib";
import RawField from '../../components/RawField';
import { permissionCheck } from "../../utils/Helpers";
import { DELIVERY, PICKUP } from '../../constant';
import { da, tr } from 'date-fns/locale';
import { TaskDataDto } from '../../models/Orders/TaskDataDto';
import { FieldDisplayDto, getField } from '../../types/FieldDisplayDto';
import { FieldInputTypeDto } from '../../types/FieldInputTypeDto';
import { OrderShowDto } from '../../types/OrderShowDto';
import { wora_CorbosService_Deliveries_ShopPortal_ShopDeliveryDto, wora_CorbosService_DeliverySteps_ShopDeliveryStepDto } from '../../packages/proxy/src';
import { FieldDisplayUiType } from '../../types/FieldDisplayUiType';

const RelatedTaskShow = (props: any) => {
    // console.log('RelatedTaskShow', props);
    const orderClasses = useOrderStyles();
    const redirect = useRedirect();
    const translate = useTranslate();

    const [showMoreDetails, setShowMoreDetails] = useState<boolean>(false);
    const [step, setStep] = useState<wora_CorbosService_DeliverySteps_ShopDeliveryStepDto>({});
    const [delivery, setDelivery] = useState<any>(null);

    const [recordTyped, setRecordTyped] = useState<TaskDataDto>({});
    const { permissions } = usePermissions();
    const [isDelivery, setIsDelivery] = useState<boolean>(false);
    const [fields, setFields] = useState<FieldDisplayDto[]>([]);
    // const recordLocale = useRecordContext(props);

    useEffect(() => {
        // console.log('effect related', props?.taskType);

        let modFields: FieldInputTypeDto[] = [
            { name: "storeName", label: translate("CorbosService::Store"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
            { name: "contactName", label: isDelivery ? translate("CorbosService::Customer") : translate("CorbosService::Referent"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
            { name: "contactAddress", label: translate("CorbosService::Address"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
            { name: "driver", label: translate("CorbosService::Driver"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
            { name: "interPhone", label: translate("CorbosService::InterPhone"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
            { name: "reception", label: translate("CorbosService::Reception"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
            { name: "note", label: translate("CorbosService::Note"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
            { name: "totalPackages", label: translate("CorbosService::TotalPackages"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
            { name: "totSmallPackages", label: translate("CorbosService::TotSmallPackages"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
            { name: "totMediumPackages", label: translate("CorbosService::TotMediumPackages"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
            { name: "totLargePackages", label: translate("CorbosService::TotLargePackages"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
            { name: "eta", label: translate("CorbosService::ETA"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
            { name: "contactPhone", label: translate("Crm::Phone"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
            { name: "related_order", label: translate("CorbosService::ExternalOrderCode"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
            { name: "dateFrom", label: translate("CorbosService::StartTime"), mandatory: false, editable: true, uitype: FieldDisplayUiType.DateTime, defaultValue: '', placeholder: '', readonly: true },
            { name: "dateTo", label: translate("CorbosService::EndTime"), mandatory: false, editable: true, uitype: FieldDisplayUiType.DateTime, defaultValue: '', placeholder: '', readonly: true },
            { name: "contact", label: translate("Crm::Contact"), mandatory: false, editable: true, uitype: FieldDisplayUiType.TextArea, defaultValue: '', placeholder: '', readonly: true },
            { name: "deliveryTypeName", label: translate("CorbosService::DeliveryType"), mandatory: false, editable: true, uitype: FieldDisplayUiType.BaseText, defaultValue: '', placeholder: '', readonly: true },
            
        ];

        setFields(modFields);
        setIsDelivery(props?.taskType === DELIVERY);
        setStep(props?.record.task);
        setDelivery(props?.record.delivery);

    }, [props?.record]) // eslint-disable-line



    const showMoreDetailsConsegna = () => {
        setShowMoreDetails(!showMoreDetails);
    }

    const getFieldForOperation = (fieldName: string | undefined): string => {
        // console.log('Type gfguhifdjguhisfjgkhsfkgjh', props?.taskType, props?.taskType === DELIVERY);
        let field: any = {};
        switch (fieldName) {
            case 'eta':
                field = props?.taskType === DELIVERY ? 'taskEtaTo' : 'taskEtaFrom';
                break;
            case 'related_order':
                field = props?.taskType === DELIVERY ? 'contactName' : 'storeName';
                break;
            case 'status':
                field = props?.taskType === DELIVERY ? 'deliveryStatusName' : 'pickupStatusName';
                break;
            case 'dateFrom':
                field = props?.taskType === DELIVERY ? 'requestedFrom' : 'requestedTo';
                break;
            case 'dateTo':
                field = props?.taskType === DELIVERY ? 'requestedEndFrom' : 'requestedEndTo';
                break;
            case 'contactAddress':
                field = props?.taskType === DELIVERY ? 'contactAddress' : 'contactAddress';
                break;
            case 'contact':
                field = props?.taskType === DELIVERY ? 'deliveryContact' : 'pickupContact';
                break;
            case 'phone':
                field = 'contactPhone';
                break;

        }

        return field;
    }

    const FieldDisplayBox = ({ fieldName, record }: { fieldName: string | undefined, record?: any }) => {
        // console.log('####### Filed display Releted Task ->', fieldName);
        let item = fields.find(t => t.name === fieldName) || {} as FieldDisplayDto;
        // console.log('Found field ', item);
        if (!item) {
            console.warn('unable to find field', fieldName, props);
        }
        
        return (
            <Box display="flex">
                <Typography variant="subtitle2" style={{
                    marginRight: '5px',
                    fontSize: "12px",
                    fontWeight: 600
                }}>{item?.label}: </Typography>
                <Typography
                    variant="body2">{FieldDisplay(item, props.describeTable, props.aUserLIst, record)}</Typography>
            </Box>
        )
    }
    // console.log('Releted task cycle', props)

    return (
        <div className={orderClasses.orderDetailsBox}>

            <Box py={1} className={orderClasses.orderHeaderBox} alignItems="center" alignContent="center"
                justifyContent="center">
                <Typography style={{ fontSize: "16px" }} align="center" variant="h6">
                    {props.delivery ? translate('CorbosService::Delivery') : translate('CorbosService::PickUp')}
                </Typography>

            </Box>
            <Box p={2} py={2.5} className={orderClasses.showForm}>
                <Box display="flex" justifyContent={'left'}>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FieldDisplayBox fieldName={isDelivery ? 'contactName' : 'storeName'} record={step} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        { 
                        isDelivery ? 
                            <div></div> : 
                            <FieldDisplayBox fieldName={'deliveryTypeName'} record={delivery} />
                        }
                    </Grid>

                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}></Grid>
                    <Grid item xs={12} md={6}></Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FieldDisplayBox fieldName={isDelivery ? 'contactAddress' : 'contactName'} record={step} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldDisplayBox fieldName={'driver'} record={step} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        { isDelivery ? <div></div> : <FieldDisplayBox fieldName={'contactPhone'} record={step} /> }
                    </Grid>
                    <Grid item xs={12} md={6}>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        {/* <FieldDisplayBox fieldName={'orderType'} /> */}
                        {
                            (
                                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                                    <div>
                                        <Typography variant="subtitle2" style={{
                                            marginRight: '5px',
                                            fontSize: "12px",
                                            fontWeight: 600
                                        }}>{isDelivery ? translate("CorbosService::DeliveryStart") : translate("CorbosService::PickupStart")}: </Typography>
                                        <Typography variant="body2">
                                            {/* <RawField record={props?.record} source='requested' /> */}
                                            <RawField record={step} source={step.startEtaWindow ? 'startEtaWindow' : 'requested'} />
                                        </Typography>
                                    </div>


                                </Box>
                            )
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {isDelivery ?
                            <div>
                                <Typography variant="subtitle2" style={{
                                    marginRight: '5px',
                                    fontSize: "12px",
                                    fontWeight: 600
                                }}>{translate("CorbosService::DeliveryEnd")}: </Typography>
                                <Typography variant="body2">
                                    <RawField record={step} source={step.endEtaWindow ? 'endEtaWindow' : 'requestedEnd'} />
                                </Typography>
                            </div>
                            : <div></div>}
                    </Grid>
                </Grid>
                {isDelivery ?
                    (<Box paddingTop={0.5}>
                        <Box mt="-1rem">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FieldDisplayBox fieldName={'totalPackages'} record={delivery}/>
                                    {(showMoreDetails) &&
                                        <>
                                            <FieldDisplayBox fieldName={'totSmallPackages'} record={delivery} />
                                            <FieldDisplayBox fieldName={'totMediumPackages'} record={delivery} />
                                            <FieldDisplayBox fieldName={'totLargePackages'} record={delivery} />

                                        </>
                                    }
                                </Grid>
                                {(showMoreDetails) &&
                                    <Grid item xs={12} md={6}>
                                        <FieldDisplayBox fieldName={'interPhone'} record={step} />
                                        {
                                            step.reception 
                                            ? <FieldDisplayBox fieldName={'reception'} record={{reception:translate("AbpUi::Yes")}} />
                                            : <FieldDisplayBox fieldName={'reception'} record={{reception:translate("AbpUi::No")}} />
                                        }
                                        
                                        {/* <FieldDisplayBox fieldName={'retailerid'} /> */}
                                        <FieldDisplayBox fieldName={'note'} record={step} />

                                    </Grid>
                                }
                            </Grid>
                        </Box>
                        <Box my={2} className={orderClasses.mostraDiPiu}>

                            {
                                props.orderType !== 'Reso' &&
                                (
                                    <AppButton onClick={showMoreDetailsConsegna} fullWidth size="large"
                                        className={orderClasses.mostraDiPiu}>
                                        {(showMoreDetails) ? translate('AbpUi::Close') : translate('AbpUi::LoadMore')}
                                    </AppButton>
                                )
                            }
                        </Box>
                    </Box>)
                    :
                    <Box p={2}>
                    </Box>
                }
                <Box my={1} display="flex" flexDirection="row" justifyContent="space-evenly">
                    <Typography className={orderClasses.orderStatusBox} align="center" variant="body2">
                        {step.statusName}
                    </Typography>
                </Box>

            </Box>
        </div>
    );
};

export default RelatedTaskShow;