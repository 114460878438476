import { makeStyles } from '@material-ui/core/styles';

export const usePaymentStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    [theme.breakpoints.up("md")]: {
      padding: '3% 0',
    },
    padding: '7% 0',
    backgroundColor: '#fff',
    "& .MuiOutlinedInput-root": {
      background: "#fff",
    },
    "& .MuiSvgIcon-root":{
      height: '0.8em',
      width: '0.8em',
    }
  },
  messageBox: {
    color: '#000'
  },
  appCard: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      width: "48%",
    },
    width: "90%",
    margin: "7% auto",
    padding: '10px ',
    borderColor: "#DCDCDC",
    borderRadius: "5px",
  },
  paymentMessageIcon: {

    color: '#32BA7C',
  },
  actionToolbar: {
    width: '100%',
    padding: '0 15px'
  },
  paymentDetailsGridRows: {
    background: '#F8F8F8',
    marginTop: '8px !important',
    marginBottom: '10px !important',
  },
  cardDetailsPaymentDetailsGridRows: {
    marginTop: '8px !important',
    marginBottom: '10px !important',
    '&:hover':{
      backgroundColor: '#F8F8F8',
      cursor: 'pointer'
    }
  },
  toolbarBtn: {
    padding: '8px 22px',
    boxShadow: 'none',
    marginTop: '20px',
  },
  accountCardImg: {
    width: '30%',
  },
  boxImg: {
    verticalAlign: "middle",
    marginRight: "5px"
  },
  boxImgContainer: {
    width: '25px',
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    verticalAlign: "middle"
  }
}));

